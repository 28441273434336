import ApiResource from '../../../../api-resources';
import { headRessource, createResource } from '../../../../actions/api';

/* A user requests an email with a reset password token */
export const RESET_PASSWORD = '@@resetPassword/RESET_PASSWORD';
/* A user submits the reset password token along with a new password */
export const CHANGE_PASSWORD = '@@resetPassword/CHANGE_PASSWORD';

/**
 *
 * @param {Object} param
 * @param {String} param.email
 * @param {*} resolve
 * @param {*} reject
 */
export const resetPassword = ({ email }, resolve, reject) => ({
  type: RESET_PASSWORD,
  email,
  resolve,
  reject,
});

/**
 *
 * @param {Object} param
 * @param {String} param.email
 */
export const resetPasswordResource = ({ email }) =>
  headRessource({
    resourceUri: `/user/request_password_reset?email=${email}`,
    data: {
      type: ApiResource.RESET_PASSWORD,
    },
  });

/**
 *
 * @param {Object} param
 * @param {String} param.password
 *  * @param {String} param.token
 * @param {*} resolve
 * @param {*} reject
 */
export const changePassword = ({ token, password }, resolve, reject) => ({
  type: CHANGE_PASSWORD,
  token,
  password,
  resolve,
  reject,
});

/**
 *
 * @param {Object} param
 * @param {String} token
 * @param {String} password
 */
export const changePasswordResource = ({ token, password }) =>
  createResource({
    resourceUri: '/user/change_password',
    data: {
      type: ApiResource.CHANGE_PASSWORD,
      attributes: {
        new_password: password,
        reset_token: token,
      },
    },
  });
