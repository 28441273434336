import find from 'lodash.find';
import intersectionBy from 'lodash.intersectionby';
import filter from 'lodash.filter';
import isEmpty from 'lodash.isempty';

export const flattenAttributes = ({ id, attributes }) => ({
  id,
  ...attributes,
});

/*
 * Extracts the first resource of a given type from the jsonapi payload
 *
 * @example
 *
 *   const jsonapi = {
 *     data: {
 *       identity: {
 *         ID42: {
 *           attributes, id, meta
 *         }
 *       }
 *     }
 *   }
 *
 *   extractFirstJsonapiResource(jsonapi)
 *   # => { attributes, id, meta }
 *
 * @param { Object } response - response object (eventually inside an action)
 * @param { resourceName } - name of the resource to extract from the payload
 * @return { Object } - jsonapi resource
 */
export const extractFirstJsonapiResource = (response, resourceName) => {
  const resources = response.data[resourceName];
  return resources ? resources[Object.keys(resources)[0]] : {};
};

export const extractFirstJsonapiResourceFromAction = (action, resourceName) => {
  if (!action) {
    return action;
  }
  return extractFirstJsonapiResource(action, resourceName);
};

/*
 * @param { JsonapiResource } resource with an ID key
 * @param { Array<JsonapiResource> } relatedResources
 * @param { String } relationName
 * @return { JsonapiResource } - First matching resource
 *
 * @example
 *  user = { id: 42, type: 'user', attributes: {} }
 *  findInverseRelationship(a, [{ relationships: { user: { id: 42 } } }])
 *  # => user
 */
export const findInverseRelationship = (
  resource,
  relatedResources,
  relationName,
) =>
  find(relatedResources, (p) => {
    const id = fetchRelationshipID(p, relationName);
    return id && id === resource.id;
  });

/**
 * Return id of relation on JSON api resource
 * @param { JsonapiResource } resource
 * @param { String } relationName
 *
 * @example user = { id: 42, type: 'user', relationships: {
 *  profile: {
 *    data: {
 *      id: 75,
 *      name: 'test'
 *    }
 *  }
 * }}
 */
export const fetchRelationshipID = (resource, relationName) =>
  (resource &&
    resource.relationships &&
    resource.relationships[relationName] &&
    resource.relationships[relationName].data &&
    resource.relationships[relationName].data.id) ||
  undefined;

/**
 * Sort a collection by resultScore attribute
 * @param {Object[]} collection - collection with resultScore to sort
 */
export const sortByResultScore = (collection) =>
  collection.sort(
    ({ resultScore: AResultScore }, { resultScore: BResultScore }) => {
      // if no score, do not sort
      if (!AResultScore || !BResultScore) {
        return 0;
      }
      return BResultScore - AResultScore;
    },
  );

/**
 * TODO: move this to utils/error
 * (related somehow to https://github.com/Startouf/MyJobGlasses/issues/2742)
 * Use json:api errors and extract errors from request payload
 * NOTE : Due to some bug "errors" is transformed into "error" in the jsonapi processing pipeline
 * @param {Object} payload
 * @link http://jsonapi.org/format/#error-objects
 * @example payload = {
 *    error(s): [{
 *      status: 402,
 *      meta: {
 *        code: 'invalid_profile',
 *        ...
 *      },
 *      ...
 *    }]
 * }
 */
export const extractErrorsFromPayload = (payload = {}) => {
  let { error, errors } = payload;
  errors = error || errors;
  if (!Array.isArray(errors)) {
    return [];
  }
  return errors.map((error) => ({
    status: error.status,
    name: (error.meta && error.meta.code) || error.code,
    source: error.source,
  }));
};

/**
 * Extract a readable message from error payload
 * (to pass to toastr feature for example)
 * @param {Object} errorPayload
 * @param {Object} dictionnary
 */
export const extractReadableErrorMessageFromErrorPayload = (
  errorPayload,
  dictionnary,
) => {
  const error = extractErrorsFromPayload(errorPayload);
  const defaultMessage = dictionnary?.default;
  const codeError = error?.[0]?.name;
  if (!codeError) {
    return defaultMessage;
  }
  const messageFromDictionnary = dictionnary?.[codeError];
  if (!messageFromDictionnary) {
    return defaultMessage;
  }
  return messageFromDictionnary;
};

/**
 * Extract status code (HTTP code) from error payload
 * @param {Object} payload
 * @example getErrorStatusCode({
 *    error: [
 *      {
 *        status: '404',
 *      }
 *    ]
 * }) = 404
 * @returns {Integer}
 */
export const getErrorStatusCode = (payload) => +payload?.error?.[0]?.status;

/**
 * Extract status code (HTTP code) from error payload
 * @param {Object} payload
 * @example getFirstErrorMetaCode({
 *    error: [
 *      {
 *        meta: {
 *          code: 'not_found'
 *        }
 *      }
 *    ]
 * }) = 'not_found'
 * @returns {Integer}
 */
export const getFirstErrorMetaCode = (payload) =>
  payload?.error?.[0]?.meta?.code;

/**
 * Retrieve a single resource (HasOne) as relation of a first parent resource
 * @param {Object} resource - Resource on which found id of the relation
 * @param {Object[]} relatedResource - List of resources on which found relation
 * @param {String} relationshipName - relation name as declared on resource object
 * @return {Object}
 */
export const findRelationship = (
  resource,
  relatedResource,
  relationshipName,
) => {
  const relationId =
    resource?.relationships?.[relationshipName]?.data?.id || null;
  if (relationId) {
    return find(relatedResource, { id: relationId });
  }
  return null;
};

/**
 * Retrieve all resources for a relationship (HasMany)
 * @param {Object} resource - Resource on which found id of the relation
 * @param {Object[]} relatedResource - List of resources on which found relation
 * @param {String} relationshipName - relation name as declared on resource object
 * @return {Object[]}
 */
export const findAllRelationships = (
  resource,
  relatedResource,
  relationshipName,
) => {
  if (
    !resource ||
    !resource.relationships ||
    !resource.relationships[relationshipName]
  ) {
    return [];
  }
  const items = intersectionBy(
    relatedResource,
    resource.relationships[relationshipName].data,
    'id',
  );
  return items;
};

/**
 *
 * @param {Object} resource - resource to compare
 * @param {Object[]} relatedResources - list of resource on which iterate
 * @param {String} relationName - Relation name to found (inverse) relation
 */
export const findAllInverseRelationship = (
  resource,
  relatedResources,
  relationName,
) =>
  filter(
    relatedResources,
    (relatedResource) =>
      !!findRelationship(relatedResource, [resource], relationName),
  ) || [];

/**
 * Transform a deep object to flattened key/value object
 * @param {Array} params - collection to flatten
 * @param {String} prevSuffix - suffix to apply to key
 * @returns {Object}
 * @example flattenRequestParams({
 *    filters: {
 *      tags_name: 'it_digital'
 *    }
 * }) = { 'filters[tags_name]': 'it_digital' }
 */
export const flattenRequestParams = (params, prevSuffix = '') => {
  if (isEmpty(params)) {
    return {};
  }
  let flattens = {};
  Object.keys(params).forEach((key) => {
    const constructedKey = prevSuffix != '' ? `${prevSuffix}[${key}]` : key;
    if (params[key] === undefined) {
      return;
    } else if (Array.isArray(params[key])) {
      flattens[constructedKey] = params[key].join(',');
    } else if (!Array.isArray(params[key]) && typeof params[key] === 'object') {
      flattens = {
        ...flattens,
        ...flattenRequestParams(params[key], constructedKey),
      };
    } else {
      flattens[constructedKey] = params[key];
    }
  });
  return flattens;
};
