import { getAccountUrl } from 'utils/redirection.util';
import CalendarIcon from 'icons/component-icons/CalendarIcon';
import Routes from 'routes';
import { t } from 'utils/translate';
import UsernavItem from '../../components/usernav-item/UsernavItem';
import translations from './marketplace.translations';
import BarChartIcon from 'icons/component-icons/BarChartIcon';
import MainMenuItem from '../../components/main-menu-item/MainMenuItem';
import { HeaderConfigItem } from '../../@types/HeaderConfig';
import SearchRefractionIcon from '../../../../../icons/component-icons/SearchRefractionIcon';
import StarIcon from '../../../../../icons/component-icons/StarIcon';
import MailIcon from '../../../../../icons/component-icons/MailIcon';
import UserIcon from '../../../../../icons/component-icons/UserIcon';
import SettingsIcon from '../../../../../icons/component-icons/SettingsIcon';
import LockIcon from '../../../../../icons/component-icons/LockIcon';
import PowerIcon from '../../../../../icons/component-icons/PowerIcon';

const accountEndpoint =
  window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL ||
  'http://localhost:4003';
const hermesEndpoint =
  window.__RUNTIME_CONFIG__.REACT_APP_HERMES_URL || 'http://localhost:3000';
const vocationEndpoint =
  window.__RUNTIME_CONFIG__.REACT_APP_SELF_SERVICE_FRONTEND_URL ||
  'http://localhost:4002';

const config: { main: HeaderConfigItem[]; connected: HeaderConfigItem[] } = {
  main: [
    {
      component: MainMenuItem,
      type: 'link',
      key: 'dashboard',
      to: Routes.dashboard,
      label: t(translations.dashboard),
      icon: BarChartIcon,
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'search',
      to: Routes.search,
      label: t(translations.search),
      icon: SearchRefractionIcon,
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'wishlist',
      to: Routes.studentWishlist,
      label: t(translations.favorites),
      icon: StarIcon,
      notificationKey: 'wishedProfessionalsCount',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'conversations',
      to: Routes.conversations,
      label: t(translations.conversations),
      icon: MailIcon,
      notificationKey: 'unreadMessageCount',
    },
    {
      component: MainMenuItem,
      type: 'link',
      key: 'appointments',
      to: Routes.appointments,
      label: t(translations.appointments),
      icon: CalendarIcon,
    },
  ],
  connected: [
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'profile',
      to: getAccountUrl('/profile'),
      label: t(translations.profile),
      icon: UserIcon,
    },
    {
      component: UsernavItem,
      type: 'link',
      key: 'settings',
      to: Routes.settings,
      label: t(translations.settings),
      icon: SettingsIcon,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'mjg-admin-new-bo',
      to: `${hermesEndpoint}/admin`,
      label: t(translations.mjgNewBO),
      condition: ({ currentUser }) => !!currentUser && currentUser.isMjgAdmin,
      icon: LockIcon,
    },
    {
      component: UsernavItem,
      type: 'external-link',
      key: 'logout',
      to: `${accountEndpoint}/signout`,
      label: t(translations.logout),
      icon: PowerIcon,
    },
    {
      component: UsernavItem,
      type: 'external-switch',
      key: 'switch-vocation',
      to: vocationEndpoint,
      label: t(translations.schoolCoachMode),
      condition: ({ currentUser }) => !!currentUser && currentUser.isCoach,
    },
    {
      component: UsernavItem,
      type: 'switch-account',
      key: 'switch-account',
      to: Routes.companyAdminBase,
      label: t(translations.employeeMode),
      condition: ({ currentUser }) =>
        !!currentUser?.companyAdminProfiles &&
        currentUser.companyAdminProfiles.length > 0,
    },
  ],
};

export default config;
