export const HYDRATE_STORAGE = '@@storage/HYDRATE_STORAGE';
export const REMOVE_IN_LOCAL_STORAGE = '@@storage/REMOVE_IN_LOCAL_STORAGE';
export const STORE_IN_LOCAL_STORAGE = '@@storage/STORE_IN_LOCAL_STORAGE';

/**
 * Request store in localstorage
 * @param {Object} params
 * @param {String} params.key
 * @param {any} params.value
 */
export const storeInLocalStorage = ({ key, value }) => ({
  type: STORE_IN_LOCAL_STORAGE,
  key,
  value,
});

/**
 * Request store in localstorage
 * @param {String} key
 */
export const removeInLocalStorage = (key) => ({
  type: REMOVE_IN_LOCAL_STORAGE,
  key,
});

/**
 * Re-hydrate redux store with data
 * @param {Object} payload - all data to rehydrate
 */
export const hydrateStorage = (payload) => ({
  type: HYDRATE_STORAGE,
  payload,
});
