import forOwn from 'lodash.forown';
import mergeWith from 'lodash.mergewith';
import keyBy from 'lodash.keyby';
import filter from 'lodash.filter';
import isEmpty from 'lodash.isempty';
import camelCase from 'lodash.camelcase';
import merge from 'lodash.merge';
import set from 'lodash.set';
import forEach from 'lodash.foreach';
import pluralize from 'pluralize';
import normalize from 'json-api-normalizer';
import { RECEIVE_DATA } from '../actions/realtime';

const defaultState = {
  professionalEmployees: [],
  professionalMentors: [],
  companies: [
    {
      attributes: {
        name: 'My Job Glasses',
      },
    },
  ],
  users: [],
  credentials: [],
};

// **relationship** not automically updated
// due to impossibility to know if a relation don't need to be erased
// https://github.com/Startouf/MyJobGlasses/issues/2908
const api = (
  state = defaultState,
  { type, data, requestData, reset, payload },
) => {
  if (/(CREATE|UPDATE|READ|READ_LIST|DELETE)_RESOURCE_\w+_SUCCESS/.test(type)) {
    const updatedObjects = {};

    forOwn(data, (obj, key) => {
      const stateKey = pluralize(key);
      const vals = Object.values(obj);
      const subStateVals = state[stateKey] || [];
      const op = reset
        ? keyBy(vals, 'id')
        : mergeWith(
            keyBy(subStateVals, 'id'),
            keyBy(vals, 'id'),
            (objValue, srcValue) => {
              if (isEmpty(srcValue)) {
                return objValue;
              }

              if (
                type.indexOf('UPDATE') !== -1 &&
                key === camelCase(requestData.type) &&
                objValue &&
                objValue.relationships
              ) {
                const updatedRelationships = {};

                forOwn(
                  requestData.relationships,
                  (relationship, relationshipKey) => {
                    updatedRelationships[relationshipKey] =
                      srcValue.relationships[relationshipKey];
                  },
                );

                return {
                  ...srcValue,
                  relationships: {
                    ...objValue.relationships,
                    ...updatedRelationships,
                  },
                };
              }
            },
          );

      updatedObjects[stateKey] = Object.values(op);
    });

    return {
      ...state,
      ...updatedObjects,
    };
  }

  if (/DELETE_RESOURCE_\w+_SUCCESS/.test(type)) {
    const updatedObjects = {};

    forOwn(data, (obj, key) => {
      const stateKey = pluralize(key);
      const deletedKeys = Object.keys(obj);
      const subStateVals = state[stateKey] || [];
      const keptItems = filter(
        subStateVals,
        (v) => deletedKeys.indexOf(v.id) === -1,
      );

      updatedObjects[stateKey] = keptItems;
    });

    return {
      ...state,
      ...updatedObjects,
    };
  }

  if (type === RECEIVE_DATA && !isEmpty(payload)) {
    const filteredPayload = filterObjectRelationsShips(payload);
    const normalized = normalize(filteredPayload);
    const updatedObjects = {};
    // loop on all keys
    forOwn(normalized, (obj, key) => {
      // pluralize state key
      const storeKey = pluralize(key);
      // suppress key on store
      const incomingValues = Object.values(obj);
      // retrieve data from store
      const currentValueInState = state[storeKey] || [];
      // merge new value on old value
      const finalValue = mergeWith(
        keyBy(currentValueInState, 'id'),
        keyBy(incomingValues, 'id'),
        (current, destination) => {
          return merge({}, current, destination);
        },
      );
      updatedObjects[storeKey] = Object.values(finalValue);
    });
    return {
      ...state,
      ...updatedObjects,
    };
  }

  // @todo SUPPRESS THIS WITH https://github.com/Startouf/MyJobGlasses/issues/2908
  if (type === 'DIRTY_PATCH_API') {
    state = merge({}, state, data);
  }
  if (type === 'FORCE_DIRTY_PATCH_API') {
    state = merge({}, state);
    Object.keys(data).forEach((collectionKey) => {
      data[collectionKey].forEach((item) => {
        const collection = state?.[collectionKey] || [];
        const index = collection.findIndex(({ id }) => id === item.id);
        set(state, `${collectionKey}.${index}`, item);
      });
    });
  }
  if (type === 'DIRTY_PATCH_API_DELETE') {
    state = Object.assign({}, state, data);
  }

  return state;
};

const filterObjectRelationsShips = (object) => {
  const relationships = object?.data?.relationships || {};

  const filteredRelationships = {};
  forEach(Object.keys(relationships), (key) => {
    if (relationships?.[key]?.meta?.included !== false) {
      filteredRelationships[key] = relationships[key];
    }
  });

  set(object, 'data.relationships', filteredRelationships);
  return object;
};

export default api;
