import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ModalStatusForm.scss';

/*
 * Just a component to display validation state as an colored arc
 * Think for form in modals (like language request or photo request)
 */
const ModalStatusForm = ({ isValid, isInformative }) => (
  <div className="status-container">
    <div
      className={classNames({
        status: true,
        'status-ok': isValid,
        'status-informative': isInformative,
      })}
    >
      <div className="effect"></div>
    </div>
  </div>
);

ModalStatusForm.propTypes = {
  isValid: PropTypes.bool,
  isInformative: PropTypes.bool,
};

ModalStatusForm.defaultProps = {
  isValid: false,
  isInformative: false,
};

export default ModalStatusForm;
