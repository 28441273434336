import { call, put } from 'redux-saga/effects';
import { rememberUtm } from '../actions/navigation';
import { utm, validUTM } from '../utils/url-parser';

/*
 * This function hydrates the state/storage from special URL paramaters
 *
 * NOTE : do not handle confirmationToken here
 *   it is tightly coupled with user bootstrapping and needs to be executed
 *   at a very specific time during sagas initialization
 */
export function* hydrateFromUrl() {
  try {
    if (validUTM) {
      yield call(handleUtm, utm);
    }
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
}

function* handleUtm(utm) {
  yield put(rememberUtm(utm));
}
