import ApiResource from '../api-resources';
import {
  TRACK_VIEWED_PROFESSIONAL,
  VIEW_PROFESSIONAL_FROM_SIMPROS,
  TRACK_VIEWED_OWN_PROFESSIONAL_PROFILE,
} from '../context';
import { readResource, readListResource } from './api';

/**
 * Retrieve a single professional
 * @param {String} id
 */
export const getProfessional = (id) =>
  readResource({
    resourceUri: '/professionals',
    data: {
      type: 'profile/employee',
    },
    id,
    params: {
      include: [
        'user',
        'educations',
        'promotions',
        'company_sector',
        'similar_professionals.user',
      ].join(','),
    },
  });

/**
 * Retrieve a list of featured professionals
 */
export const getFeaturedProfessionals = () =>
  readListResource({
    resourceUri: '/search/featured_professionals',
    data: {
      type: ApiResource.FEATURED,
    },
    params: {
      'page[size]': 3,
      'page[number]': 1,
    },
  });

/**
 * Pass to ahoy the action of view professional
 * @param {String} id
 * @param {Object} currentUser
 */
export const trackViewedProfessional = (
  id,
  currentUser,
  navigationReferrer,
) => ({
  type: TRACK_VIEWED_PROFESSIONAL,
  id,
  user_id: currentUser?.id,
  navigationReferrer,
});

/**
 * Pass to ahoy the action of view professional
 * @param {String} id
 * @param {Object} currentUser
 */
export const trackViewedOwnProfessionalProfile = (
  id,
  currentUser,
  navigationReferrer,
) => ({
  type: TRACK_VIEWED_OWN_PROFESSIONAL_PROFILE,
  id,
  user_id: currentUser?.id,
  navigationReferrer,
});

/**
 * Pass to ahoy middleware a click on similar pro
 * @param {String} id - professional ID
 */
export const trackSimProsClick = (id) => ({
  type: VIEW_PROFESSIONAL_FROM_SIMPROS,
  professionalID: id,
});
