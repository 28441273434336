export const BUILD_INDEX = '@@apiIndexes/BUILD_INDEX';
export const ADD_TO_INDEX = '@@apiIndexes/ADD_TO_INDEX';
export const FORCE_LAST_PAGE = '@@apiIndexes/FORCE_LAST_PAGE';
export const SET_INDEX_LOADING_STATE = '@@apiIndexes/SET_INDEX_LOADING_STATE';
export const SET_INDEX_ERROR = '@@apiIndexes/SET_INDEX_ERROR';

/**
 * Create a new index (or update) on the store
 * new data will erase olds
 * @param {String} key - key of the index to create (as used on store)
 * @param {Array} data - An array of keys to store on index
 * @param {Object} page
 * @param {Integer} page.currentPage - Current page of result to store
 * @param {Integer} page.total - Total amount of results (of all pages)
 * @param {Integer} page.numberOfPages - Number of page to display
 */
export const buildIndex = (key, data, page) => ({
  type: BUILD_INDEX,
  key,
  data,
  page,
});

/**
 * Add items to a stored index
 * @param {String} key - key of the index to update (as used on store)
 * @param {Array} data - An array of keys to add on the index
 * @param {Object} page
 * @param {Integer} page.currentPage - Current page of result to store
 * @param {Integer} page.total - Total amount of results (of all pages)
 * @param {Integer} page.numberOfPages - Number of page to display
 */
export const addToIndex = (key, data, page) => ({
  type: ADD_TO_INDEX,
  key,
  data,
  page,
});

/**
 * Set the loading state of an index
 * @param {String} key - key of the index to set loading state
 * @param {Boolean} state - (true for isLoading, false for isNotLoading)
 */
export const setIndexLoadingState = (key, state) => ({
  type: SET_INDEX_LOADING_STATE,
  key,
  state,
});

/**
 * Set the error of an index
 * @param {String} key - key of the index to set loading state
 * @param {Object|String} error - Error to set
 */
export const setIndexError = (key, error) => ({
  type: SET_INDEX_ERROR,
  key,
  error,
});

/**
 * Force last page to specific search index
 * @param {String} key - key of the index to set loading state
 */
export const forceLastPage = (key) => ({
  type: FORCE_LAST_PAGE,
  key,
});
