import { take, race, put, takeEvery, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import ApiResource from '../../../../api-resources';
import {
  resetPasswordResource,
  changePasswordResource,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
} from '../actions/updatePassword';
import Routes from '../../../../routes';
import { currentRouteSelector } from '../../../../selectors';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../../../../utils/actions';

/**
 *
 * @param {Object} param
 * @param {String} param.email
 * @param {Function} param.resolve
 * @param {Function} param.reject
 */
function* handleResetPassword({ email, resolve, reject }) {
  yield put(
    resetPasswordResource({
      email,
    }),
  );
  const { error } = yield race({
    success: take(createSuccessActionType('HEAD', ApiResource.RESET_PASSWORD)),
    error: take(createErrorActionType('HEAD', ApiResource.RESET_PASSWORD)),
  });
  if (error) {
    if (typeof reject === 'function') {
      reject(error.error);
    }
    return;
  }
  resolve();
  const currentRoute = yield select(currentRouteSelector);
  if (currentRoute === Routes.forgottenPassword) {
    yield put(push(Routes.forgottenPasswordTokenSent, { email }));
  }
}

/**
 *
 * @param {Object} param
 * @param {String} param.token
 * @param {String} param.password
 * @param {Function} param.resolve
 * @param {Function} param.reject
 */
function* handleChangePassword({ token, password, resolve, reject }) {
  yield put(
    changePasswordResource({
      token,
      password,
    }),
  );
  const { error } = yield race({
    success: take(
      createSuccessActionType('CREATE', ApiResource.CHANGE_PASSWORD),
    ),
    error: take(createErrorActionType('CREATE', ApiResource.CHANGE_PASSWORD)),
  });
  if (error) {
    if (typeof reject === 'function') {
      reject(error.error);
    }
    return;
  }
  resolve();
  yield put(push(Routes.signinView, { passwordChanged: true }));
}

/**
 *
 */
export function* hydrateForgottenPassword() {
  yield takeEvery([RESET_PASSWORD], handleResetPassword);
  yield takeEvery([CHANGE_PASSWORD], handleChangePassword);
}
