import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

export const containerLoadStateFromStore = (store: ReduxStore) =>
  store?.containerLoadState || {};

/**
 * Retrieve a specific container state
 * @param {String} containerName
 * @return {Function} - selector
 */
export const containerLoadStateSelector = memoize((containerName) =>
  createSelector(
    containerLoadStateFromStore,
    (states) => states?.[containerName] || {},
  ),
);
