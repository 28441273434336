import useAssignmentAffiliationMethods from './hooks/useAssignmentAffiliationMethods';
import useSignUpIntent from './hooks/useSignUpIntent';

function RouterHive() {
  useAssignmentAffiliationMethods();
  useSignUpIntent();
  return null;
}

export default RouterHive;
