import { CurrentUser, SwitchState } from './CurrentUserContext.types';

export const LS_SWITCH_STATE_KEY = 'switch_state_v1.1_';

// eslint-disable-next-line max-len
export function isPublishedAtLeastOnce({
  firstPublishedAt,
}: CurrentUser): boolean {
  return !!firstPublishedAt;
}

export function getSwitchStateBasedOnSituation({
  isAmbassador,
}: CurrentUser): SwitchState {
  if (
    !isAmbassador
  ) {
    return 'member';
  }

  const lsSwitchState = localStorage.getItem(LS_SWITCH_STATE_KEY);
  if (lsSwitchState) {
    return lsSwitchState as SwitchState;
  }
  return 'ambassador';
}

export function updateSwitchStateLocalStorage(
  switchState: SwitchState,
  { primarySituation, firstPublishedAt }: CurrentUser,
) {
  if (
    firstPublishedAt
  ) {
    localStorage.setItem(LS_SWITCH_STATE_KEY, switchState);
  }
}
