import { JsonApiCurrentUser, ProfileType } from '../../@types/jsonapi.d';

import { getCookie } from '../cookie';

type MjgHermesUserCookie = {
  is_coach?: boolean | undefined;
  is_company_admin?: boolean | undefined;
  is_company_employee?: boolean | undefined;
  is_mentor?: boolean | undefined;
  is_mjg_admin?: boolean | undefined;
  is_moderator?: boolean | undefined;
  is_student?: boolean | undefined;
  is_vip?: boolean | undefined;
};

export function getProfilesCookie(): MjgHermesUserCookie {
  const cookie: string = getCookie('mjg_hermes_user');

  if (cookie === '') {
    return {};
  }

  let parsedCookie = {};
  try {
    parsedCookie = JSON.parse(cookie);
  } catch (_) {
    return {};
  }

  return parsedCookie;
}

export function HaveProfessionalCookie(): boolean {
  const profilesCookie = getProfilesCookie();

  return (
    profilesCookie.is_mentor === true ||
    profilesCookie.is_company_employee === true ||
    profilesCookie.is_vip === true
  );
}

export function isCompanyAdmin(user?: JsonApiCurrentUser): boolean {
  if (Array.isArray(user?.profiles)) {
    return !!user?.profiles.find(
      ({ type }) => type === ProfileType.companyAdmin,
    );
  }
  return false;
}

export function isMJGAdmin(user?: JsonApiCurrentUser): boolean {
  if (Array.isArray(user?.profiles)) {
    return !!user?.profiles.find(({ type }) => type === ProfileType.MJGAdmin);
  }
  return false;
}

export function isModerator(user?: JsonApiCurrentUser): boolean {
  if (Array.isArray(user?.profiles)) {
    return !!user?.profiles.find(({ type }) => type === ProfileType.moderator);
  }
  return false;
}

export function isSchoolCoach(user?: JsonApiCurrentUser): boolean {
  if (Array.isArray(user?.profiles)) {
    return !!user?.profiles.find(
      ({ type }) => type === ProfileType.schoolCoach,
    );
  }
  return false;
}

export function isStudent(user?: JsonApiCurrentUser): boolean {
  if (Array.isArray(user?.profiles)) {
    return !!user?.profiles.find(({ type }) => type === ProfileType.student);
  }
  return false;
}

export function isMentor(user?: JsonApiCurrentUser): boolean {
  if (Array.isArray(user?.profiles)) {
    return !!user?.profiles.find(({ type }) => type === ProfileType.mentor);
  }
  return false;
}

export function isAmbassador(user?: JsonApiCurrentUser): boolean {
  if (Array.isArray(user?.profiles)) {
    return !!user?.profiles.find(({ type }) => type === ProfileType.employee);
  }
  return false;
}

export function isVIP(user?: JsonApiCurrentUser): boolean {
  if (Array.isArray(user?.profiles)) {
    return !!user?.profiles.find(({ type }) => type === ProfileType.vip);
  }
  return false;
}

export function isProfessional(user?: JsonApiCurrentUser): boolean {
  return isMentor(user) || isAmbassador(user) || isVIP(user);
}

export function isCompanyAdminButNotAmbassador(
  user?: JsonApiCurrentUser,
): boolean {
  return isCompanyAdmin(user) && !isAmbassador(user);
}
