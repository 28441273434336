export const CREATE = 'CREATE';
export const READ = 'READ';
export const READ_LIST = 'READ_LIST';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const UPLOAD = 'UPLOAD';

export function createRequestActionType(prefix, resourceType) {
  return `@@api/${prefix}_RESOURCE_${resourceType.replace(/\//g, '_').toUpperCase()}`;
}

export function createErrorActionType(prefix, resourceType) {
  return `@@api/${prefix}_RESOURCE_${resourceType.replace(/\//g, '_').toUpperCase()}_ERROR`;
}

export function createSuccessActionType(prefix, resourceType) {
  return `@@api/${prefix}_RESOURCE_${resourceType.replace(/\//g, '_').toUpperCase()}_SUCCESS`;
}

export function getCreateSuccessActionType(resourceType) {
  return createSuccessActionType(CREATE, resourceType);
}

export function getCreateErrorActionType(resourceType) {
  return createErrorActionType(CREATE, resourceType);
}

export function getReadRequestActionType(resourceType) {
  return createRequestActionType(READ, resourceType);
}

export function getReadSuccessActionType(resourceType) {
  return createSuccessActionType(READ, resourceType);
}

export function getReadErrorActionType(resourceType) {
  return createErrorActionType(READ, resourceType);
}

export function getReadListRequestActionType(resourceType) {
  return createRequestActionType(READ_LIST, resourceType);
}

export function getReadListSuccessActionType(resourceType) {
  return createSuccessActionType(READ_LIST, resourceType);
}

export function getReadListErrorActionType(resourceType) {
  return createErrorActionType(READ_LIST, resourceType);
}

export function getUpdateSuccessActionType(resourceType) {
  return createSuccessActionType(UPDATE, resourceType);
}

export function getUpdateErrorActionType(resourceType) {
  return createErrorActionType(UPDATE, resourceType);
}

export function getDeleteSuccessActionType(resourceType) {
  return createSuccessActionType(DELETE, resourceType);
}

export function getDeleteErrorActionType(resourceType) {
  return createErrorActionType(DELETE, resourceType);
}

export function getUploadSuccessActionType(resourceType) {
  return createSuccessActionType(UPLOAD, resourceType);
}

export function getUploadErrorActionType(resourceType) {
  return createErrorActionType(UPLOAD, resourceType);
}
