export const REPORT_ACTION_TO_AHOY = '@@ahoy/REPORT_ACTION_TO_AHOY';

/**
 * forward action data to ahoy
 * @param {String} name
 * @param {Object} params
 */
export const reportActionToAhoy = (name, params) => ({
  type: REPORT_ACTION_TO_AHOY,
  name,
  params,
});
