import { call, fork, put, race, select, take } from 'redux-saga/effects';
import { setContainerLoadState } from '../../../../actions/container-load-state';
import {
  getProfessional,
  trackViewedProfessional,
  trackViewedOwnProfessionalProfile,
} from '../../../../actions/professionals';
import { VISITING_PROFILE_PAGE_CONTEXT } from '../../../../context';
import { userSelector } from '../../../../selectors';
import { profileSelector } from '../../../../selectors/profile';
import { historyStateSelector } from '../../../../selectors/router';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../../../../utils/actions';
import { storeSmartURL } from '../../../../utils/smarturl';
import { watchProfessionalsUnavailabilities } from '../../../../sagas/profile';
import { getUserMainProfile } from '../../../../utils/user';

/**
 * Retrieve a professional (in cache or request to api)
 * @param {String} professionalId
 * @returns {Object} - requested professional
 */
export function* retrieveProfessional(professionalId) {
  const professional = yield select(profileSelector(professionalId));
  // wished professional does not load user relationship
  if (professional && professional?.user?.id) {
    return professional;
  }
  yield put(getProfessional(professionalId));
  const { success } = yield race({
    success: take(createSuccessActionType('READ', 'profile/employee')),
    error: take(createErrorActionType('READ', 'profile/employee')),
  });
  if (success) {
    return yield select(profileSelector(professionalId));
  }
  return false;
}

/**
 * Hydrate the professional view
 * (on same time) watch professional unavailabilites to update pro state
 * @param {String} professionalId
 */
export function* hydrateProfessionalView(professionalId) {
  yield put(setContainerLoadState('professional', { loading: true }));
  yield fork(watchProfessionalsUnavailabilities);
  const professional = yield call(retrieveProfessional, professionalId);
  if (professional) {
    const { navigationReferrer } = yield select(historyStateSelector);
    const currentUser = yield select(userSelector);
    const currentUserProfile = getUserMainProfile(currentUser);
    if (currentUserProfile?.id === professionalId) {
      yield put(
        trackViewedOwnProfessionalProfile(
          professionalId,
          currentUser,
          navigationReferrer,
        ),
      );
    } else {
      yield put(
        trackViewedProfessional(
          professionalId,
          currentUser,
          navigationReferrer,
        ),
      );
    }
    yield put(setContainerLoadState('professional', { success: true }));
  } else {
    yield put(setContainerLoadState('professional', { error: true }));
  }
}

/**
 * hydrate the One Day With a CEO view
 */
export function* hydrateOneDayWithACeoView() {
  // eslint-disable-next-line
  yield call(
    hydrateProfessionalView,
    window.__RUNTIME_CONFIG__.REACT_APP_ODWC_PROFESSIONAL_ID,
  );
}

export const trackProfessionalView = ({ pathname }) => {
  storeSmartURL({
    type: 'student',
    context: VISITING_PROFILE_PAGE_CONTEXT,
    pathname,
  });
};
