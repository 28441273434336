import { put, takeEvery } from 'redux-saga/effects';
import { StorageEntries } from '../@types/storage-entries';
import { REMEMBER_UTM } from '../actions/navigation';
import { AUTO_SIGNIN_SUCCESS } from '../actions/signin';
import {
  hydrateStorage,
  STORE_IN_LOCAL_STORAGE,
  REMOVE_IN_LOCAL_STORAGE,
} from '../actions/storage';

/*
 * If there is a json web token in the storage,
 * it will attempt to hydrate the header with user information
 * ( -> Will run a getProfile() request to get basic user info + pending reviews)
 *
 * Will not do anything if there are no credentials in the local storage
 */
export function* initializeStateFromStorage() {
  if (window.localStorage) {
    const user = getFromLocalStorage('user');
    const credentials = getFromLocalStorage('credentials');

    yield put(
      hydrateStorage({
        credentials,
        message: getMessageFromLocalStorage(),
        utm: getFromLocalStorage('utm'),
        utmSavedAt: getFromLocalStorage('utmSavedAt'),
        savedDescriptionParts: getFromLocalStorage('savedDescriptionParts'),
        rememberedSignupFlow: getFromLocalStorage('rememberedSignupFlow'),
        [StorageEntries.assignmentAffiliationToken]: getFromLocalStorage(
          StorageEntries.assignmentAffiliationToken,
        ),
        [StorageEntries.mentorInvitationCode]: getFromLocalStorage(
          StorageEntries.mentorInvitationCode,
        ),
      }),
    );

    return { credentials, user };
  }
  return { credentials: null, user: null }; // prevent from errors on saga manager
}

const getFromLocalStorage = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

const getMessageFromLocalStorage = () => getFromLocalStorage('message') || {};

export const hasAutoSigninInfoInStorage = () =>
  !!window.localStorage &&
  !!getFromLocalStorage('user') &&
  !!getFromLocalStorage('credentials');

export function* store() {
  yield takeEvery(
    [
      AUTO_SIGNIN_SUCCESS,
      REMEMBER_UTM,
      STORE_IN_LOCAL_STORAGE,
      REMOVE_IN_LOCAL_STORAGE,
    ],
    handleStore,
  );
}

export function handleStore(action) {
  try {
    switch (action.type) {
      case STORE_IN_LOCAL_STORAGE:
        !!window.localStorage &&
          window.localStorage.setItem(action.key, JSON.stringify(action.value));
        break;

      case REMOVE_IN_LOCAL_STORAGE:
        !!window.localStorage && window.localStorage.removeItem(action.key);
        break;

      case REMEMBER_UTM: {
        !!window.localStorage &&
          window.localStorage.setItem(
            'utm',
            JSON.stringify({
              utm: action.utm,
              utmSavedAt: action.utmSavedAt,
            }),
          );
        break;
      }

      default: {
        return;
      }
    }
  } catch (e) {
    console.error(e); // eslint-disable-line
  }
}
