import memoize from 'lodash.memoize';
import { createSelector } from 'reselect';
import {
  JsonApiCurrentUser,
  JsonApiProfile,
  JsonApiBaseUser,
  JsonApiProfileContentEducationMapped,
  JsonApiProfileContentEducation,
  JsonApiProfileContentPromotionsMapped,
  JsonApiProfileContentPromotions,
  JsonApiMappedInternship,
  JsonApiProfileInternships,
} from '../../@types/jsonapi.d';
import { clearObjectBlankValues } from '../../utils/dev-tools';
import {
  findInverseRelationship,
  findRelationship,
  findAllRelationships,
} from '../../utils/json-api';
import { allProfileContentEducationsCollectionJsonApi } from './educations';
import {
  allProfilesCollectionJsonApi,
  allProfileDraftCollectionJsonApi,
} from './profiles';
import { allProfileContentPromotionsCollectionJsonApi } from './promotions';
import { allProfileInternshipsCollectionJsonApi } from './internships';
import { getCurrentUserData } from '../current-user-data';

export function allUsersCollectionJsonApi(store: ReduxStore) {
  return store?.api?.users || [];
}

export const getUserById = memoize((profileId?: string) =>
  createSelector(
    getCurrentUserData('email'),
    allUsersCollectionJsonApi,
    allProfilesCollectionJsonApi,
    allProfileDraftCollectionJsonApi,
    allProfileContentEducationsCollectionJsonApi,
    allProfileContentPromotionsCollectionJsonApi,
    allProfileInternshipsCollectionJsonApi,
    (
      currentLoggedEmail,
      users: JsonApiBaseUser[],
      profiles,
      drafts,
      educationsCollection,
      promotionsCollection,
      internshipsCollection,
    ): JsonApiCurrentUser | undefined => {
      let profile: JsonApiProfile | undefined;
      let user: JsonApiBaseUser | undefined;
      if (profileId) {
        profile = profiles.find(({ id }) => id === profileId);
        user =
          profile &&
          findRelationship<JsonApiProfile, JsonApiBaseUser>(
            profile,
            users,
            'user',
          );
      } else {
        user = users.find((u) => u?.attributes?.email === currentLoggedEmail);
        profile =
          user &&
          findInverseRelationship<JsonApiBaseUser, JsonApiProfile>(
            user,
            profiles,
            'user',
          );
      }
      if (!profile || !user) {
        return undefined;
      }
      const userProfiles = profiles.filter(
        (p) => p?.relationships?.user?.data?.id === user?.id,
      );

      return {
        id: user.id,
        type: profile.type,
        ...(user.attributes || {}),
        identities: [],
        profiles: userProfiles.map((userProfile) => {
          // handle draft
          const draft = drafts.find(
            ({ id }) =>
              id === userProfile?.relationships?.profileDraft?.data?.id,
          );

          // handle educations
          let educations: JsonApiProfileContentEducationMapped[] = [];
          if (userProfile.relationships.educations?.data?.length) {
            educations = findAllRelationships<
              JsonApiProfile,
              JsonApiProfileContentEducation
            >(userProfile, educationsCollection, 'educations').map(
              (education) => ({
                id: education.id,
                ...education.attributes,
              }),
            );
          }

          // handle promotions
          let promotions: JsonApiProfileContentPromotionsMapped[] = [];
          if (userProfile?.relationships?.promotions?.data?.length) {
            promotions = findAllRelationships<
              JsonApiProfile,
              JsonApiProfileContentPromotions
            >(userProfile, promotionsCollection, 'promotions').map(
              (promotion) => ({
                id: promotion.id,
                ...promotion.attributes,
              }),
            );
          }

          // handle internships
          let internships: JsonApiMappedInternship[] = [];
          if (userProfile?.relationships?.internships?.data?.length) {
            internships = findAllRelationships<
              JsonApiProfile,
              JsonApiProfileInternships
            >(userProfile, internshipsCollection, 'internships').map(
              (internship) => ({
                id: internship.id,
                ...internship.attributes,
              }),
            );
          }

          return {
            id: userProfile.id,
            type: userProfile.type,
            ...(userProfile?.attributes || {}),
            ...clearObjectBlankValues(draft?.attributes || {}),
            educations,
            promotions,
            internships,
          };
        }),
      };
    },
  ),
);
