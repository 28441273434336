import { SET_SEARCH_FACETS_INDEX } from '../actions/search';

const defaultState = {
  facets: {},
};

const search = (state = defaultState, action) => {
  switch (action.type) {
    case SET_SEARCH_FACETS_INDEX: {
      return {
        ...state,
        facets: {
          ...state.facets,
          [action.hash]: action.facets,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default search;
