import { defineMessages } from 'react-intl';
import { AffiliateToAssignmentErrors } from '../../../../../@types/graphql-errors.d';

export default defineMessages({
  success: {
    id: 'useAffiliateToAssignment.success',
    defaultMessage: "Affiliation à l'exercice My Job Glasses réussie !",
  },
  tooEarlyWarning: {
    id: 'useAffiliateToAssignment.tooEarlyWarning',
    defaultMessage:
      "Vous avez bien rejoint l'exercice My Job Glasses qui débute le {fdrStartsAt}. Votre activité ne sera prise en compte qu'à partir de cette date là.",
  },
  [AffiliateToAssignmentErrors.ASSIGNMENT_AFFILIATION_TOKEN_INVALID]: {
    id: 'useAffiliateToAssignment.AFFILIATION_TO_ASSIGNMENT_NOT_FOUND',
    defaultMessage:
      "Echec de l'affiliation. Il vous faut cliquer à nouveau sur un lien valide.",
  },
  [AffiliateToAssignmentErrors.AFFILIATION_TO_ASSIGNMENT_TOO_LATE]: {
    id: 'useAffiliateToAssignment.AFFILIATION_TO_ASSIGNMENT_TOO_LATE',
    defaultMessage:
      "L'affiliation à un exercice My Job Glasses terminé est impossible. Veuillez réessayer en utilisant un lien valide.",
  },
  [AffiliateToAssignmentErrors.AFFILIATE_TO_ASSIGNMENT_UNAUTHORIZED]: {
    id: 'useAffiliateToAssignment.AFFILIATE_TO_ASSIGNMENT_UNAUTHORIZED',
    defaultMessage:
      "Désolé, vous n'êtes pas autorisé(e) à vous affilier à cet exercice.",
  },
  defaultError: {
    id: 'useAffiliateToAssignment.defaultError',
    defaultMessage:
      "Echec de l'affiliation. Il vous faut cliquer à nouveau sur un lien valide.",
  },
});
