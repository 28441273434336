import { HIDE_HOW_IT_WORK_BANNER } from '../actions/current-user-data';
import { BOOTSTRAPPING_SUCCESS } from '../actions/user';
import { objectValueFromPosition } from '../utils/dev-tools';

const initialState = {
  showHowItWorkBanner: true,
  email: undefined,
  userId: undefined,
};

/**
 * A collections to handle data about current user information
 */
export default (state = initialState, action) => {
  return state;
};
