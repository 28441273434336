import { put, race, select, take } from 'redux-saga/effects';
import { snakeCaseObjectKeys } from '../../../utils/dev-tools';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../../../utils/actions';
import ApiResource from '../../../api-resources';
import { internshipsSelector } from '../../../selectors';
import {
  updateProfileInternshipsResource,
  deleteProfileInternshipResource,
} from '../actions/profile';

/**
 *
 * @param {Object} values
 * @param {String} values.id
 * @param {String} values.status
 * @param {String} values.type
 * @param {String} values.duration
 * @param {String} values.startsAtMonth
 * @param {String} values.startsAtYear
 * @param {String} values.description
 * @param {Function} values.resolve
 * @param {Function} values.reject
 */
export function* handleInternshipsUpdates({
  values: {
    id,
    status,
    type,
    duration,
    startsAtMonth,
    startsAtYear,
    description,
    profileId,
  },
  resolve,
  reject,
}) {
  yield put(
    updateProfileInternshipsResource(
      profileId,
      {
        internship: {
          ...snakeCaseObjectKeys({
            id,
            status,
            type,
            duration,
            startsAtMonth,
            startsAtYear,
            description,
          }),
        },
      },
      resolve,
      reject,
    ),
  );

  const { success, error } = yield race({
    success: take(
      createSuccessActionType('UPDATE', ApiResource.PROFILE_INTERNSHIP),
    ),
    error: take(
      createErrorActionType('UPDATE', ApiResource.PROFILE_INTERNSHIP),
    ),
  });

  if (error && typeof reject === 'function') {
    reject(error?.error);
  } else if (success && typeof resolve === 'function') {
    resolve();
    // @todo SUPPRESS THIS WITH https://github.com/Startouf/MyJobGlasses/issues/2908
    let collectionsToUpdate = yield select(
      (state) => state?.api?.profileStudents || [],
    );
    collectionsToUpdate = collectionsToUpdate.map((profile) => {
      if (profile.id === profileId) {
        return {
          ...profile,
          attributes: {
            ...(profile?.attributes || {}),
            profileCompletion:
              success?.data?.profileStudents?.[profile.id]?.attributes
                ?.profileCompletion || {},
          },
        };
      }
      return profile;
    });
    yield put({
      type: 'DIRTY_PATCH_API',
      data: {
        profileStudents: [...collectionsToUpdate],
      },
    });
    // END OF @todo
  }
}

/**
 * @param {Object} action
 * @param {String} action.resourceId
 * @param {String} action.profileId
 * @param {Function} action.resolve
 * @param {Function} action.reject
 */
export function* handleInternshipsRemovals({
  resourceId,
  profileId,
  resolve,
  reject,
}) {
  yield put(
    deleteProfileInternshipResource(profileId, {
      deleteResourceId: resourceId,
    }),
  );

  const { success, error } = yield race({
    success: take(
      createSuccessActionType('UPDATE', ApiResource.PROFILE_INTERNSHIP),
    ),
    error: take(
      createErrorActionType('UPDATE', ApiResource.PROFILE_INTERNSHIP),
    ),
  });
  if (error) {
    if (typeof reject === 'function') {
      reject(error?.error);
    }
    return;
  }
  if (typeof resolve === 'function') {
    resolve();
  }
  // @todo remove this with https://github.com/MyJobGlasses/Olympus/issues/2908
  const profileInternships = yield select(internshipsSelector);
  const profileInternshipIndex = profileInternships.findIndex(
    (internship) => internship.id === resourceId,
  );
  delete profileInternships[profileInternshipIndex];
  yield put({
    type: 'DIRTY_PATCH_API_DELETE',
    data: {
      profileInternships: [
        ...profileInternships.slice(0, profileInternshipIndex),
        ...profileInternships.slice(
          profileInternshipIndex + 1,
          profileInternships.length,
        ),
      ],
    },
  });
  let collectionsToUpdate = yield select(
    (state) => state?.api?.profileStudents || [],
  );
  collectionsToUpdate = collectionsToUpdate.map((profile) => {
    if (profile.id === profileId) {
      return {
        ...profile,
        attributes: {
          ...(profile?.attributes || {}),
          profileCompletion:
            success?.data?.profileStudents?.[profile.id]?.attributes
              ?.profileCompletion || {},
        },
      };
    }
    return profile;
  });
  yield put({
    type: 'DIRTY_PATCH_API',
    data: {
      profileStudents: [...collectionsToUpdate],
    },
  });
  // END OF @todo
}
