import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { GoogleTagManager } from 'redux-beacon/targets/google-tag-manager';
import { createMiddleware as createReaduxBeaconMiddleWare } from 'redux-beacon';
import createAhoyMiddleware from './middlewares/createAhoyMiddleware';
import createRootReducer from './reducers';
import sagas from './sagas';
import { analyticsEvents } from './analytics-events';
import { createLinkedinAuthMiddleware } from './middlewares/createLinkedinAuthMiddleware';
import { rerouteRestrictedAccountMiddleware } from './middlewares/rerouteRestrictedAccountMiddleware';

// redux-saga
const sagaMiddleware = createSagaMiddleware();

// redux-router
const history = createBrowserHistory();
const historyMiddleware = routerMiddleware(history);

// google tag manager
const analyticsMiddleware = createReaduxBeaconMiddleWare(
  analyticsEvents,
  GoogleTagManager(),
);
window.dataLayer = window.dataLayer || [];

// ahoy
const ahoyMiddleware = createAhoyMiddleware();

const linkedinAuthMiddleware = createLinkedinAuthMiddleware();

const middleware = applyMiddleware(
  linkedinAuthMiddleware,
  rerouteRestrictedAccountMiddleware,
  historyMiddleware,
  sagaMiddleware,
  analyticsMiddleware,
  ahoyMiddleware,
);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  createRootReducer(history),
  composeEnhancers(middleware),
);

sagaMiddleware.run(sagas);

export const reduxContext = React.createContext(store);

export default store;
export { middleware, history };
