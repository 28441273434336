import { makeVar } from '@apollo/client';
import { PrivateDataProfileType } from '../@types/graphql.d';

export const profileId = makeVar<string | undefined>(undefined);
export const profileType = makeVar<PrivateDataProfileType | undefined>(
  undefined,
);
export const firstName = makeVar<string | undefined>(undefined);
export const avatar = makeVar<string | undefined>(undefined);
export const unreadMessageCount = makeVar<number>(0);

interface Variables {
  profileId?: string;
  profileType?: PrivateDataProfileType;
  firstName?: string;
  avatar?: string;
  unreadMessageCount?: number;
}
export function init(attributes: Variables) {
  if (attributes.profileId) {
    profileId(attributes.profileId);
  }
  if (attributes.profileType) {
    profileType(attributes.profileType);
  }
  if (attributes.firstName) {
    firstName(attributes.firstName);
  }
  if (attributes.avatar) {
    avatar(attributes.avatar);
  }
  if (attributes.unreadMessageCount) {
    unreadMessageCount(attributes.unreadMessageCount);
  }
}
