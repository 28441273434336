import {
  getReadRequestActionType,
  getReadSuccessActionType,
} from '../utils/actions';
import ApiResource from '../api-resources';

const profileView = (state = { isLoading: false, isLoaded: false }, { type }) =>
  ({
    '@@router/LOCATION_CHANGE': {
      ...state,
      isLoaded: false,
    },
    [getReadRequestActionType(ApiResource.PROFILE_EMPLOYEE)]: {
      ...state,
      isLoading: true,
    },
    [getReadSuccessActionType(ApiResource.PROFILE_EMPLOYEE)]: {
      ...state,
      isLoading: false,
      isLoaded: true,
    },
  })[type] || state;

export default profileView;
