import React from 'react';
import {
  defineMessages,
  DateSource,
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  MessageValue,
} from 'react-intl';
import { intl } from '../locales/configure';
import { ucSentenceFirstLetter } from './format-text';

const translations = defineMessages({
  defaultErrorMessage: {
    id: 'utils.translate.defaultErrorMessage',
    defaultMessage: "Une erreur s'est produite. Merci de réessayer",
    description: '',
  },
});

/**
 * Use user locale to translate into the good language a message descriptor
 */
export const t = (
  messageDescriptor: FormattedMessage.MessageDescriptor,
  values?: { [key: string]: MessageValue },
): string => intl.formatMessage(messageDescriptor, values);

/**
 * Use user locale to translate into the good language a message descriptor
 * (same as t but for HTML messages)
 */
export const tHTML = (
  messageDescriptor: FormattedMessage.MessageDescriptor,
  values?: { [key: string]: MessageValue },
): React.ReactNode => (
  <span
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: intl.formatHTMLMessage(messageDescriptor, values),
    }}
  />
);

/**
 * Use user locale to translate into the good language a message descriptor
 * (same as t but for HTML messages)
 */
export const dangerouslyTranslate = (
  messageDescriptor: FormattedMessage.MessageDescriptor,
  values?: { [key: string]: MessageValue },
): string => intl.formatHTMLMessage(messageDescriptor, values);

/**
 * Use user locale to translate a number
 */
export const translateNumber = (value: Number | string): string =>
  intl.formatNumber(+value);

/**
 * Use user locale to translate a date
 */
export const translateDate = (
  value: DateSource,
  params?: FormattedDate.PropsBase,
): string => intl.formatDate(value, params);

/**
 * Use user locale to translate a date
 */
export const translateHour = (
  value: DateSource,
  params?: FormattedTime.PropsBase,
): string => intl.formatTime(value, params);

/**
 * Translate an error code with fallback
 */
export function translateErrorCode<AsyncErrors extends string>(
  messageDescriptors: ReactIntl.Messages<AsyncErrors | 'default'>,
  code: AsyncErrors,
  values?: { [key: string]: MessageValue },
): string {
  if (messageDescriptors[code]) {
    return t(messageDescriptors[code], values);
  }
  if (messageDescriptors.default) {
    return t(messageDescriptors.default, values);
  }
  return t(translations.defaultErrorMessage);
}

export const tMobileAdapt = (
  translationsCollection: { [key: string]: FormattedMessage.MessageDescriptor },
  messageDescriptor: string,
  isMobile: boolean = false,
): string => {
  const message = isMobile
    ? translationsCollection[
        `mobile${ucSentenceFirstLetter(messageDescriptor)}`
      ]
    : translationsCollection[messageDescriptor];
  return t(message);
};

export function sortByEnumTranslation<T>(
  translationObject: ReactIntl.Messages,
  a: T,
  b: T,
) {
  return t(translationObject[a as string]).localeCompare(
    t(translationObject[b as string]),
  );
}
