import { extractErrorsFromPayload } from './json-api';

export const checkStatus = (response) => {
  // TOTHINKABOUT: Architecture rethinking as suggested in  Startouf/MyJobGlasses#2143
  if (response.status >= 200 && response.status < 400) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

/**
 * @param { Object } error - Some jsonapi error payload
 *  should work also against non-jsonapi payloads
 *  depending on the power of extractErrorsFromPayload)
 * @return { Boolean } true if error matches an unconfirmed access error
 */
export const isUnconfirmedAccess = (error) => {
  const errors = extractErrorsFromPayload({ errors: error });
  return (
    errors[0] && errors[0].status === '401' && errors[0].name === 'unconfirmed'
  );
};

/**
 * Handle response from fetch implementation
 * case 204 : return empty object (204 = empty body response)
 * case 200-599 : return json response
 * case 200-599 (without json body) : return custom object with code : 'non_jsonapi_response_error'
 * @param {Object} response
 * @param {Number} response.status
 * @param {Function} response.json
 */
export const parseJSON = (response) => {
  if (response.status === 204) {
    return {};
  }
  return response
    .json()
    .then((...data) => Promise.resolve(...data))
    .catch(() =>
      Promise.reject({
        errors: [
          {
            meta: {
              code: 'non_jsonapi_response_error',
            },
          },
        ],
      }),
    );
};

/**
 * Take JSON:api data and convert them to react-select format
 * @param Object response json:api data
 * @example
 *   const example={ schools: [{attributes: {name: 'UTT'}, ...}, ...] }
 *   formatAutocomplete(example) #=> [{name: 'UTT', value: 'UTT'}, ...]
 * @returns {{ options: { value: string, label: string }[] }}
 */
export const formatAutocomplete = (response) => {
  // retrieve child object which contains options
  const options = response[Object.keys(response)[0]];
  return {
    options:
      typeof options === 'object'
        ? Object.values(options).map((result) => ({
            value: result.attributes.name,
            label: result.attributes.name,
          }))
        : [],
  };
};

/**
 * Take JSON:api data
 * @param {Object} response
 * @returns {Object}
 * @example
 *   const example={ companies: { companies_suggestions: { attributes: { buckets: [] }, id: "companies_suggestions" }}}
 */
export const formatAutocompleteMultiple = (response) => {
  let results = {};
  Object.values(response).map((values) => {
    results = {
      ...results,
      [Object.keys(values)[0]]:
        values[Object.keys(values)[0]].attributes.buckets,
    };
  });
  return results;
};
