import React from 'react';
import { branch, compose, renderComponent, withProps } from 'recompose';
import Spinner from 'react-loaders';
import { MJG_BLUE } from '../../utils/styled-components';

import './Loader.scss';

/**
 * Basic loader without MEGA padding
 */
export const Loader = () => <Spinner type="ball-pulse" />;

/**
 * Loader to use on full-page mode
 */
export const PageLoader = () => (
  <div className="page-loader">
    <Spinner
      type="ball-scale-multiple"
      color={MJG_BLUE}
    />
  </div>
);

/**
 * [HOC] Display a loader or the goal component
 * @param {Function} isLoading - function to handle if loader is displayed
 * @param {Object} params
 * @param {boolean} params.isComponentLoading - Determine to display PageLoader or [minimal] Loader
 * @param {boolean} params.height - custom height of the loader
 */
export const spinnerWhileLoading = (
  isLoading,
  { isComponentLoading, height } = {},
) =>
  compose(
    withProps(() => ({
      height,
    })),
    branch(
      isLoading,
      renderComponent(isComponentLoading ? Loader : PageLoader),
    ),
  );

export default Loader;
