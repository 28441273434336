import { takeEvery, select, put } from 'redux-saga/effects';
import { createSuccessActionType } from '../../../../utils/actions';
import { objectValueFromPosition } from '../../../../utils/dev-tools';
import { profileEmployeesSelector } from '../../../../selectors';
import ApiResource from '../../../../api-resources';
import {
  HIDE_HOW_DOES_IT_WORKS_FROM_EDIT_PROFILE_VIEW,
  UPDATE_PROFILE_ATTRIBUTES,
  UPDATE_PROFILE_EDUCATIONS,
  DELETE_PROFILE_EDUCATIONS,
  UPDATE_PROFILE_INTERNSHIPS,
  DELETE_PROFILE_INTERNSHIPS,
} from '../../actions/profile';
import { UPDATE_USER_ATTRIBUTES } from '../../actions/user';
import {
  handleEducationsUpdates,
  handleEducationsRemovals,
} from '../../sagas/handleEducationsUpdates';
import { handleProfileUpdates } from '../../sagas/handleProfileUpdates';
import { handleUserUpdates } from '../../sagas/handleUserUpdates';
import {
  handleInternshipsUpdates,
  handleInternshipsRemovals,
} from '../../sagas/handleInternshipsUpdates';
import { handleHideHowItWorksBanner } from './handleHowItWorksBanner';
import { setContainerLoadState } from '../../../../actions/container-load-state';
import { containerLoadStateSelector } from '../../../../selectors/container-load-state';

// @todo SUPPRESS THIS WITH https://github.com/Startouf/MyJobGlasses/issues/2908
function* synchronizePromotions(action) {
  const professionalEmployees = yield select(profileEmployeesSelector);
  const profileEmployee = action?.data?.profileEmployee || [];
  const targetEmployee = objectValueFromPosition(0, profileEmployee);
  const patched = professionalEmployees.map((employee) => {
    if (employee.id === targetEmployee.id) {
      return {
        ...employee,
        relationships: {
          ...employee.relationships,
          promotions: targetEmployee?.relationships?.promotions || {},
        },
      };
    }
    return employee;
  });
  yield put({
    type: 'DIRTY_PATCH_API_DELETE',
    data: {
      profileEmployees: patched,
    },
  });
}

/**
 * initialized when the user visit the /profile page
 */
export function* hydrateEditProfileView() {
  const { loaded } = yield select(
    containerLoadStateSelector('hydrate-profile-view'),
  );
  if (!loaded) {
    yield takeEvery(UPDATE_PROFILE_EDUCATIONS, handleEducationsUpdates);
    yield takeEvery(DELETE_PROFILE_EDUCATIONS, handleEducationsRemovals);
    yield takeEvery(UPDATE_PROFILE_ATTRIBUTES, handleProfileUpdates);
    yield takeEvery(UPDATE_USER_ATTRIBUTES, handleUserUpdates);
    yield takeEvery(UPDATE_PROFILE_INTERNSHIPS, handleInternshipsUpdates);
    yield takeEvery(DELETE_PROFILE_INTERNSHIPS, handleInternshipsRemovals);
    yield takeEvery(
      HIDE_HOW_DOES_IT_WORKS_FROM_EDIT_PROFILE_VIEW,
      handleHideHowItWorksBanner,
    );
    yield takeEvery(
      createSuccessActionType('DELETE', ApiResource.EMPLOYEE_PROMOTION),
      synchronizePromotions,
    );
    yield put(setContainerLoadState('hydrate-profile-view', { loaded: true }));
  }
}
