import { useMutation, MutationFunctionOptions } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  Affiliate_To_AssignmentMutation,
  Affiliate_To_AssignmentMutationVariables,
} from '../../../../../@types/graphql.d';
import { AffiliateToAssignmentErrors } from '../../../../../@types/graphql-errors.d';
import { userSelector } from '../../../../../selectors';
import { MJGApolloErrorExtended } from '../../../../../utils/graphql';
import { t, translateDate } from '../../../../../utils/translate';
import { getUserMainProfile } from '../../../../../utils/user';
import translations from './useAffiliateToAssignment.translations';
import { AFFILIATE_TO_ASSIGNMENT } from './useAffiliateToAssignment.gql';
import { updateProfile } from '../../../../../actions/profile';

export default function useAffiliateToAssignment() {
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelector);
  const currentMainProfile = getUserMainProfile(currentUser);

  const { enqueueSnackbar } = useSnackbar();
  const [mutate] = useMutation<
    Affiliate_To_AssignmentMutation,
    Affiliate_To_AssignmentMutationVariables
  >(AFFILIATE_TO_ASSIGNMENT);
  function submit(
    options?: MutationFunctionOptions<
      Affiliate_To_AssignmentMutation,
      Affiliate_To_AssignmentMutationVariables
    >,
  ) {
    return mutate(options)
      .then((data, ...params) => {
        const isTooEarly =
          data?.data?.affiliateToAssignment?.earlyAffiliationWarning;
        const fdrStartsAt =
          data.data?.affiliateToAssignment?.assignmentActivityStartsAt;
        dispatch(
          updateProfile({
            profileId: currentMainProfile.id,
            userType: currentUser.type,
          }),
        );
        if (isTooEarly) {
          enqueueSnackbar(
            t(translations.tooEarlyWarning, {
              fdrStartsAt: translateDate(fdrStartsAt),
            }),
            {
              autoHideDuration: 5000,
              variant: 'warning',
            },
          );
        } else {
          enqueueSnackbar(t(translations.success), {
            autoHideDuration: 5000,
            variant: 'success',
          });
        }
        return Promise.resolve(...params);
      })
      .catch((error: MJGApolloErrorExtended<AffiliateToAssignmentErrors>) => {
        const errorCode = error?.graphQLErrors?.[0]?.extensions?.code;
        if (errorCode && translations[errorCode]) {
          enqueueSnackbar(t(translations[errorCode]), {
            autoHideDuration: 5000,
            variant: 'error',
          });
        } else {
          enqueueSnackbar(t(translations.defaultError), {
            autoHideDuration: 5000,
            variant: 'error',
          });
        }
      });
  }
  return [submit];
}
