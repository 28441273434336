/*
 * Load URL string parameters in constant
 * /!\ Will load them only when loading the React APP
 * (will not refresh when browsing other pages)
 * => Useful to intercept one-time actions to be done upon booting
 * (like URL confirmation, special signup from invitation, etc.)
 */

import querystring from 'querystring';

const urlParamString = document.location.search.split('?')[1];
const hashParamString = document.location.hash.split('#')[1];

/* Retrieve params from URL params and Hash Params */
export const {
  invitation_token,
  confirmation_token,
  password_pending_confirmation,
  password_reset,
  presentation_id,
  matching_id,
  referee_code,
  signup,
  signin,
  utm_campaign,
  utm_medium,
  utm_source,
  utm_term,
  utm_from,
  utm_time,
  utm_lp,
  modal,
  invitation_code,
  scroll,
} = querystring.parse(
  [urlParamString, hashParamString].filter(Boolean).join('&'),
);

export const validUTM = utm_campaign && utm_medium && utm_source;

const utm = {
  campaign: utm_campaign,
  medium: utm_medium,
  source: utm_source,
  term: utm_term,
  from: utm_from,
  time: utm_time,
  lp: utm_lp,
};

export { utm };
