import React, { useContext, useRef } from 'react';
import classnames from 'classnames';
import { matchPath } from 'react-router';
import Avatar from '../../../../../components/avatar/Avatar';
import Icon from '../../../../../components/icon/Icon';
import { currentRouterPathnameSelector } from '../../../../../selectors/router';
import { useOutsideClickEffect } from '../../../../../utils/hooks/useOutsideClickEffect';
import { useSelector } from '../../../../../utils/hooks/useRedux';
import { HeaderConfigItem } from '../../@types/HeaderConfig';
import MainMenuMobileContext from '../../context/MainMenuMobileContext';

import './MainMenu.scss';
import { CurrentUserContext } from 'contexts/current-user-context/CurrentUserContext';

interface MainMenuProps {
  config?: HeaderConfigItem[];
  shouldShowSwitch?: boolean;
}

const MainMenu = ({ config }: MainMenuProps) => {
  const { currentUser } = useContext(CurrentUserContext);
  const pathname = useSelector(currentRouterPathnameSelector);
  // mobile feature
  const { isMainMenuMobileOpened, setMainMenuMobileOpened } = useContext(
    MainMenuMobileContext,
  );
  const ref = useRef(null);
  useOutsideClickEffect(() => {
    setMainMenuMobileOpened(false);
  }, ref);
  function closeMainMenuMenu() {
    setMainMenuMobileOpened(false);
  }
  return (
    <nav
      className={classnames('main-menu', {
        'main-menu-mobile-opened': isMainMenuMobileOpened,
      })}
      ref={ref}
    >
      <div className="mobile-heading">
        <button
          type="button"
          onClick={closeMainMenuMenu}
          className="mobile-heading-close"
        >
          <Icon name="close" />
        </button>
        <Avatar
          avatar={currentUser?.avatars}
          className="avatar"
        />
        <p className="firstname">{currentUser?.firstName}</p>
      </div>

      {Array.isArray(config) && (
        <ul>
          {config.map(
            ({
              component: WrappedComponent,
              condition,
              type,
              className,
              label,
              to,
              key,
              onClick,
              icon,
              notificationKey,
            }) =>
              (!condition || condition({ currentUser })) &&
              !currentUser?.mustConfirmEmail ? (
                <li
                  className="main-menu-item"
                  key={key}
                >
                  <WrappedComponent
                    type={type}
                    data-testid={key}
                    className={className}
                    label={label}
                    to={to}
                    onClick={onClick}
                    icon={icon}
                    active={
                      !!(
                        pathname &&
                        to &&
                        matchPath(to, { path: pathname, exact: true })
                      )
                    }
                    currentUser={currentUser}
                    closeMainMenuMenu={closeMainMenuMenu}
                    notificationKey={notificationKey}
                  />
                </li>
              ) : null,
          )}
        </ul>
      )}
    </nav>
  );
};

export default MainMenu;
