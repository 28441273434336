import pluralize from 'pluralize';
import { scroller as scroll } from 'react-scroll';
import { put, select, take, takeEvery } from 'redux-saga/effects';
import ApiResource from '../api-resources';
import { SCROLL_TO_FIRST_SECTION_IN_ERROR } from '../actions/profile-completion';
import { fieldsetWithCompletionSelector } from '../selectors/profile';
import { createSuccessActionType } from '../utils/actions';

export function* watchForScrollToFirstSectionInError() {
  while (true) {
    // eslint-disable-line no-constant-condition
    yield take(SCROLL_TO_FIRST_SECTION_IN_ERROR);
    const fieldsetCompletions = yield select(fieldsetWithCompletionSelector);
    if (fieldsetCompletions && fieldsetCompletions.length > 0) {
      const firstInError = fieldsetCompletions.find((item) => item.error);
      if (firstInError) {
        const sectionName = firstInError.name;
        scroll.scrollTo(sectionName, { smooth: true, offset: -80 });
      }
    }
  }
}

/**
 * Due to shitty jsonapi front-end implementation
 * many operation will not update local data
 * We need to manually force api redux state update
 * @todo delete with https://github.com/MyJobGlasses/Olympus/issues/3517 💩
 */
export function* watchForProfileSpecificUpdates() {
  yield takeEvery(
    [createSuccessActionType('UPLOAD', ApiResource.EMPLOYEE_PROMOTION)],
    function* ({ data } = {}) {
      Object.keys(data).forEach((key) => {
        data[pluralize(key)] = Object.values(data[key]);
        delete data[key];
      });
      const employees = yield select((store) => store?.api?.profileEmployees);
      const profileUpdate = data?.profileEmployees[0];
      yield put({
        type: 'DIRTY_PATCH_API',
        data: {
          ...data,
          profileEmployees: employees.map((employee) => {
            if (employee.id !== profileUpdate.id) {
              return employee;
            }
            return profileUpdate;
          }),
        },
      });
    },
  );
}
