import { routerActions } from 'connected-react-router';
import Routes from '../routes';

const accountRestrictedCodes = [
  'account_restricted', // Bootstrap/Authentication
  'restricted_professionals_cannot_hibernate_error', // Planificate hibernation
  'recipient_is_restricted', // Book/Move/Cancel appointment
  'recipient_is_restricted', // Claim appointment
  'restricted', // Conversation start
  'sender_is_restricted', // send a message
];

/**
 * A middleware that catches every restricted meta code
 * to redirect to a custom page that informs about the restriction
 */
export const rerouteRestrictedAccountMiddleware = () => (next) => (action) => {
  if (accountRestrictedCodes.indexOf(action?.error?.[0]?.meta?.code) > -1) {
    next(routerActions.replace(Routes.accountRestrictedPage));
  }
  return next(action);
};
