import camelCase from 'lodash.camelcase';

/**
 * extract all school names from educations
 * @param {Object[]} educations
 */
export const schoolNamesFromEducations = (educations) =>
  educations.map((education) => education?.schoolName).join(', ');

/**
 * @param {Object} profile
 * @return {String|Null}
 */
export const getUnavailableUntilDate = (profile) =>
  profile?.unavailableUntil || null;

/**
 *
 * @param {Object} [user]
 * @returns {(
 *  'softDeleted'|
 *  'published'
 * )}
 */
export const getStudentPublicationStatus = (user) => {
  if (user?.softDeletedAt) {
    return 'softDeleted';
  }

  return 'published';
};

/**
 * @param {Object} profile
 * @param {Boolean} redCarpet
 * @return {(
 *    'unpublished'|
 *    'unpublished-after-published-one-time'|
 *    'redcarpetOnly'|
 *    'quota'|
 *    'frozen'|
 *    'softDeleted'|
 *    'hibernated'|
 *    'published'
 * )} return profile visibility status
 */
export const getProfileStatus = (profile, redCarpet = false) => {
  const visibilityStatus = camelCase(profile?.visibilityStatus || 'published');
  const firstPublishedAt = profile?.firstPublishedAt || null;
  const unavailableUntil = profile?.unavailableUntil || null;
  const published = profile?.published || null;

  if (firstPublishedAt === null) {
    return 'unpublished';
  }
  if (['softDeleted'].indexOf(visibilityStatus) > -1) {
    if (redCarpet) {
      return 'redcarpetOnly';
    }
    return visibilityStatus;
  }
  if (firstPublishedAt && !published) {
    return 'unpublishedAfterPublishedOneTime';
  }
  if (
    (visibilityStatus !== 'published' || unavailableUntil !== null) &&
    redCarpet
  ) {
    return 'redcarpetOnly';
  }
  if (visibilityStatus !== 'hibernated' && unavailableUntil !== null) {
    return 'quota';
  }
  if (
    ['icedUp', 'unpublished', 'restricted', 'invalidated'].indexOf(
      visibilityStatus,
    ) > -1
  ) {
    return 'frozen';
  }
  return visibilityStatus;
};
