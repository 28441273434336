import { clearObjectBlankValues } from '../utils/dev-tools';
import { flattenAttributes } from '../utils/json-api';

/**
 * Create a proper object for profile (with all needed relation)
 * @param {Object} profile
 * @param {Object} relations
 * @param {Object} relations.user
 */
export const profileMapper = (
  profile,
  { user = {}, promotions = [], similarProfessionals = [], educations = [] },
) => ({
  id: profile.id,
  ...profile.attributes,
  ...clearObjectBlankValues(profile?.attributes?.draft || {}),
  coordinates: {
    // Be careful, backend stores [X,Y] = Longitude, Latitude !! (And not LatLng)
    longitude: profile?.attributes?.coordinates?.[0] || 0.0,
    latitude: profile?.attributes?.coordinates?.[1] || 0.0,
  },
  user,
  promotions,
  similarProfessionals,
  educations: educations.map(flattenAttributes),
});
