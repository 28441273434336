import set from 'lodash.set';
import { languageWithoutRegionCode } from '../locales/configure';
import { companyType } from '../enums/company';

/**
 * Transform facet object from ElasticSearch to SelectField key/value objects
 * @param {Object} facets
 * @example transformFacetsToSelectFieldOption([{ key: 'demo', docCount: 12 }]) = [{ value: 'demo', label: 'demo (12)' }]
 */
export const transformFacetsToSelectFieldOption = (facets) =>
  facets.map((facet) => ({
    value: facet.key,
    label: `${facet?.label?.[languageWithoutRegionCode] || facet?.label?.fr || facet?.label || facet.key}`,
    disabled: facet.docCount === 0,
    rawCount: facet.docCount || 0,
  }));

/**
 * Transform search params extracted from url
 * to an initialValue(able) object
 * @param {Object} searchParams - A key/value object
 * @example searchParamsToInitialValues({ 'filters[tag_names]': 'it_digital' }) = { tag_names: ['it_digital'] };
 */
export const searchParamsToInitialValues = (searchParams) => {
  const filters = Object.keys(searchParams);
  const values = {};
  filters.forEach((value) => {
    let key, newValues;
    if (value === 'q') {
      set(values, 'q', [searchParams['q']]);
      return;
    } else if (value.startsWith('filters')) {
      key = value.substring(8, value.length - 1).replace('][', '.');
      if (key === 'company_sizes') {
        newValues = companyType
          .map(({ key }) => key)
          .filter((key) => searchParams[value].indexOf(key) > -1);
      } else {
        newValues = searchParams[value].split(',');
      }
    } else {
      return;
    }
    set(values, key, [...(values[key] || []), ...newValues]);
  });
  return values;
};

/**
 * Suppress [] and undefined from filters
 * @param {Object} filters
 */
export const cleanEmptyFilters = (filters) => {
  if (!filters) {
    return {};
  }
  const processedObject = Object.assign({}, filters); // prevent from modify object by reference
  Object.keys(filters).filter((facetKey) => {
    if (
      (Array.isArray(filters[facetKey]) && filters[facetKey].length === 0) ||
      filters[facetKey] === undefined
    ) {
      delete processedObject[facetKey];
    }
  });
  return processedObject;
};

/*
 * Map all facets to add label from translations
 * @param {Object} facets - An array of facets
 * @param {Object} translationTable - key/value object of translations
 * @example mapFacetsKeysWithLabels([{ key: 'demo', docCount: 12 }], {demo: 'Une clé de démo'}) = [{ value: 'demo', label: 'Une clé de démo', docCount: 12 }]
 */
export const mapFacetsKeysWithLabels = (facets, translationTable) =>
  facets.map((facet) => ({
    ...facet,
    label: translationTable[facet.key],
  }));
