import { defineMessages } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import {
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { PrivateDataProfileType } from '../@types/graphql.d';
import ApiResource from '../api-resources';
import { setContainerLoadState } from '../actions/container-load-state';
import { hideHowItWorkBanner } from '../actions/current-user-data';
import { toggleModal, TOGGLE_MODAL } from '../actions/modals';
import { displayToastr } from '../actions/toastr';
import { getProfile } from '../actions/profile';
import { updateResourceState } from '../actions/resources-states';
import { confirmUser, bootstrappingSuccess } from '../actions/user';
import { userSelector } from '../selectors/index';
import { getCurrentUserData } from '../selectors/current-user-data';
import {
  currentUserIcedUpDuringWarmUpSelector,
  currentUserUnpublishReasonSelector,
  currentUserIcedUpDueToCrisisSelector,
} from '../selectors/profile';
import { urlSearchSelector } from '../selectors/router';
import {
  getReadSuccessActionType,
  getReadErrorActionType,
  getUpdateSuccessActionType,
  getUpdateErrorActionType,
  createSuccessActionType,
  createErrorActionType,
  createRequestActionType,
} from '../utils/actions';
import { getFirstErrorMetaCode } from '../utils/json-api';
import { gimmeFeedback } from '../utils/error';
import { t } from '../utils/translate';
import { doesUserNeedToConfirmEmail, getUserMainProfile } from '../utils/user';
import {
  avatar,
  firstName,
  profileId,
  profileType,
  unreadMessageCount,
} from '../variables/privateData';
import { loadNotRepliedConversations } from './conversations';
import {
  requestIfNecessaryLanguages,
  requestIfNecessaryAvatarCropInformations,
  requestUserEmailConfirmation,
} from './signin';
import { errorTracker } from 'error-tracker/error-tracker';

export const profileSagaTranslations = defineMessages({
  yourAccountIsReady: {
    id: 'toastr.profile.yourAccountIsReady',
    defaultMessage: 'Félicitations, votre adresse email a bien été confirmée.',
    description: 'Toastr lorsque le Membre clique sur le mail de validation',
  },
  pleaseCompleteAllYourData: {
    id: 'toastr.profile.pleaseCompleteAllYourData',
    defaultMessage:
      'Merci de remplir ces dernières informations pour pouvoir publier votre profil',
    description:
      "Toastr lorsque le profile d'un Ambassadeur n est pas complet ",
  },
  toastrBadCheshire: {
    id: 'toastr.profile.badCheshireHash',
    defaultMessage:
      "Ce lien n'était pas adressé à l'adresse {user_mail}, avec laquelle vous êtes connecté. Si vous avez créé deux comptes, veuillez supprimer celui que vous n'utilisez pas.",
    description: 'Message d erreur si le cheshire hash n est pas bon.',
  },
});

/* Must somehow load current user data so
 * information related to current user can be displayed appropriately
 * - User avatar in header
 * - Unread message count in header
 * - Display pending appointment reviews
 * etc.
 * Currently implemented by making a GET request on the whole profile
 * Later on we may rely more on localStorage + dedicated endpoint to retrieve up-to-date status
 */
export function* bootstrapCurrentUser() {
  try {
    yield put(getProfile());
  } catch (error) {
    errorTracker.sendJSONApiError('BootstrapCurrentUser', "Can't bootstrap user", { error: JSON.stringify(error) });
  }
}

/*
 * Handle user bootstrapping AFTER receiving user+profile information from the API
 */
export function* handleUserBootstrapping() {
  const currentUserData = yield select(getCurrentUserData('email'));
  if (currentUserData) {
    return;
  }
  yield put(setContainerLoadState('bootstrap-current-user', { loading: true }));
  const { success } = yield race({
    success: take(getReadSuccessActionType('USER')),
    error: take(getReadErrorActionType('USER')),
  });
  if (success) {
    yield call(hydrateCurrentUserData);
    yield put(
      setContainerLoadState('bootstrap-current-user', {
        success: true,
        loaded: true,
      }),
    );
    yield put(bootstrappingSuccess(success.data));
    yield fork(notifyIfFollowingTheLinkOfSomeoneElse);
    yield call(requestEmailConfirmationIfNecessary);
    yield call(requestIfNecessaryLanguages);
    yield call(informOfUnpublication);
    yield call(requestIfNecessaryAvatarCropInformations);
    // NOTE : to be successful this requires the pendingAppointmentReviews relationship to be sideloaded
    // NOTE 2: even if the take() picks a read success
    // corresponding to a different getUser request (eg visiting profile, etc.) without that sideload
    // it should at least be fail-safe
    // (unless the #displayPendingReviews crashes whn receiving payload with incomplete sideloads)
  } else {
    yield put(
      setContainerLoadState('bootstrap-current-user', {
        error: true,
        loaded: true,
      }),
    );
  }
}

/**
 * Store data about current user
 */
export function* hydrateCurrentUserData() {
  const currentUser = yield select(userSelector);
  const mainProfile = getUserMainProfile(currentUser);
  profileId(mainProfile?.id);
  firstName(currentUser?.firstName);
  avatar(currentUser?.avatar?.cropped?.url);
  switch (mainProfile?.type) {
    case 'mentor':
      profileType(PrivateDataProfileType.Mentor);
      break;
    case 'student':
      profileType(PrivateDataProfileType.Student);
      break;
    case 'employee':
      profileType(PrivateDataProfileType.Employee);
      break;
  }
  unreadMessageCount(currentUser?.unreadConversationsCount || 0);
  if (mainProfile?.hideHowItWorks || false) {
    yield put(hideHowItWorkBanner());
  }
}

/**
 * Function to retrieve distante image and retrieve it as blob
 * @param {String} url - url of the image
 */
export const retrieveBlobFromImageUrl = (url) =>
  new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then(resolve)
      .catch(reject);
  });

/*
 * Execute any necessary API call to retrieve profile Information to show in header, etc.
 * @param { Object } user
 * @param { String } user.type
 */
export function* resolveProfile() {
  yield put(getProfile());
  return yield race({
    success: take('@@api/READ_RESOURCE_USER_SUCCESS'),
    error: take('@@api/READ_RESOURCE_USER_ERROR'),
  });
}

/**
 * Confirms the user on Hermes
 * and show a toastr with feedback
 *
 * @param { String } confirmation_token
 */
export function* handleConfirmationToken(confirmation_token) {
  yield put(confirmUser(confirmation_token));

  const { success, error } = yield race({
    success: take(getUpdateSuccessActionType(ApiResource.USER)),
    error: take(getUpdateErrorActionType(ApiResource.USER)),
  });

  if (success) {
    toastr.success(t(profileSagaTranslations.yourAccountIsReady));
  } else if (error) {
    gimmeFeedback(error);
  }
}

/**
 * Inform of one of unpublished state
 */
export function* informOfUnpublication() {
  const isManuallyUnpublished = yield call(informIfManuallyUnpublished);
  if (isManuallyUnpublished) {
    return;
  }
  const icedUpAndInWarmup = yield call(informIfIcedUpAndInWarmup);
  if (icedUpAndInWarmup) {
    return;
  }
  const icedUpAndInCrisis = yield call(informIfIcedUpAndInCrisis);
  if (icedUpAndInCrisis) {
    return;
  }
}

/**
 * Display icedup modal (for inwarmup)
 * @todo to move to bootstrap saga file
 * @returns {Boolean} - true if professional is iced_up & in_warmup
 */
export function* informIfIcedUpAndInWarmup() {
  const needWarmupWithIcedUpInformation = yield select(
    currentUserIcedUpDuringWarmUpSelector,
  );
  if (needWarmupWithIcedUpInformation) {
    yield call(loadNotRepliedConversations);
    yield put(
      toggleModal('unpublished', true, { inWarmup: true, icedUp: true }),
    );
    yield take(TOGGLE_MODAL);
    return true;
  }
  return false;
}

/**
 * Display icedup modal (for in crisis)
 * @todo to move to bootstrap saga file
 * @returns {Boolean} - true if professional is iced_up & in_warmup
 */
export function* informIfIcedUpAndInCrisis() {
  const needIcedUpDueToCrisisInformation = yield select(
    currentUserIcedUpDueToCrisisSelector,
  );
  if (needIcedUpDueToCrisisInformation) {
    yield call(loadNotRepliedConversations);
    yield put(
      toggleModal('unpublished', true, { inCrisis: true, icedUp: true }),
    );
    yield take(TOGGLE_MODAL);
    return true;
  }
  return false;
}

/**
 * Display unpublish modal (select the best)
 * @returns {Boolean} - true if professional is unpublished
 */
export function* informIfManuallyUnpublished() {
  const needManualyUnpublishedInformation = yield select(
    currentUserUnpublishReasonSelector,
  );
  if (needManualyUnpublishedInformation) {
    if (needManualyUnpublishedInformation === 'company_admin_decision') {
      yield put(
        toggleModal('unpublished', true, { unpublished: true, byRH: true }),
      );
      yield take(TOGGLE_MODAL);
      return true;
    } else if (needManualyUnpublishedInformation === 'mjg_admin_decision') {
      yield put(
        toggleModal('unpublished', true, { unpublished: true, byMJG: true }),
      );
      yield take(TOGGLE_MODAL);
      return true;
    }
  }
  return false;
}

/**
 * Save professional unavailabilities to the store
 */
export function* watchProfessionalsUnavailabilities() {
  yield takeEvery(
    [
      createRequestActionType('READ', ApiResource.PROFILE_EMPLOYEE),
      createRequestActionType('READ', ApiResource.PROFILE_MENTOR),
    ],
    function* (requestData) {
      const professionalID = requestData?.id;
      if (!professionalID) {
        return;
      }
      const { error } = yield race({
        // only match success to prevent unecessary wait
        success: take([
          createSuccessActionType('READ', ApiResource.PROFILE_EMPLOYEE),
          createSuccessActionType('READ', ApiResource.PROFILE_MENTOR),
        ]),
        error: take([
          createErrorActionType('READ', ApiResource.PROFILE_EMPLOYEE),
          createErrorActionType('READ', ApiResource.PROFILE_MENTOR),
        ]),
      });
      if (error) {
        const status = getFirstErrorMetaCode(error);
        const resourceType = error?.resourceType;
        switch (status) {
          case 'unpublished_professional':
          case 'soft_deleted_professional':
            yield put(
              updateResourceState({
                id: professionalID,
                resourceType,
                state: {
                  code: status,
                },
              }),
            );
            break;
        }
      }
    },
  );
}

/**
 * Ensure cheshire key to be for the current user
 */
export function* notifyIfFollowingTheLinkOfSomeoneElse() {
  const urlParams = yield select(urlSearchSelector);
  const urlCheshireHash = urlParams?.cheshire_hash;
  if (urlCheshireHash) {
    const user = yield select(userSelector);
    const userCheshireHash = user?.cheshireHash;
    if (userCheshireHash) {
      if (userCheshireHash !== urlCheshireHash) {
        yield put(
          displayToastr(
            'error',
            t(profileSagaTranslations.toastrBadCheshire, {
              user_mail: user?.email,
            }),
            undefined,
            { timeOut: 0 },
          ),
        );
      }
    } else {
      errorTracker.sendJSONApiError('FollowingTheLinkOfSomeoneElse', 'Alice fail to found the EAT ME cake :(', { user, urlCheshireHash });
    }
  }
}

/**
 * Check if user need to confirm his email
 * And display request to confirm email modale
 */
export function* requestEmailConfirmationIfNecessary() {
  const user = yield select(userSelector);
  const needEmailConfirmation = doesUserNeedToConfirmEmail(user);
  if (needEmailConfirmation) {
    requestUserEmailConfirmation();
  }
}
