import React, { useContext } from 'react';
import { ProfileType } from '../../@types/jsonapi.d';
import NotFound from '../../containers/not-found/NotFound';
import { CurrentUserContext } from '../../contexts/current-user-context/CurrentUserContext'

interface ProtectRouteOptions {
  allowedProfiles: ProfileType[];
}

export function redirectToAccount() {
  // eslint-disable-next-line
  window.location.href = `${window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL}?redirect=${encodeURIComponent(window.location.href)}`;
}

export default function protectRoute<T extends object>(
  Component: React.ComponentType<T>,
  { allowedProfiles }: ProtectRouteOptions,
): React.ComponentType<any> {
  return function WithRowRendererWrapper(props: T) {
    return (
      <Component
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  };
}
