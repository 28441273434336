import { actions } from 'react-redux-toastr';

/**
 * Wrapper to be able to display toastr
 * More powerable than toastr.'action' because testable
 * @param {String} type - Toastr type
 * @param {String} [title] - Toastr title (displayed as bold)
 * @param {String} [description] - Toastr description (displayed as normal)
 * @param {Object} [options] - List of toastr options
 */
export const displayToastr = (type, title, description, options = {}) => {
  return actions.add({
    type,
    position: 'top-left',
    options: {
      ...options,
      showCloseButton: true,
    },
    title,
    description,
  });
};
