import React from 'react';
import { compose, branch, renderComponent } from 'recompose';
import { FormattedMessage } from 'react-intl';

const ProfileStudentSoftDeletedInformation = () => (
  <FormattedMessage
    id="ProfileSoftDeletionInformation.student"
    defaultMessage="Cet Membre n'est malheureusement plus disponible pour échanger avec des professionnels, et a quitté la plateforme"
    description="Message d'information si le destinataire d'un message a été supprimé"
  >
    {(txt) => <p className="soft-deletion-information student">{txt}</p>}
  </FormattedMessage>
);

const ProfileProfessionalSoftDeletedInformation = () => (
  <FormattedMessage
    id="ProfileSoftDeletionInformation.professional"
    defaultMessage="Ce professionnel n'est malheureusement plus disponible pour échanger avec des Membres, et a quitté la plateforme"
    description="Message d'information si le destinataire d'un message a été supprimé"
  >
    {(txt) => <p className="soft-deletion-information professional">{txt}</p>}
  </FormattedMessage>
);

export default compose(
  branch(
    ({ isProfessional }) => isProfessional,
    renderComponent(ProfileStudentSoftDeletedInformation),
  ), // if connected user is professional
)(ProfileProfessionalSoftDeletedInformation);
