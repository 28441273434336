import React from 'react';
import { languageWithoutRegionCode } from '../../../../../locales/configure';
import { t } from '../../../../../utils/translate';
import translations from './LanguageSelectorSimple.translations';

import './LanguageSelectorSimple.scss';

const LanguageSelectorSimple = () => {
  function handleChange(value) {
    window.localStorage?.setItem('language', value);
    window.location.reload();
  }

  function chooseFR() {
    handleChange('fr');
  }

  function chooseEN() {
    handleChange('en');
  }

  return (
    <div className="language-selector-simple">
      <button
        type="button"
        className={`language-selector-option ${languageWithoutRegionCode === 'fr' ? 'selected' : ''}`}
        onClick={chooseFR}
        disabled={languageWithoutRegionCode === 'fr'}
      >
        {t(translations.frLanguage)}
      </button>
      |
      <button
        type="button"
        className={`language-selector-option ${languageWithoutRegionCode === 'en' ? 'selected' : ''}`}
        onClick={chooseEN}
        disabled={languageWithoutRegionCode === 'en'}
      >
        {t(translations.enLanguage)}
      </button>
    </div>
  );
};

export default LanguageSelectorSimple;
