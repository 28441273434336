import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../avatar/Avatar';

import './AvatarShowcase.scss';

const AvatarShowcase = ({ users, count }) => (
  <div className="avatar-showcase">
    {users.map((user, key) => (
      <div
        className="avatar-item"
        key={user.id}
      >
        {key === 0 && count && <span className="counter">{count}</span>}
        <Avatar
          avatar={user?.avatar || {}}
          className="avatar"
        />
      </div>
    ))}
  </div>
);

AvatarShowcase.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.shape({}).isRequired,
    }).isRequired,
  ),
  count: PropTypes.number,
};

export default AvatarShowcase;
