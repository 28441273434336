import { TOGGLE_MODAL } from '../actions/modals';

const defaultState = {
  confirmDelete: { isOpened: false, params: null },
  takeAndCropPicture: { isOpened: false, params: null },
  phone: { isOpened: false },
  review: { isOpened: false, params: null },
  requestLanguage: { isOpened: false, params: null },
  addNewSchool: { isOpened: false, params: null },
  unpublished: { isOpened: false, params: {} },
  bunny: { isOpened: false, params: {} },
  requestUserEmailConfirmation: { isOpened: false, params: {} },
};

const modals = (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        [action.key]: {
          isOpened: action.value,
          params: action.value ? action.params : state[action.key].params,
        },
      };
    default:
      return state;
  }
};
export default modals;
