export const SET_VIEW = '@@view/SET_VIEW';
export const HYDRATE_VIEW = '@@view/HYDRATE_VIEW';
export const SHOW_LOADER = '@@view/SHOW_LOADER';
export const SET_INITIALIZED = '@@view/SET_INITIALIZED';
export const TRACK_VIEW = '@@view/TRACK_VIEW';

export const setView = (view, id) => ({
  type: SET_VIEW,
  view,
  id,
});

export const hydrateView = (route, id) => ({
  type: HYDRATE_VIEW,
  route,
  id,
});

export const showLoader = (view, isLoading) => ({
  type: SHOW_LOADER,
  view,
  isLoading,
});

export const setInitialized = (view) => ({
  type: SET_INITIALIZED,
  view,
});

export const trackView = ({ route, pathname, id }) => ({
  type: TRACK_VIEW,
  route,
  pathname,
  id,
});
