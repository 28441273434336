export enum GraphqlGeneralErrors {
  GRAPHQL_NO_NETWORK = 'GRAPHQL_NO_NETWORK',
  GRAPHQL_UNKNOWN = 'GRAPHQL_UNKNOWN',
}

export enum CompanyQueryErrors {
  COMPANY_COMPANY_NOT_FOUND = 'COMPANY_COMPANY_NOT_FOUND',
  COMPANY_UNAUTHORIZED = 'COMPANY_UNAUTHORIZED',
}

export enum PartnerSchoolErrors {
  PARTNER_SCHOOL_UNAUTHORIZED = 'PARTNER_SCHOOL_UNAUTHORIZED',
}

export enum InviteEmployeeErrors {
  EMAIL_ALREADY_INVITED = 'EMAIL_ALREADY_INVITED',
  EMAIL_ALREADY_INVITED_WITH_OTHER_NAME = 'EMAIL_ALREADY_INVITED_WITH_OTHER_NAME',
  EMAIL_ALREADY_USED = 'EMAIL_ALREADY_USED',
  EMAIL_BLANK = 'EMAIL_BLANK',
  EMAIL_INVALID = 'EMAIL_INVALID',
  COMPANY_ATTRIBUTE_BLANK = 'COMPANY_ATTRIBUTE_BLANK',
  INVITE_EMPLOYEE_COMPANY_NOT_FOUND = 'INVITE_EMPLOYEE_COMPANY_NOT_FOUND',
  NOT_ADMIN_OF_COMPANY = 'NOT_ADMIN_OF_COMPANY',
  INVITE_EMPLOYEE_UNAUTHORIZED = 'INVITE_EMPLOYEE_UNAUTHORIZED',
}

export enum ApproveEmployeeErrors {
  APPROVE_EMPLOYEE_EMPLOYEE_NOT_FOUND = 'APPROVE_EMPLOYEE_EMPLOYEE_NOT_FOUND',
  CANNOT_APPROVE_INCOMPLETE_EMPLOYEE = 'CANNOT_APPROVE_INCOMPLETE_EMPLOYEE',
  CANNOT_APPROVE_SOFT_DELETE_EMPLOYEE = 'CANNOT_APPROVE_SOFT_DELETE_EMPLOYEE',
  CANNOT_APPROVE_INVALID_EMPLOYEE = 'CANNOT_APPROVE_INVALID_EMPLOYEE',
  APPROVE_EMPLOYEE_UNAUTHORIZED = 'APPROVE_EMPLOYEE_UNAUTHORIZED',
}

export enum PublishEmployeeErrors {
  PUBLISH_EMPLOYEE_EMPLOYEE_NOT_FOUND = 'PUBLISH_EMPLOYEE_EMPLOYEE_NOT_FOUND',
  PUBLISH_EMPLOYEE_UNAUTHORIZED = 'PUBLISH_EMPLOYEE_UNAUTHORIZED',
  CANNOT_PUBLISH_PROFILE = 'CANNOT_PUBLISH_PROFILE',
  CANNOT_PUBLISH_EMPLOYEE_PACK_SIZE_EXCEEDED = 'CANNOT_PUBLISH_EMPLOYEE_PACK_SIZE_EXCEEDED',
  PUBLISH_EMPLOYEE_MISSING_COMPANY_LOGO = 'PUBLISH_EMPLOYEE_MISSING_COMPANY_LOGO',
}

export enum SendEmployeeInvitationEmailErrors {
  SEND_EMPLOYEE_INVITATION_EMAIL_EMPLOYEE_NOT_FOUND = 'SEND_EMPLOYEE_INVITATION_EMAIL_EMPLOYEE_NOT_FOUND',
  SEND_EMPLOYEE_INVITATION_EMAIL_EMPLOYEE_INVALID = 'SEND_EMPLOYEE_INVITATION_EMAIL_EMPLOYEE_INVALID',
}

export enum UnpublishEmployeeErrors {
  UNPUBLISH_EMPLOYEE_EMPLOYEE_NOT_FOUND = 'UNPUBLISH_EMPLOYEE_EMPLOYEE_NOT_FOUND',
  UNPUBLISH_EMPLOYEE_UNAUTHORIZED = 'UNPUBLISH_EMPLOYEE_UNAUTHORIZED',
  CANNOT_UNPUBLISH_PROFILE = 'CANNOT_UNPUBLISH_PROFILE',
}

export enum ArchiveEmployeeErrors {
  ARCHIVE_EMPLOYEE_EMPLOYEE_NOT_FOUND = 'ARCHIVE_EMPLOYEE_EMPLOYEE_NOT_FOUND',
  ARCHIVE_EMPLOYEE_UNAUTHORIZED = 'ARCHIVE_EMPLOYEE_UNAUTHORIZED',
  CANNOT_ARCHIVE_EMPLOYEE_HAS_FUTURE_APPOINTMENTS = 'CANNOT_ARCHIVE_EMPLOYEE_HAS_FUTURE_APPOINTMENTS',
}

export enum CompaniesErrors {
  HAS_NO_COMPANIES = 'HAS_NO_COMPANIES',
  COMPANIES_UNAUTHORIZED = 'COMPANIES_UNAUTHORIZED',
}

export enum CompanyReportsErrors {
  COMPANY_REPORT_COMPANY_NOT_FOUND = 'COMPANY_REPORT_COMPANY_NOT_FOUND',
  COMPANY_REPORT_UNAUTHORIZED = 'COMPANY_REPORT_UNAUTHORIZED',
}

export enum CreateContractualFdrErrors {
  DESCRIPTION_BLANK = 'DESCRIPTION_BLANK',
  DESCRIPTION_TOO_LONG = 'DESCRIPTION_TOO_LONG',
  NUMBER_OF_MANDATORY_APPOINTMENTS_PER_STUDENT_BLANK = 'NUMBER_OF_MANDATORY_APPOINTMENTS_PER_STUDENT_BLANK',
  NUMBER_OF_MANDATORY_APPOINTMENTS_PER_STUDENT_NOT_A_NUMBER = 'NUMBER_OF_MANDATORY_APPOINTMENTS_PER_STUDENT_NOT_A_NUMBER',
  DIPLOMA_LEVEL_BLANK = 'DIPLOMA_LEVEL_BLANK',
  DIPLOMA_LEVEL_INCLUSION = 'DIPLOMA_LEVEL_INCLUSION',
  CAMPUS_NAME_BLANK = 'CAMPUS_NAME_BLANK',
  CAMPUS_NAME_TOO_SHORT = 'CAMPUS_NAME_TOO_SHORT',
  CAMPUS_NAME_TOO_LONG = 'CAMPUS_NAME_TOO_LONG',
  SUPERVISOR_EMAIL_INVALID = 'SUPERVISOR_EMAIL_INVALID',
  SUPERVISOR_EMAIL_FORBIDDEN_EMAIL = 'SUPERVISOR_EMAIL_FORBIDDEN_EMAIL',
  STARTS_AT_BLANK = 'STARTS_AT_BLANK',
  ENDS_AT_BLANK = 'ENDS_AT_BLANK',
  ENDS_AT_NOT_BETWEEN_1W_AND_1Y_OF_STARTS_AT = 'ENDS_AT_NOT_BETWEEN_1W_AND_1Y_OF_STARTS_AT',
  ACADEMIC_YEAR_BLANK = 'ACADEMIC_YEAR_BLANK',
  CONVERSATION_PENALTY_ACTIVATED_BLANK = 'CONVERSATION_PENALTY_ACTIVATED_BLANK',
  APPOINTMENT_BONUS_ACTIVATED_BLANK = 'APPOINTMENT_BONUS_ACTIVATED_BLANK',
  CONVERSATION_BONUS_ACTIVATED_BLANK = 'CONVERSATION_BONUS_ACTIVATED_BLANK',
  EMAIL_REMINDER_ACTIVATED_BLANK = 'EMAIL_REMINDER_ACTIVATED_BLANK',
  APPOINTMENT_BONUS_THRESHOLD1_BLANK = 'APPOINTMENT_BONUS_THRESHOLD1_BLANK',
  APPOINTMENT_BONUS_THRESHOLD1_OUT_OF_RANGE = 'APPOINTMENT_BONUS_THRESHOLD1_OUT_OF_RANGE',
  APPOINTMENT_BONUS_THRESHOLD2_OUT_OF_RANGE = 'APPOINTMENT_BONUS_THRESHOLD2_OUT_OF_RANGE',
  CONVERSATION_BONUS_DEADLINE_BLANK = 'CONVERSATION_BONUS_DEADLINE_BLANK',
  CONVERSATION_BONUS_MESSAGE_COUNT_BLANK = 'CONVERSATION_BONUS_MESSAGE_COUNT_BLANK',
  EMAIL_REMINDER_LANGUAGE_BLANK = 'EMAIL_REMINDER_LANGUAGE_BLANK',
  EMAIL_REMINDER_LANGUAGE_INCLUSION = 'EMAIL_REMINDER_LANGUAGE_INCLUSION',
  EMAIL_REMINDER_WEEKLY_RATE_BLANK = 'EMAIL_REMINDER_WEEKLY_RATE_BLANK',
  EMAIL_REMINDER_WEEKLY_RATE_NOT_A_NUMBER = 'EMAIL_REMINDER_WEEKLY_RATE_NOT_A_NUMBER',
  EMAIL_REMINDER_WEEKLY_RATE_LESS_THAN_OR_EQUAL_TO = 'EMAIL_REMINDER_WEEKLY_RATE_LESS_THAN_OR_EQUAL_TO',
  EMAIL_REMINDER_WEEKLY_RATE_GREATER_THAN_OR_EQUAL_TO = 'EMAIL_REMINDER_WEEKLY_RATE_GREATER_THAN_OR_EQUAL_TO',
  // globals
  CREATE_CONTRACTUAL_FDR_UNAUTHORIZED = 'CREATE_CONTRACTUAL_FDR_UNAUTHORIZED',
  PAST_STARTS_AT = 'PAST_STARTS_AT',
  CONTRACTUAL_FDR_IS_DUPLICATE = 'CONTRACTUAL_FDR_IS_DUPLICATE',
  PARTIAL_SAVE = 'PARTIAL_SAVE',
}

export enum ConfigureContractualAssignmentErrors {
  CONFIGURE_CONTRACTUAL_ASSIGNMENT_UNAUTHORIZED = 'CONFIGURE_CONTRACTUAL_ASSIGNMENT_UNAUTHORIZED',
  CONFIGURE_CONTRACTUAL_ASSIGNMENT_FDR_NOT_FOUND = 'CONFIGURE_CONTRACTUAL_ASSIGNMENT_FDR_NOT_FOUND',
  NAME_BLANK = 'NAME_BLANK ',
  NAME_TAKEN = 'NAME_TAKEN',
  NAME_TOO_SHORT = 'NAME_TOO_SHORT',
  NAME_TOO_LONG = 'NAME_TOO_LONG',
  EMAIL_LANGUAGE_BLANK = 'EMAIL_LANGUAGE_BLANK',
  EMAIL_LANGUAGE_INCLUSION = 'EMAIL_LANGUAGE_INCLUSION',
  SIZE_BLANK = 'SIZE_BLANK',
  SIZE_NOT_A_NUMBER = 'SIZE_NOT_A_NUMBER',
  SIZE_GREATER_THAN_OR_EQUAL_TO = 'SIZE_GREATER_THAN_OR_EQUAL_TO',
  SIZE_LESS_THAN_OR_EQUAL_TO = 'SIZE_LESS_THAN_OR_EQUAL_TO',
}

export enum AffiliateToAssignmentErrors {
  AFFILIATE_TO_ASSIGNMENT_UNAUTHORIZED = 'AFFILIATE_TO_ASSIGNMENT_UNAUTHORIZED',
  ASSIGNMENT_AFFILIATION_TOKEN_INVALID = 'ASSIGNMENT_AFFILIATION_TOKEN_INVALID',
  AFFILIATION_TO_ASSIGNMENT_TOO_LATE = 'AFFILIATION_TO_ASSIGNMENT_TOO_LATE',
}

export enum AffiatedAssignmentQueryErrors {
  AFFILIATED_ASSIGNMENTS_UNAUTHORIZED = 'AFFILIATED_ASSIGNMENTS_UNAUTHORIZED',
}

export enum ConfiguredAssignmentListErrors {
  CONFIGURED_ASSIGNMENTS_UNAUTHORIZED = 'CONFIGURED_ASSIGNMENTS_UNAUTHORIZED',
}

export enum ConfiguredAssignmentQueryErrors {
  CONFIGURED_ASSIGNMENT_ASSIGNMENT_NOT_FOUND = 'CONFIGURED_ASSIGNMENT_ASSIGNMENT_NOT_FOUND',
  CONFIGURED_ASSIGNMENT_UNAUTHORIZED = 'CONFIGURED_ASSIGNMENT_UNAUTHORIZED',
}

export enum UpdateCompanyMutationErrors {
  UPDATE_COMPANY_COMPANY_NOT_FOUND = 'UPDATE_COMPANY_COMPANY_NOT_FOUND',
  UPDATE_COMPANY_NOT_AUTHORIZED = 'UPDATE_COMPANY_NOT_AUTHORIZED',
}

export enum UpdateCompanyGeneralInformationsMutationErrors {
  SECTOR_BLANK = 'SECTOR_BLANK',
}

export enum UpdateCompanyDescriptionMutationErrors {
  DESCRIPTION_TOO_LONG = 'DESCRIPTION_TOO_LONG',
}

export enum UpdateCustomAttributeMutationErrors {
  UPDATE_CUSTOM_ATTRIBUTES_CUSTOM_EMPLOYEE_ATTRIBUTE_TOO_LONG = 'UPDATE_CUSTOM_ATTRIBUTES_CUSTOM_EMPLOYEE_ATTRIBUTE_TOO_LONG',
  CUSTOM_EMPLOYEE_ATTRIBUTE_ALREADY_USED = 'CUSTOM_EMPLOYEE_ATTRIBUTE_ALREADY_USED',
  UPDATE_CUSTOM_ATTRIBUTE_COMPANY_NOT_FOUND = 'UPDATE_CUSTOM_ATTRIBUTE_COMPANY_NOT_FOUND',
  UPDATE_CUSTOM_ATTRIBUTE_CUSTOM_EMPLOYEE_ATTRIBUTE_NOT_FOUND = 'UPDATE_CUSTOM_ATTRIBUTE_CUSTOM_EMPLOYEE_ATTRIBUTE_NOT_FOUND',
}

export enum AddCompanyRecruitingWorkAreaMutationErrors {
  WORKING_AREA_ALREADY_EXISTS = 'WORKING_AREA_ALREADY_EXISTS',
  WORKING_AREA_EMPTY = 'WORKING_AREA_EMPTY',
}

export enum SendMessageMutationErrors {}

export enum UpdateAssignmentGeneralInformationErrors {
  UPDATE_CONTRACTUAL_ASSIGNMENT_UNAUTHORIZED = 'UPDATE_CONTRACTUAL_ASSIGNMENT_UNAUTHORIZED',
  UPDATE_CONTRACTUAL_ASSIGNMENT_ASSIGNMENT_NOT_FOUND = 'UPDATE_CONTRACTUAL_ASSIGNMENT_ASSIGNMENT_NOT_FOUND',
  SIZE_BLANK = 'SIZE_BLANK',
  SIZE_NOT_A_NUMBER = 'SIZE_NOT_A_NUMBER',
  SIZE_GREATER_THAN_OR_EQUAL_TO = 'SIZE_GREATER_THAN_OR_EQUAL_TO',
  SIZE_LESS_THAN_OR_EQUAL_TO = 'SIZE_LESS_THAN_OR_EQUAL_TO',
  NAME_BLANK = 'NAME_BLANK ',
  NAME_TAKEN = 'NAME_TAKEN',
  NAME_TOO_SHORT = 'NAME_TOO_SHORT',
  NAME_TOO_LONG = 'NAME_TOO_LONG',
}

export enum CompanyEmployeeAppointmentErrors {
  COMPANY_EMPLOYEE_APPOINTMENT_APPOINTMENT_NOT_FOUND = 'COMPANY_EMPLOYEE_APPOINTMENT_APPOINTMENT_NOT_FOUND',
}
