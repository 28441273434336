import { createSelector } from 'reselect';
import at from 'lodash.at';
import camelCase from 'lodash.camelcase';
import map from 'lodash.map';
import mapKeys from 'lodash.mapkeys';
import snakeCase from 'lodash.snakecase';
import isEmpty from 'lodash.isempty';
import set from 'lodash.set';
import memoize from 'lodash.memoize';
import {
  hasSubmitSucceeded,
  hasSubmitFailed,
  isValid,
  isPristine as isPristineForm,
} from 'redux-form';
import moment from 'moment';
import {
  flattenAttributes,
  findAllInverseRelationship,
  findAllRelationships,
  findInverseRelationship,
  findRelationship,
} from '../utils/json-api';
import { JUST_SIGNED_UP_CONTEXT } from '../context';
import { profileMapper } from '../mappers/profile';
import { getUserMainProfile, isPublished } from '../utils/user';
import { getUserById } from './typed/user';
import {
  currentUserSelector,
  isProfessionalSelector,
  profileMentorsSelector,
  profileStudentsSelector,
  userSelector,
  usersSelector,
} from './index';

const mjgAdminsApiSelector = ({ api: { profileMjgAdmins } }) =>
  profileMjgAdmins;
const companyAdminsApiSelector = ({ api: { profileCompanyAdmins } }) =>
  profileCompanyAdmins;

const currentUserMjgAdminApiSelector = createSelector(
  currentUserSelector,
  mjgAdminsApiSelector,
  (user, mjgAdmins) => findInverseRelationship(user, mjgAdmins, 'user'),
);

const currentUserCompanyAdminApiSelector = createSelector(
  currentUserSelector,
  companyAdminsApiSelector,
  (user, companyAdmins) => findInverseRelationship(user, companyAdmins, 'user'),
);

export const currentUserAdminProfilesSelector = createSelector(
  currentUserMjgAdminApiSelector,
  currentUserCompanyAdminApiSelector,
  (mjgAdmin, companyAdmin) => [mjgAdmin, companyAdmin].filter((p) => p),
);

/**
 * Retrieves all users from redux store
 * @param {Object} state - Redux store
 */
export const usersJsonApiFromState = (store) => store?.api?.users || [];

/**
 * Retrieves all profile employees from redux store
 * @param {Object} state - Redux store
 */
export const profileEmployeesJsonApiFromState = (store) =>
  store?.api?.profileEmployees || [];

/**
 * Retrieves all profile mentors from redux store
 * @param {Object} state - Redux store
 */
export const profileMentorsJsonApiFromState = (store) =>
  store?.api?.profileMentors || [];

/**
 * Retrieves all profile seekers from redux store
 * @param {Object} state - Redux store
 */
export const profileSeekersJsonApiFromState = (store) =>
  store?.api?.profileSeekers || [];

/**
 * Retreive professional profile for logged user
 * @return {Object||null}
 */
export const currentProfessionalProfileSelector = createSelector(
  getUserById(),
  profileEmployeesJsonApiFromState,
  profileMentorsJsonApiFromState,
  (currentUser, allEmployeeProfiles, allMentorProfiles) => {
    const loggedProfileIds = (currentUser?.profiles || []).map(({ id }) => id);
    return (
      [...allEmployeeProfiles, ...allMentorProfiles].find(
        ({ id }) => loggedProfileIds.indexOf(id) > -1,
      ) || null
    );
  },
);

/**
 * Retreive professional profile for logged user
 * @return {Object||null}
 */
export const currentStudentProfileSelector = createSelector(
  getUserById(),
  profileStudentsSelector,
  (currentUser, allStudents) => {
    const loggedProfileIds = (currentUser?.profiles || []).map(({ id }) => id);
    return (
      allStudents.find(({ id }) => loggedProfileIds.indexOf(id) > -1) || null
    );
  },
);

/**
 * Return fields relative to fieldset
 * @param {Object} state - redux store
 * @param {String} fieldsetName - name of current fieldset
 * @returns {Array} - list of all fields for fieldset
 */
export const fieldsetFields = (state, fieldsetName) =>
  state?.profileCompletion?.fieldset?.[fieldsetName]?.fields;

/**
 * On optional fieldset we only check points number to test if at least one item is valide
 * @param {Object} state - Redux store
 * @param {String} fieldsetName - Name of fieldset (as exist in redux)
 * @param {Boolean} optional - Does current fieldset to test is optionnal
 * @returns {Boolean}
 */
export const optionalValidation = createSelector(
  fieldsetFields,
  userSelector,
  (_, fieldsetName, optional) => ({ fieldsetName, optional }),
  (fields, user, { optional, fieldsetName }) => {
    return (
      !optional ||
      at(
        user,
        `profile.profileCompletion.fieldsets.${fieldsetName}.currentPoints`,
      )[0] > 0
    );
  },
);

/**
 *
 * @param {Object} state - Redux store
 * @param {String} fieldsetName - Name of fieldset (as exist in redux)
 * @param {Boolean} optional - Does current fieldset to test is optionnal
 * @returns {Boolean|undefined} - True if fieldset valide, False if not, undefined if not exist in API
 */
export const isFieldsetInSuccess = () =>
  createSelector(
    fieldsetFields,
    userSelector,
    optionalValidation,
    (state, fieldsetName) => ({ state, fieldsetName }),
    (fields, user, optionalValidationResult, { state, fieldsetName }) => {
      if (!optionalValidationResult) {
        return false;
      }
      if (fields instanceof Array) {
        const invalid = fields.reduce((previous, field) => {
          if (!isValid(field)(state)) {
            return true;
          }
          if (hasSubmitFailed(field)(state)) {
            return true;
          }
          return previous;
        }, false);
        if (invalid) {
          return false;
        }
      }
      const mainProfile = getUserMainProfile(user);
      return (
        mainProfile?.profileCompletion?.fieldsets?.[fieldsetName]?.complete ||
        false
      );
    },
  );

/**
 * Fieldset order in display
 * @param {Object} state - Redux store
 * @param {String} fieldsetName - Name of fieldset (as exist in redux)
 */
export const fieldsetCurrentOrder = (state, fieldsetName) =>
  state?.profileCompletion?.fieldset?.[fieldsetName]?.order;

/**
 * Return which section is inprogress
 * @param {Object} state
 */
export const maxProfileSectionInProgress = (state) =>
  state?.profileCompletion?.currentProfileSection || 0;

/**
 * Return profile page context
 * @param {Object} state
 */
export const profilePageContext = (state) =>
  state?.profileCompletion?.context || null;

/**
 * Check if fieldset (passed as param) is in error state
 * @param {Object} state - Redux store
 * @param {String} fieldsetName - Name of fieldset (as exist in redux)
 * @param {Boolean} optional - Does current fieldset to test is optionnal
 * @returns {Boolean} - Is fieldset in error
 */
export const isFieldsetInError = (user) =>
  createSelector(
    maxProfileSectionInProgress,
    isFieldsetInSuccess(user),
    profilePageContext,
    fieldsetCurrentOrder,
    (_, __, optional) => optional,
    (sectionInprogres, completeness, context, order, isOptional) => {
      if (isOptional) {
        return false;
      }
      const isSignupContext = context === JUST_SIGNED_UP_CONTEXT;
      const isLowOrderThanCurrentTouched = sectionInprogres > order;
      // a = completion, b = context, c = modified, d = order < max edit order (last edited section)
      // (!a && !b && !c && !d) || (!a && !b && !c && d) || (!a && !b && c && !d) || (!a && !b && c && d) || (!a && b && !c && d) || (!a && b && c && d)
      // so => (d || !b) && !a
      return (
        (isLowOrderThanCurrentTouched || !isSignupContext) && !completeness
      );
    },
  );

/**
 * Check if a list of AutosubmitFields are pristine
 * @param {Object} state - store
 * @param {Array} fields - list of fields to group
 * @returns {Bool} - true if pristine, false if dirty
 */
export const isPristine = (state, fields) => {
  let pristine = false;

  if (fields instanceof Array) {
    pristine = fields.reduce((previous, field) => {
      if (!isPristineForm(field)(state)) {
        return false;
      }
      return previous;
    }, true);
  }

  return pristine;
};

/**
 * Return if list of fields has successfully be submitted (success or error)
 * @param {Object} state - store
 * @param {Array} fields - list of fields to group
 * @returns {Bool} - true if async validate, false elsewhere
 */
export const hasReceivedAsyncValidationResults = (state, fields) => {
  let asyncValidate = false;

  if (fields instanceof Array) {
    asyncValidate = fields.reduce((previous, field) => {
      if (hasSubmitSucceeded(field)(state) || hasSubmitFailed(field)(state)) {
        return true;
      }
      return previous;
    }, false);
  }

  return asyncValidate;
};

/**
 * Check if field valid on server
 * @param {Object} state
 * @param {String} field - field name
 */
export const isFieldCompletedOnServer = createSelector(
  userSelector,
  (state, field) => ({ state, field }),
  (user, { field }) => {
    return at(
      user,
      `profile.profileCompletion.fields.${camelCase(field)}.complete`,
    )[0];
  },
);

/**
 * Use (api) data on a profile to decide if a specific field is valid (or not)
 * @param {String} field - Field name to check
 * @param {String} profile - Profile which we want to know if field is valid
 * @returns true if the field is valid for `profile`, false otherwise
 */
export const isFieldCompletedOnServerForContext = (
  field,
  profile,
  completionKey = 'profileCompletion',
) => at(profile, `${completionKey}.fields.${camelCase(field)}.complete`)[0];

/**
 * Return completion for fieldset
 * @param {Object} state - store
 * @param {String} fieldName - fieldset name
 */
export const fieldsetOfFieldState = (state, fieldName) => {
  const completion =
    state && state.profileCompletion && state.profileCompletion.fieldset;
  if (!completion) {
    return;
  }
  const fieldsetName = Object.keys(completion).find(
    (fieldset) =>
      completion[fieldset].fields &&
      completion[fieldset].fields.indexOf(fieldName) > -1,
  );
  return isFieldsetInError(state, fieldsetName);
};

/**
 * Return all registered fieldset with completion state
 * @param {Object} state - redux store
 * @return {Array} - all fielset (by order) with success/error state
 * @example return [{ name: 'tags', success: true, error: false }]
 */
export const fieldsetWithCompletionSelector = (state) => {
  const fieldsets =
    state && state.profileCompletion && state.profileCompletion.fieldset;
  return Object.keys(fieldsets)
    .map((name) => ({
      name,
      success: isFieldsetInSuccess(userSelector(state))(state, name),
      error: isFieldsetInError(userSelector(state))(state, name),
    }))
    .sort(
      (a, b) => fieldsetCurrentOrder(state, a) - fieldsetCurrentOrder(state, b),
    );
};

const currentMoment = moment();
/**
 * Return user educations ready for form (key snake_cased)
 * If no educations, return a default education with only set current year
 * @param {Object} user
 */
export const getUserEducationForForm = (user) => {
  const educations = user?.profile?.educations || [];
  if (educations.length) {
    return map(educations, (e) => mapKeys(e, (v, k) => snakeCase(k)));
  }
  return [{ ended_at: currentMoment }];
};

/**
 * Return all languages for a profile
 * @param {Object} user
 * @param {Object} user.profile
 * @param {Array} user.profile.languages
 * @returns {Array} - eg: ['french', 'english'...]
 */
export const languagesSelector = (user) => user?.profile?.languages || [];

/**
 * Return whether user already choose his languages
 * @param {Object} state - redux store
 * @returns {bool}
 */
export const needFillLanguageSelector = createSelector(
  isProfessionalSelector,
  userSelector,
  (isProfessional, user) => {
    if (!isProfessional) {
      return false;
    }
    const languages = languagesSelector(user);
    const published = isPublished(user);
    return published && isEmpty(languages);
  },
);

/**
 * Return all promotions for a specific profile
 * @param {Object} user
 * @returns {Array}
 */
export const promotionForProfileSelector = (user) =>
  user?.profile?.promotions || [];

/**
 * Retrieves all promotions from state (only for ambassador)
 * @param {Object} state - Redux store
 */
const profileContentPromotionsJsonApiFromState = ({
  api: { profileContentPromotions = [] },
}) => profileContentPromotions;

/**
 * Retrieves all professional employees from state
 * @param {Object} state - Redux store
 */
const professionalEmployeesJsonApiFromState = ({
  api: { professionalEmployees = [] },
}) => professionalEmployees;

/**
 * Retrieve all profile educations
 */
export const profileContentEducationsJsonApiFromState = ({
  api: { profileContentEducations = [] },
}) => profileContentEducations;

/**
 * Retrieves all professional employees from state
 * @param {Object} state - Redux store
 */
const professionalMentorsJsonApiFromState = ({
  api: { professionalMentors = [] },
}) => professionalMentors;

/**
 * Retrieves all VIPs profiles from state
 * @param {Object} state - Redux store
 */
const professionalVIPJsonApiFromState = ({ api: { profileVips = [] } }) =>
  profileVips;

/**
 * Retrieve all profile (of all types) from the store
 * @todo add selector for student profile
 * @param {Object} state - Redux store
 */
const allProfilesSelector = createSelector(
  profileEmployeesJsonApiFromState,
  profileMentorsJsonApiFromState,
  professionalVIPJsonApiFromState,
  (profileEmployees, profileMentors, profileVips) => [
    ...profileEmployees.map((p) => set(p, 'attributes.isEmployee', true)),
    ...profileMentors.map((p) => set(p, 'attributes.isMentor', true)),
    ...profileVips.map((p) => set(p, 'attributes.isVIP', true)),
  ],
);

/**
 * Retrieve all professionals (of all types) from the store
 * @param {Object} state - Redux store
 */
const allProfessionalsSelector = createSelector(
  professionalEmployeesJsonApiFromState,
  professionalMentorsJsonApiFromState,
  (professionalEmployees, professionalMentors) => [
    ...professionalEmployees
      .map((p) => set(p, 'attributes.isEmployee', true))
      .map(profileMapper),
    ...professionalMentors
      .map((p) => set(p, 'attributes.isMentor', true))
      .map(profileMapper),
  ],
);

/**
 * Selector to retrieve a profile from redux store
 * @param {String} id - ID of the profile to retrieve
 * @returns {Object} [profile]
 */
export const profileSelector = memoize((id) =>
  createSelector(
    allProfilesSelector,
    usersJsonApiFromState,
    profileContentPromotionsJsonApiFromState,
    profileContentEducationsJsonApiFromState,
    allProfessionalsSelector,
    (
      profiles,
      users,
      profileContentPromotions,
      profileContentEducations,
      professionals,
    ) => {
      const profile = profiles.find((profile) => profile.id === id);
      if (!profile) {
        return null;
      }
      const user = findRelationship(profile, users, 'user');
      const profilePromotions = findAllRelationships(
        profile,
        profileContentPromotions,
        'promotions',
      );
      const profileSimilarProfessionals = findAllRelationships(
        profile,
        professionals,
        'similarProfessionals',
      );
      const educations = findAllRelationships(
        profile,
        profileContentEducations,
        'educations',
      );
      return profileMapper(profile, {
        user: flattenAttributes(user || {}),
        promotions: profilePromotions.map((promotion) =>
          flattenAttributes(promotion),
        ),
        similarProfessionals: profileSimilarProfessionals,
        educations,
      });
    },
  ),
);

/**
 * Determine if current user is iced up and in_warmup
 * @param {Object} state - redux store
 * @returns {Boolean} - true if inWarmup + icedUp / false otherwise
 */
export const currentUserIcedUpDuringWarmUpSelector = createSelector(
  userSelector,
  (user) =>
    (user?.profile?.inWarmup || false) && (user?.profile?.icedUp || false),
);

/**
 * Determine if current user is iced up and in_crysis
 * @param {Object} state - redux store
 * @returns {Boolean} - true if inWarmup + inCrisis / false otherwise
 */
export const currentUserIcedUpDueToCrisisSelector = createSelector(
  userSelector,
  (user) =>
    (user?.profile?.inCrisis || false) && (user?.profile?.icedUp || false),
);

/**
 * Retrieve unpublish reason
 * @param {Object} state - redux store
 * @returns {Boolean|String} - Reason of unpublished or false if not unpublished
 */
export const currentUserUnpublishReasonSelector = createSelector(
  userSelector,
  (user) =>
    !(user?.profile?.published || false) &&
    (user?.profile?.lastUnpublicationReason || null),
);

/**
 * Determine if the current user is a student
 */
export const isStudentSelector = createSelector(
  userSelector,
  profileStudentsSelector,
  (user, profileStudents = []) => {
    if (!user) {
      return false;
    }
    return !!profileStudents.find(
      (student) => student?.relationships?.user?.data?.id === user.id,
    );
  },
);

/**
 * Retrieve all wishes from the store
 * @param {Object} state - redux store
 */
export const studentWishesJsonApiFromState = ({ api: { studentWishes } }) =>
  studentWishes;

/**
 * @param {Object} store
 * @param {Object[]} store.api
 * @returns {Object[]}
 */
export const studentInternshipsJsonApiFromState = ({ api }) =>
  api?.profileInternships || [];

/**
 * Retrieve wishes professional ids for current logged student
 * @param {Object} state - redux store
 */
export const wishesProfessionalIdsForCurrentStudentSelector = createSelector(
  currentStudentProfileSelector,
  studentWishesJsonApiFromState,
  (currentStudent, wishes) => {
    let finalWishes = [];
    const studentWishes = findAllRelationships(
      currentStudent,
      wishes,
      'wishes',
    );
    if (Array.isArray(studentWishes)) {
      finalWishes = [...studentWishes];
    }
    const inverseStudentWishes = findAllInverseRelationship(
      currentStudent,
      wishes,
      'student',
    );
    if (Array.isArray(inverseStudentWishes)) {
      finalWishes = [...finalWishes, ...inverseStudentWishes];
    }
    return finalWishes
      .sort((a, b) =>
        moment
          .utc(b?.attributes?.lastActivatedAt)
          .diff(moment.utc(a?.attributes?.lastActivatedAt)),
      )
      .filter((wish) => wish?.attributes?.active || false)
      .map((wish) => wish?.relationships?.professional?.data?.id);
  },
);

/**
 * Select wishes professionals from student wishlist
 * @param {Object} state - redux store
 * @returns {Object[]} - A list of professional
 */
export const wishesProfessionalsForCurrentStudentSelector = createSelector(
  wishesProfessionalIdsForCurrentStudentSelector,
  profileMentorsSelector,
  profileEmployeesJsonApiFromState,
  usersSelector,
  (
    wishesProfessionalIds,
    profileMentors = [],
    profileEmployees = [],
    users = [],
  ) => {
    const profiles = [
      ...profileMentors.map((profile) => ({
        ...profile,
        attributes: { ...profile.attributes, isMentor: true },
      })),
      ...profileEmployees.map((profile) => ({
        ...profile,
        attributes: { ...profile.attributes, isEmployee: true },
      })),
    ];
    return wishesProfessionalIds
      .map((professionalID) => {
        let professional = profiles.find(({ id }) => id === professionalID);
        if (!professional) {
          return;
        }
        let user = findRelationship(professional, users, 'user');
        if (!user) {
          return;
        }
        return {
          ...flattenAttributes(professional),
          ...(user?.attributes || {}),
        };
      })
      .filter(Boolean);
  },
);

/**
 * Retrieve all schools from json api state
 * @param {Object} state - redux store
 */
export const schoolsFromJsonApiSelector = ({ api: { schools } }) => schools;

/**
 * Retrieve the numbre of missing information in profile
 */
export const numberOfMissingInformationInProfileSelector = createSelector(
  currentProfessionalProfileSelector,
  currentStudentProfileSelector,
  (currentProfessionalProfile, currentStudentProfile) => {
    let numberOfMissingInformation = 0;
    const profile = currentProfessionalProfile || currentStudentProfile;
    const field = profile?.attributes?.profileCompletion?.fields || {};
    Object.values(field).forEach(function (currentValue) {
      if (!currentValue.complete && currentValue.required) {
        return numberOfMissingInformation++;
      }
    });
    return numberOfMissingInformation;
  },
);
