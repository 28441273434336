import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { branch, compose, renderComponent, withProps } from 'recompose';
import { schoolNamesFromEducations } from '../../utils/profile';

const DefaultEducationName = ({ className }) => (
  <FormattedMessage
    id="EducationDisplay.defaultSchoolName"
    defaultMessage="Autre"
    description="Nom d'école par default si non disponible"
  >
    {(txt) => <span className={className}>{txt}</span>}
  </FormattedMessage>
);
DefaultEducationName.propTypes = {
  className: PropTypes.string,
};

const EducationDisplay = ({ className, activeSchoolName }) => (
  <span className={className}>{activeSchoolName}</span>
);
EducationDisplay.propTypes = {
  className: PropTypes.string,
  activeSchoolName: PropTypes.string,
};

export default compose(
  branch(
    ({ educations }) => Array.isArray(educations) && educations.length > 0,
    renderComponent(
      withProps(({ educations }) => ({
        activeSchoolName: schoolNamesFromEducations(educations),
      }))(EducationDisplay),
    ),
  ),
  branch(
    ({ activeSchoolName }) => activeSchoolName,
    renderComponent(EducationDisplay),
  ),
)(DefaultEducationName);
