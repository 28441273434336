import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { clearObjectBlankValues } from '../utils/dev-tools';

// eslint-disable-next-line
const utmValidity =
  window.__RUNTIME_CONFIG__.REACT_APP_UTM_VALIDITY_IN_HOUR || 12;

/**
 * Retrieve netizen utm (only if not outdated)
 * @param {Object} state - redux store
 * @return {Object}
 */
export const utmSelector = (state) => {
  // retrieve current utm from navigation
  const navigationUtm = clearObjectBlankValues(state?.navigation?.utm || null);
  if (!isEmpty(navigationUtm)) {
    return navigationUtm;
  }
  // if retrieve utm from localstorage
  // but without save date, ignore
  const utmSavedAt = state?.storage?.utm?.utmSavedAt || null;
  if (!utmSavedAt) {
    return {};
  }
  // or if date > ENV_VALIDITY
  const diff = -1 * moment(utmSavedAt).diff(moment());
  if (diff > utmValidity * 1000 * 60 * 60) {
    return {};
  }
  // return uptodate utm from localstorage
  return clearObjectBlankValues(state?.storage?.utm?.utm || {});
};

/**
 * Get a selector for retrieve stored data
 * @param {String} key
 */
export const localStorageSelector =
  (key) =>
  /**
   * @param {ReduxStore} store
   * @param {Object} store.storage
   */
  ({ storage }) =>
    storage?.[key];

/**
 * Remember the signup flow when the user follows a link with the `signup=xx` URL param
 * @param {Object} state - redux store
 * @returns {String|undefined} - the flow type
 */
export const recallSignupFlowFromLocalStorage = ({ storage }) =>
  storage?.rememberedSignupFlow;
