import React, { useContext } from 'react';
import { SwitchIcon } from '@myjobglasses/dsm';
import classnames from 'classnames';
import { WaterfallProps } from '../../../../@types/HeaderConfig';
import useWindowSize from '../../../../../../../utils/hooks/useWindowSize';
import { t } from '../../../../../../../utils/translate';
import { breakpoints } from '../../../../../../../variables';
import UsernavContext from '../../../../context/UsernavContext';
import translations from './UsernavItemSwitchAccount.translations';
import styles from './UsernavItemSwitchAccount.module.scss';

interface UsernavItemSwitchExternalAccountProps extends WaterfallProps {}

function UsernavItemSwitchExternalAccount({
  to,
  className,
  label,
}: UsernavItemSwitchExternalAccountProps) {
  const { closeUsernavMenu } = useContext(UsernavContext);
  function onClick() {
    closeUsernavMenu();
  }
  const { width } = useWindowSize();
  if (!to) {
    return null;
  }
  const disabled = width < breakpoints.sm;
  return (
    <a
      href={to}
      className={classnames(className, styles.usernavItemSwitchAccount, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <SwitchIcon className={styles.icon} />
      <span className={styles.label}>
        {label}
        {disabled && (
          <>
            <br />
            <span className={styles.onlyDesktop}>
              {`(${t(translations.onlyDesktop)})`}
            </span>
          </>
        )}
      </span>
    </a>
  );
}

export default UsernavItemSwitchExternalAccount;
