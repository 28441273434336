import { toastr } from 'react-redux-toastr';
import { defineMessages } from 'react-intl';
import {
  password_pending_confirmation,
  password_reset,
} from '../utils/url-parser';
import { t } from '../utils/translate';

const translations = defineMessages({
  emailResetSendToYourMail: {
    id: 'toastr.home.emailResetSendToYourMail',
    defaultMessage:
      "Un E-mail contenant les instructions de réinitialisation du mot de passe a été envoyé à l'adresse que vous avez indiquée",
    description:
      'Page d accueil - Retour sur aphrodyte après demande de mot de passe oublié',
  },
  passwordUpdateSuccessfull: {
    id: 'toastr.home.passwordUpdateSuccessfull',
    defaultMessage: 'Votre mot de passe a été édité avec succès',
    description: 'Page d accueil - Reset du mot de passe',
  },
});

export function trackHomeView() {
  if (password_pending_confirmation) {
    // Wrapping the content into a setTimeout function enables it to be executed
    // out of this saga thread, leaving the toastr component enough time to be loaded
    setTimeout(function () {
      toastr.success(t(translations.emailResetSendToYourMail));
    }, 0);
  } else if (password_reset) {
    // Wrapping the content into a setTimeout function enables it to be executed
    // out of this saga thread, leaving the toastr component enough time to be loaded
    setTimeout(function () {
      toastr.success(t(translations.passwordUpdateSuccessfull));
    }, 0);
  }
}
