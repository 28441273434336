/**
 * list of all professional speech language
 */
export const availableLanguages = ['french', 'english'];

/**
 * Correlation table for access to language properties
 */
export const languageTable = {
  french: {
    code: 'fr',
  },
  english: {
    code: 'gb',
  },
};
