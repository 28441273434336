import { flattenAttributes } from './utils/json-api';

// Define an event
const pageView = (action) => ({
  hitType: 'pageview',
  page: action.payload,
});

const studentSignup = (action) => {
  const {
    data: { user, profileStudent },
  } = action;
  // If not a student or not have user in payload, not catch
  if (!user || !profileStudent) return;
  const userValues = Object.values(user);
  // If user in payload is empty, not catch
  if (!userValues.length) return;
  const userFlatten = flattenAttributes(userValues[0]);

  let student = {
    id: userFlatten.id,
    firstName: userFlatten.firstName,
    lastName: userFlatten.lastName,
    email: userFlatten.email,
  };

  return {
    hitType: 'studentRegistration',
    student: student,
  };
};

// Map the event to a Redux action
export const analyticsEvents = {
  '@@router/LOCATION_CHANGE': pageView,
  '@@api/CREATE_RESOURCE_CREDENTIALS_SUCCESS': studentSignup,
};

export default analyticsEvents;
