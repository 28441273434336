import {
  INCREASE_TO_CURRENT_SECTION,
  PROFILE_REGISTER_FIELDS,
  SET_PROFILE_PAGE_CONTEXT,
} from '../actions/profile-completion';

const defaultState = {
  currentProfileSection: 0,
  fieldset: {},
  context: null,
};

const profileCompletion = (state = defaultState, action) => {
  switch (action.type) {
    case PROFILE_REGISTER_FIELDS:
      return {
        ...state,
        fieldset: {
          ...state.fieldset,
          [action.fieldsetName]: {
            fields: action.fieldNames,
            order: action.order,
          },
        },
      };
    case INCREASE_TO_CURRENT_SECTION:
      // prevent from updating with low order
      if (action.current < state.currentProfileSection) {
        return state;
      }
      return {
        ...state,
        currentProfileSection: action.current,
      };
    case SET_PROFILE_PAGE_CONTEXT:
      return {
        ...state,
        context: action.context,
      };
    default:
      return state;
  }
};

export default profileCompletion;
