import find from 'lodash.find';
import memoize from 'lodash.memoize';
import { createSelector } from 'reselect';
import { findRelationship } from '../utils/json-api';
import { urlSearchSelector } from './router';
import { usersSelector, profileStudentsSelector } from './index';

const redCarpetRollsJsonapiSelector = ({
  api: { recommendationRedCarpetRolls },
}) => recommendationRedCarpetRolls;

/*
 * Selects information required to present a student from a rolled carpet
 *
 * @param { String } rollID - id of some created rolled carpet
 *
 * @return { Object[] } student
 * @return { Object[] } student.schoolName
 * @return { Object[] } student.studentFirstName
 */
export const rolledStudentSelector = (rollId) =>
  createSelector(
    redCarpetRollsJsonapiSelector,
    profileStudentsSelector,
    usersSelector,
    (rolls, students, users) => {
      const roll = find(rolls, (r) => r.id === rollId);
      const student = findRelationship(roll, students, 'seeker');
      const user = findRelationship(student, users, 'user');
      return {
        studentFirstName: user.attributes.firstName,
        schoolName: student.attributes.activeSchoolName,
      };
    },
  );

/*
 * Assumes all available rolledRedCarpet belong to the current user
 * Might have to tweak this for multi-account or when admins have access to the website
 */
export const hasRolledRedCarpetSelector = memoize((proId) =>
  createSelector(
    redCarpetRollsJsonapiSelector,
    urlSearchSelector,
    (rolls, urlParams) =>
      (urlParams.utm_campaign &&
        urlParams.utm_campaign.indexOf('red-carpet') !== -1) ||
      !!(rolls && rolls.find((r) => r.attributes.professionalId === proId)),
  ),
);
