import 'ahoy.js';
import { TRACK_SEARCH } from '../actions/search';
import { getCookie } from '../utils/cookie';
import {
  TRACK_VIEWED_PROFESSIONAL,
  VIEW_PROFESSIONAL_FROM_SIMPROS,
  TRACK_VIEWED_OWN_PROFESSIONAL_PROFILE,
} from '../context';
import { REPORT_ACTION_TO_AHOY } from '../actions/ahoy';

const ahoy = window.ahoy;
ahoy.configure({
  // eslint-disable-next-line
  visitsUrl:
    window.__RUNTIME_CONFIG__.REACT_APP_AHOY_VISITS_ENDPOINT || '/ahoy/visits',
  // eslint-disable-next-line
  eventsUrl:
    window.__RUNTIME_CONFIG__.REACT_APP_AHOY_EVENTS_ENDPOINT || '/ahoy/events',
  // eslint-disable-next-line
  cookieDomain: window.__RUNTIME_CONFIG__.REACT_APP_AHOY_COOKIE_DOMAIN || '',
});

/**
 * Track a search
 * @param {Object} params - search payload
 */
const processTrackSearch = ({
  query,
  filters,
  page,
  size,
  topSearch,
  isRecommendations,
}) => {
  const eventProperties = {
    q: query,
    filters: filters,
    page,
    size,
    topSearch,
    isRecommendations,
  };
  ahoy.track('Searched', eventProperties);
};

/**
 * Track a view on professional
 * @param {Object} params
 * @param {String} params.id - id of the professional viewed
 * @param {String} params.user_id - Id of the logged user
 */
const processTrackViewedProfessional = ({
  id,
  user_id,
  navigationReferrer,
}) => {
  let eventProperties = {
    id,
    navigation_referrer: navigationReferrer,
  };

  let widgetCookie = getCookie('widget_source');

  let parsedWidgetCookie = {};
  try {
    if (widgetCookie && widgetCookie[0] !== '{') {
      widgetCookie = decodeURIComponent(widgetCookie);
    }
    parsedWidgetCookie = JSON.parse(widgetCookie);
  } catch (_) {
    parsedWidgetCookie = {};
  }

  eventProperties.widget_source_id = parsedWidgetCookie.widget_source_id;
  eventProperties.widget_source_id_added_at =
    parsedWidgetCookie.widget_source_id_added_at;

  if (user_id) {
    const coupleId = user_id + id;
    eventProperties.couple_id = coupleId;
  }

  ahoy.track('Viewed professional', eventProperties);
};

/**
 * Track a view on professional
 * @param {Object} params
 * @param {String} params.id - id of the professional viewed
 * @param {String} params.user_id - Id of the logged user
 * @param {String} params.navigationReferrer - context of professional loading
 */
const processTrackViewedOwnProfessionalProfile = ({
  id,
  user_id,
  navigationReferrer,
}) => {
  let eventProperties = {
    id,
    navigation_referrer: navigationReferrer,
    user_id,
  };

  ahoy.track('View own professional profile', eventProperties);
};

/**
 * Process a location change event to determine if
 * - On professional route
 * - On from similar professional context
 * @param {Object} params - Location change payload
 */
const processViewProfessionalFromSimpros = ({ professionalID }) => {
  const eventProperties = {
    id: professionalID,
  };

  ahoy.track('Clicked on similar professional', eventProperties);
};

/**
 * Inform ahoy from user interaction
 */
const processReportActionToAhoy = ({ name, params }) => {
  ahoy.track(name, params);
};

// List all events to process
const trackingActions = {
  [TRACK_SEARCH]: processTrackSearch,
  [TRACK_VIEWED_PROFESSIONAL]: processTrackViewedProfessional,
  [TRACK_VIEWED_OWN_PROFESSIONAL_PROFILE]:
    processTrackViewedOwnProfessionalProfile,
  [VIEW_PROFESSIONAL_FROM_SIMPROS]: processViewProfessionalFromSimpros,
  [REPORT_ACTION_TO_AHOY]: processReportActionToAhoy,
};

const processTrackingAction = (action) =>
  trackingActions[action.type] && trackingActions[action.type](action);
export const createAhoyMiddleware =
  () => (/* store */) => (next) => (action) => {
    processTrackingAction(action);
    return next(action);
  };

export default createAhoyMiddleware;
