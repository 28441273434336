import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'NoNetwork.description',
    defaultMessage: "Oups, il semblerait qu'on ait perdu le contact !",
  },
  refetchButton: {
    id: 'NoNetwork.refetchButton',
    defaultMessage: 'Rafraichir',
  },
});
