import {
  createRequestActionType,
  createSuccessActionType,
  createErrorActionType,
} from '../utils/actions';
import {
  JsonApiPostRelationshipOneToMany,
  JsonApiPostRelationshipOneToOne,
} from '../@types/jsonapi';
import ApiResource from '../api-resources';

export const CREATE_RESOURCE = '@@api/CREATE_RESOURCE';
export const CREATE_RESOURCE_SUCCESS = '@@api/CREATE_RESOURCE_SUCCESS';
export const CREATE_RESOURCE_ERROR = '@@api/CREATE_RESOURCE_ERROR';

/**
 * Action to request a resource
 */
type CrudType =
  | 'CREATE'
  | 'READ'
  | 'READ_LIST'
  | 'UPDATE'
  | 'DELETE'
  | 'UPLOAD'
  | 'HEAD';
interface RequestResourceData {
  resourceUri: string;
  id?: string;
  reset?: boolean;
  stateKey?: string;
  data: {
    id?: string;
    type: ValueOf<ApiResource>;
    attributes?: {
      [key: string]: any;
    };
    relationships?: {
      [key: string]:
        | JsonApiPostRelationshipOneToMany
        | JsonApiPostRelationshipOneToOne;
    };
    params?: {
      [key: string]: string;
    };
  };
  meta?: {
    [key: string]: any;
  };
  included?: {
    [key: string]: any;
  }[];
  params?: {
    [key: string]: string;
  };
  body?: FormData;
}
export function requestResource(
  crudType: CrudType,
  {
    resourceUri,
    id,
    reset = false,
    stateKey,
    data,
    meta,
    included,
    params,
    body,
  }: RequestResourceData,
  resolve?: PromiseResolveFn,
  reject?: PromiseRejectFn,
) {
  return {
    type: createRequestActionType(crudType, data.type),
    resourceUri,
    id,
    reset,
    stateKey,
    data,
    meta,
    included,
    params,
    body,
    resolve,
    reject,
  };
}

/**
 * Action triggered automaticly on request resource success
 */
interface RequestResourceSuccessData {
  resourceType: string;
  data: object;
  requestData: object;
  reset: boolean;
}
export function requestResourceSuccess(
  crudType: CrudType,
  {
    resourceType,
    data,
    requestData,
    reset = false,
  }: RequestResourceSuccessData,
) {
  return {
    type: createSuccessActionType(crudType, resourceType),
    reset,
    data,
    requestData,
  };
}

/**
 * Action triggered automaticly on request resource error
 */
interface RequestResourceErrorData {
  resourceType: string;
  error: object;
}
export function requestResourceError(
  crudType: CrudType,
  { resourceType, error }: RequestResourceErrorData,
) {
  return {
    type: createErrorActionType(crudType, resourceType),
    resourceType,
    error,
  };
}

// CREATE
export const createResource = (
  params: RequestResourceData,
  resolve?: PromiseResolveFn,
  reject?: PromiseRejectFn,
) => requestResource('CREATE', params, resolve, reject);
export const createResourceSuccess = (params: RequestResourceSuccessData) =>
  requestResourceSuccess('CREATE', params);
export const createResourceError = (params: RequestResourceErrorData) =>
  requestResourceError('CREATE', params);

// READ
export const readResource = (params: RequestResourceData) =>
  requestResource('READ', params);
export const readResourceSuccess = (params: RequestResourceSuccessData) =>
  requestResourceSuccess('READ', params);
export const readResourceError = (params: RequestResourceErrorData) =>
  requestResourceError('READ', params);

// READ_LIST
export const readListResource = (
  params: RequestResourceData,
  resolve?: PromiseResolveFn,
  reject?: PromiseRejectFn,
) => requestResource('READ_LIST', params, resolve, reject);
export const readListResourceSuccess = (params: RequestResourceSuccessData) =>
  requestResourceSuccess('READ_LIST', params);
export const readListResourceError = (params: RequestResourceErrorData) =>
  requestResourceError('READ_LIST', params);

// UPDATE
export const updateResource = (
  params: RequestResourceData,
  resolve?: PromiseResolveFn,
  reject?: PromiseRejectFn,
) => requestResource('UPDATE', params, resolve, reject);
export const updateResourceSuccess = (params: RequestResourceSuccessData) =>
  requestResourceSuccess('UPDATE', params);
export const updateResourceError = (params: RequestResourceErrorData) =>
  requestResourceError('UPDATE', params);

// DELETE
export const deleteResource = (
  params: RequestResourceData,
  resolve?: PromiseResolveFn,
  reject?: PromiseRejectFn,
) => requestResource('DELETE', params, resolve, reject);
export const deleteResourceSuccess = (params: RequestResourceSuccessData) =>
  requestResourceSuccess('DELETE', params);
export const deleteResourceError = (params: RequestResourceErrorData) =>
  requestResourceError('DELETE', params);

// UPDLOAD
export const uploadResource = (
  params: RequestResourceData,
  resolve?: PromiseResolveFn,
  reject?: PromiseRejectFn,
) => requestResource('UPLOAD', params, resolve, reject);
export const uploadResourceSuccess = (params: RequestResourceSuccessData) =>
  requestResourceSuccess('UPLOAD', params);
export const uploadResourceError = (params: RequestResourceErrorData) =>
  requestResourceError('UPLOAD', params);

// HEAD
export const headRessource = (
  params: RequestResourceData,
  resolve?: PromiseResolveFn,
  reject?: PromiseRejectFn,
) => requestResource('HEAD', params, resolve, reject);
export const headRessourceSuccess = (params: RequestResourceSuccessData) =>
  requestResourceSuccess('HEAD', params);
export const headRessourceError = (params: RequestResourceErrorData) =>
  requestResourceError('HEAD', params);
