export const SET_CONTAINER_LOAD_STATE =
  '@@containerLoadState/SET_CONTAINER_LOAD_STATE';

/**
 * Store container status (loading, loaded, error)
 * @param {String} containerName
 * @param {Object} params
 * @param {Boolean} [params.loading] - initial loading
 * @param {Boolean} [params.loaded] - initial loading success
 * @param {Boolean} [params.sending] - when we send data to server
 * @param {Boolean|Object} [params.error] - when server respond with error
 * @param {Boolean|Array} [params.success] - when server respond with success
 */
export const setContainerLoadState = (
  containerName,
  {
    loading = false,
    loaded = false,
    error = false,
    sending = false,
    success = false,
  },
) => ({
  type: SET_CONTAINER_LOAD_STATE,
  containerName,
  state: {
    loading,
    loaded,
    error,
    sending,
    success,
  },
});
