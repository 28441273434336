import { SubmissionError } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { displayToastr } from '../actions/toastr';
import { serverToClient } from './error';

/**
 * @param {Function} dispatch
 * @param {Function} action
 * @param {Object} params
 * @param {Object} options
 * @param {String} options.toastrMessage
 * @param {Bool} options.displayToastr
 * @returns Promise
 *
 * @example wrapDispatchWithPromise(dispatch,
 *   createNewMessage,
 *   { text: text, conversationId: id },
 *   { displayToastr: true, toastrMessage: t(someTranslation) })
 *
 */
export const wrapDispatchWithPromise = (
  dispatch,
  action,
  params,
  options = {},
) => {
  const { displayToastr, toastrMessage, toasterErrorMessage } = options;

  return new Promise((resolve, reject) => {
    dispatch(action(params, resolve, reject));
  })
    .then(() => {
      if (displayToastr !== false) {
        toastr.success(toastrMessage || '');
      }
    })
    .catch((error) => {
      if (displayToastr !== false && toasterErrorMessage) {
        toastr.error(toasterErrorMessage || '');
      }
      // 💩 https://github.com/MyJobGlasses/Hermes/issues/4673
      throw new SubmissionError(serverToClient(error));
    });
};

/**
 * @param {Function} dispatch
 * @param {Function} action
 * @param {Object} params
 * @param {Object} options
 * @param {String} options.toastrMessage
 * @param {Bool} options.displayToastr
 * @returns Promise
 *
 * @example handleFormSubmission(dispatch,
 *   createNewMessage,
 *   { text: text, conversationId: id },
 *   { displayToastr: true, toastrMessage: t(someTranslation) })
 *
 */
export const handleFormSubmission = (
  dispatch,
  action,
  params,
  options = {},
) => {
  const { displayToastr: shouldDisplayToastr, toastrMessage = '' } = options;

  return new Promise((resolve, reject) => {
    dispatch(action(params, resolve, reject));
  })
    .then(() => {
      if (shouldDisplayToastr !== false) {
        dispatch(displayToastr('error', toastrMessage));
      }
    })
    .catch((error) => {
      throw new SubmissionError({ _error: serverToClient(error) });
    });
};
