export const UPDATE_RESOURCE_STATE = '@@resourcesStates/UPDATE_RESOURCE_STATE';

/**
 * Redux action to save a resource state
 * @param {Object} params
 * @param {String} params.id - ID of the resource to save state
 * @param {String} params.resourceType - type of the resource
 * @param {Object} params.state - State of the resource
 * @param {String} params.state.code - Code of the status (eg: unpublished_professional)
 */
export const updateResourceState = ({ id, resourceType, state }) => ({
  type: UPDATE_RESOURCE_STATE,
  id,
  resourceType,
  state,
});
