import { createMatchSelector } from 'connected-react-router';
import { fork, put, select } from 'redux-saga/effects';
import Routes from '../../../../routes';
import { getEmployee } from '../../../../actions/profile';
import { hydrateEditProfileView } from '../../../user/profile/saga/hydrateEditProfileView';

export function* hydrateEmployeeEditionView() {
  const match = yield select(
    createMatchSelector(Routes.companyAdminEditAmbassador),
  );
  const matchMjgAdmin = yield select(
    createMatchSelector(Routes.mjgAdminCompaniesEditEmployee),
  );
  yield put(
    getEmployee({
      id: match?.params?.employeeid || matchMjgAdmin?.params?.employeeid,
    }),
  );
  yield fork(hydrateEditProfileView);
}
