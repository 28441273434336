import {
  SET_COMPANY_ID,
  SET_AUTH_TOKEN,
  HYDRATE_IDENTITY,
} from '../actions/identity';

const defaultState = {
  companyId: null,
};

const identity = (state = defaultState, action) => {
  switch (action.type) {
    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.id,
      };

    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.token,
      };

    case HYDRATE_IDENTITY:
      return action.identity;

    default:
      return state;
  }
};

export default identity;
