import { defineMessages } from 'react-intl';

export default defineMessages({
  frLanguage: {
    id: 'LanguageSelectorSimple.frLanguage',
    defaultMessage: 'FR',
    description: 'Langue français',
  },
  enLanguage: {
    id: 'LanguageSelectorSimple.enLanguage',
    defaultMessage: 'EN',
    description: 'Langue anglaise',
  },
});
