import { put, select } from 'redux-saga/effects';
import { hideHowItWorkBanner } from '../../../../actions/current-user-data';
import { updateProfile } from '../../../../actions/profile';
import { userSelector } from '../../../../selectors';
import { getUserMainProfile } from '../../../../utils/user';

/**
 * Handle when current user clicks on Got it button
 * on edit profile  view
 */
export function* handleHideHowItWorksBanner() {
  const currentUser = yield select(userSelector);
  const mainProfile = getUserMainProfile(currentUser);
  const isPublishedCurrentUser = mainProfile?.published || false;

  yield put(hideHowItWorkBanner());

  if (isPublishedCurrentUser) {
    yield put(
      updateProfile({
        profileId: mainProfile?.id,
        userType: mainProfile?.type,
        attributes: {
          hide_how_it_works: true,
        },
      }),
    );
  }
}
