import { fork, call, all } from 'redux-saga/effects';
import { confirmation_token } from '../utils/url-parser';
import { hydrateReviewView } from '../scenes/interactions/reviews/sagas/review';
import { hydrateForgottenPassword } from '../scenes/onboarding/reset-password/sagas/updatePassword';
import {
  createResource,
  watchReadResource,
  watchReadListResource,
  watchDeleteResource,
  watchUploadResource,
  watchUpdateResource,
  watchHeadResource,
} from './api';
import { watchHermesVersion } from './hermes';
import { signoutSaga } from './signout';
import {
  bootstrapCurrentUser,
  handleUserBootstrapping,
  handleConfirmationToken,
} from './profile';
import { createOrUpdateAppointment } from './appointments';
import { hydrateFromUrl } from './navigation';
import { store } from './storage';
import { initializeViewSaga, hydrateViewSaga, trackViewSaga } from './views';
import { watchToastrSaga } from './toastr';
import { watchReadNewConversation } from './conversations';
import { ensureSectorListInitialized } from './company';
import { watchDownload } from './download';
import { dirtyHellWatchers } from './dirty-watchers';
import { initializeFromUrl } from './initialize';
import {
  watchForProfileSpecificUpdates,
  watchForScrollToFirstSectionInError,
} from './profile-completion';
import {
  handleModerationAfterUpdateStatus,
  autoupdateLocalCompanyLogoWithServerData,
  autobuildModerationIndex,
} from './moderator';

export default function* root() {
  yield all([
    // Hermes API Saga
    fork(createResource),
    fork(watchReadResource),
    fork(watchReadListResource),
    fork(watchHeadResource),
    fork(watchUpdateResource),
    fork(watchDeleteResource),
    fork(watchUploadResource),
    fork(dirtyHellWatchers),
    // Watch user bootstrapping
    fork(handleUserBootstrapping),
    // Local Storage
    fork(store),
    // handle download actions
    fork(watchDownload),
  ]);

  if (confirmation_token) {
    yield call(handleConfirmationToken, confirmation_token);
  }

  // eslint-disable-next-line
  if (
    window.__RUNTIME_CONFIG__.REACT_APP_DEBUG_RESPONSIVE_BREAKPOINT === 'true'
  ) {
    yield fork(watchHermesVersion);
  }

  yield call(bootstrapCurrentUser);

  yield all([
    // fork all initialize features
    fork(initializeFromUrl),

    // views
    fork(initializeViewSaga),
    fork(hydrateViewSaga),
    fork(trackViewSaga),

    //forgotten password
    fork(hydrateForgottenPassword),

    // signout
    fork(signoutSaga),

    // professional
    fork(watchReadNewConversation),

    // profile completion
    fork(watchForScrollToFirstSectionInError),
    fork(watchForProfileSpecificUpdates),

    // moderation
    fork(autobuildModerationIndex),
    fork(autoupdateLocalCompanyLogoWithServerData),
    fork(handleModerationAfterUpdateStatus),
    fork(ensureSectorListInitialized),

    // toastr
    fork(watchToastrSaga),

    // review
    fork(hydrateReviewView),
  ]);

  yield call(hydrateFromUrl);
}
