import find from 'lodash.find';
import { matchPath } from 'react-router';
import { fork, put, take, takeEvery } from 'redux-saga/effects';
import {
  HYDRATE_VIEW,
  hydrateView,
  TRACK_VIEW,
  trackView,
} from '../actions/views';
import Routes from '../routes';
import { hydrateEmployeeProfileView } from '../scenes/company-admin/ambassadors/components/employee-profile-view/saga/hydrateEmployeeProfileView';
import { hydrateEmployeeEditionView } from '../scenes/company-admin/employee-edition/saga/hydrateEmployeeEditionView';
import {
  hydrateOneDayWithACeoView,
} from '../scenes/public/profile/sagas/hydrateProfessionalView';
import { hydrateEditProfileView } from '../scenes/user/profile/saga/hydrateEditProfileView';
import { getPathnameId } from '../utils/routes';
import {
  hydrateDownloadConversationAppointmentIcalendarView,
} from './conversations';
import { trackHomeView } from './home';
import { subscribe } from './realtime';
import { hydrateRedCarpetsView } from './red-carpets';

export const views = {
  [Routes.home]: {
    track: trackHomeView,
  },
  [Routes.oneDayWithACeo]: {
    hydrate: hydrateOneDayWithACeoView,
  },
  [Routes.downloadConversationAppointmentIcalendar]: {
    hydrate: hydrateDownloadConversationAppointmentIcalendarView,
    initialized: false,
  },
  [Routes.conversations]: {},
  [Routes.redCarpets]: {
    hydrate: hydrateRedCarpetsView,
    initialized: false,
  },
  [Routes.profile]: {
    hydrate: hydrateEditProfileView,
    initialized: false,
  },
  [Routes.profileAvatarEdit]: {
    hydrate: hydrateEditProfileView,
    initialized: false,
  },
  [Routes.softDeletionGoodbye]: {
    hydrate: false,
    initialized: false,
  },
  [Routes.accountRestrictedPage]: {
    hydrate: false,
    initialized: false,
  },
  [Routes.companyAdminShowAmbassador]: {
    hydrate: hydrateEmployeeProfileView,
  },
  [Routes.companyAdminEditAmbassador]: {
    hydrate: hydrateEmployeeEditionView,
  },
  [Routes.mjgAdminCompaniesShowEmployee]: {
    hydrate: hydrateEmployeeProfileView,
  },
  [Routes.mjgAdminCompaniesEditEmployee]: {
    hydrate: hydrateEmployeeEditionView,
  },
};

export const getMatchingRoute = (pathname) =>
  find(Routes, (route) =>
    matchPath(pathname, {
      path: route,
      exact: true,
    })
  );

export function* initializeViewSaga() {
  yield takeEvery('@@router/LOCATION_CHANGE', function* ({ payload }) {
    const pathname = payload?.location?.pathname;
    const matchingRoute = getMatchingRoute(pathname);
    if (matchingRoute) {
      if (views[matchingRoute]) {
        const id = getPathnameId(matchingRoute, pathname);
        yield put(hydrateView(matchingRoute, id));
        yield put(trackView({ route: matchingRoute, pathname, id }));
        if (views[matchingRoute].subscription) {
          yield fork(
            subscribe,
            views[matchingRoute].subscription,
            { closeOnLocationChange: true },
            { id }
          );
        }
      }
    }
  });
}

export function* hydrateViewSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { route, id } = yield take(HYDRATE_VIEW);
    const view = views[route];

    if (view && view.hydrate) {
      yield fork(view.hydrate, id, view.initialized);

      if (!view.initialized) {
        view.initialized = true;
      }
    }
  }
}

export function* trackViewSaga() {
  while (true) {
    // eslint-disable-line no-constant-condition
    const { route, pathname } = yield take(TRACK_VIEW);
    const view = views[route];

    if (view.track) {
      yield fork(view.track, { pathname });
    }
  }
}
