/* eslint-disable camelcase */
import ApiResource from '../../../../api-resources';
import { createResource, updateResource } from '../../../../actions/api';

export const CHOOSE_MISSED_APPOINTMENT_REASON =
  '@@appointments/CHOOSE_MISSED_APPOINTMENT_REASON';
export const SET_REVIEW = '@@appointments/SET_REVIEW';
export const SNOOZE_REVIEW = '@@appointments/SNOOZE_REVIEW';

/**
 *
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.values
 * @param {String} params.conversationId
 * @param {Function} resolve
 * @param {Function} reject
 */
export const createReview = (
  {
    id,
    isProfessional,
    question1,
    answer1,
    question2,
    answer2,
    answer2IsDismissed,
    question3,
    answer3,
    answer3IsDismissed,
    liked,
    messageForProfessional,
    messageForHr,
    feedback,
    contractType,
    duration,
    startsAtMonth,
    startsAtYear,
    zipCode,
    domains,
    wantToBeRecontacted,
    administrativeAreas,
    internationalOutsideFrance,
  },
  resolve,
  reject,
) => ({
  type: SET_REVIEW,
  id,
  isProfessional,
  question1,
  answer1,
  question2,
  answer2,
  answer2IsDismissed,
  question3,
  answer3,
  answer3IsDismissed,
  liked,
  messageForProfessional,
  messageForHr,
  feedback,
  wantToBeRecontacted,
  domains,
  zipCode,
  internationalOutsideFrance,
  administrativeAreas,
  contractType,
  duration,
  startsAtMonth,
  startsAtYear,
  resolve,
  reject,
});

/**
 *
 * @param {from_professional||from_student} type
 * @param {Number} id
 * @param {Object} data
 */
export const createReviewResource = ({ type, id, data }) =>
  createResource({
    resourceUri: `/messaging/appointments/${id}/review/${type}`,
    data: {
      id,
      type: ApiResource.MESSAGING_APPOINTMENT_REVIEW,
      attributes: {
        ...data,
      },
    },
  });

/**
 *
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.didNotShowUpReason
 * @param {Function} resolve
 * @param {Function} reject
 */
export const chooseMissedAppointmentReason = (
  { id, didNotShowUpReason, comment },
  resolve,
  reject,
) => ({
  type: CHOOSE_MISSED_APPOINTMENT_REASON,
  id,
  reason: didNotShowUpReason,
  comment,
  resolve,
  reject,
});

/**
 * REST call to set reason to missed appointment
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.reason
 */
export const missedAppointment = ({ id, reason, comment }) =>
  createResource({
    resourceUri: `/messaging/appointments/${id}/review/absence`,
    data: {
      type: ApiResource.ABSENCE,
      attributes: {
        reason: reason || 'cancelled',
        description: comment,
      },
    },
  });

/**
 *
 * @param {Object} params
 * @param {string} params.id
 */
export const snoozeReview = ({ id }, resolve, reject) => ({
  type: SNOOZE_REVIEW,
  id,
  resolve,
  reject,
});

/**
 *
 * @param {object} param
 * @param {string} param.id
 */
export const snoozeReviewResource = ({ id }) =>
  updateResource({
    resourceUri: `/messaging/appointments/${id}/snooze`,
    data: {
      type: ApiResource.MESSAGING_APPOINTMENT,
      attributes: {},
    },
  });
