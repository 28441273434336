import { put, race, select, take } from 'redux-saga/effects';
import camelCase from 'lodash.camelcase';
import moment from 'moment-timezone';
import {
  JsonApiBaseProfile,
  JsonApiProfileContentEducationAttributes,
  ProfileType,
  JsonApiProfileContentEducation,
} from '../../../@types/jsonapi.d';
import ApiResource from '../../../api-resources';
import { profileContentEducationsJsonApiFromState } from '../../../selectors/profile';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../../../utils/actions';
import { snakeCaseObjectKeys } from '../../../utils/dev-tools';
import {
  deleteProfileEducationResource,
  updateProfileEducationResource,
} from '../actions/profile';

interface HandleEducationsUpdatesParams {
  values: JsonApiProfileContentEducationAttributes & {
    profileId: string;
    profileType: ProfileType;
  };
  resolve: PromiseResolveFn;
  reject: PromiseRejectFn;
}
export function* handleEducationsUpdates({
  values: {
    id,
    schoolName,
    diplomaType,
    diplomaTitle,
    startedAt,
    endedAt,
    profileId,
    profileType,
  },
  resolve,
  reject,
}: HandleEducationsUpdatesParams) {
  yield put(
    updateProfileEducationResource(
      {
        profileId,
        userType: profileType,
        education: {
          ...snakeCaseObjectKeys<JsonApiProfileContentEducationAttributes>({
            id,
            schoolName,
            diplomaType,
            diplomaTitle,
            started_at: moment(startedAt).toISOString(),
            ended_at: moment(endedAt).toISOString(),
          }),
        },
      },
      resolve,
      reject,
    ),
  );

  const { success, error } = yield race({
    success: take(
      createSuccessActionType('UPDATE', ApiResource.PROFILE_CONTENT_EDUCATION),
    ),
    error: take(
      createErrorActionType('UPDATE', ApiResource.PROFILE_CONTENT_EDUCATION),
    ),
  });
  if (error && typeof reject === 'function') {
    reject(error?.error);
  } else if (success && typeof resolve === 'function') {
    resolve();
    // @todo SUPPRESS THIS WITH https://github.com/Startouf/MyJobGlasses/issues/2908
    const profileTypeCollectionName = camelCase(`profile/${profileType}s`);
    let collectionsToUpdate = yield select(
      (state) => state?.api?.[profileTypeCollectionName] || [],
    );
    collectionsToUpdate = collectionsToUpdate.map(
      (profile: JsonApiBaseProfile) => {
        if (profile.id === profileId) {
          return {
            ...profile,
            attributes: {
              ...(success?.data?.[camelCase(`profile/${profileType}`)]?.[
                profile.id
              ]?.attributes ||
                profile?.attributes ||
                {}),
            },
          };
        }
        return profile;
      },
    );
    yield put({
      type: 'DIRTY_PATCH_API',
      data: {
        [profileTypeCollectionName]: [...collectionsToUpdate],
      },
    });
    // END OF @todo
  }
}

interface HandleEducationsRemovalsParams {
  resourceId: string;
  profileId: string;
  profileType: ProfileType;
  resolve: PromiseResolveFn;
  reject: PromiseRejectFn;
}
export function* handleEducationsRemovals({
  resourceId,
  profileId,
  profileType,
  resolve,
  reject,
}: HandleEducationsRemovalsParams) {
  yield put(
    deleteProfileEducationResource({
      profileId,
      userType: profileType,
      deleteResourceId: resourceId,
    }),
  );

  const { error } = yield race({
    success: take(
      createSuccessActionType('UPDATE', ApiResource.PROFILE_CONTENT_EDUCATION),
    ),
    error: take(
      createErrorActionType('UPDATE', ApiResource.PROFILE_CONTENT_EDUCATION),
    ),
  });
  if (error) {
    if (typeof reject === 'function') {
      reject(error?.error);
    }
    return;
  }
  if (typeof resolve === 'function') {
    resolve();
  }
  // @todo SUPPRESS THIS WITH https://github.com/Startouf/MyJobGlasses/issues/2908
  const profileContentEducations = yield select(
    profileContentEducationsJsonApiFromState,
  );
  const profileContentEducationIndex = profileContentEducations.findIndex(
    (education: JsonApiProfileContentEducation) => education.id === resourceId,
  );
  delete profileContentEducations[profileContentEducationIndex];
  yield put({
    type: 'DIRTY_PATCH_API_DELETE',
    data: {
      profileContentEducations: [
        ...profileContentEducations.slice(0, profileContentEducationIndex),
        ...profileContentEducations.slice(
          profileContentEducationIndex + 1,
          profileContentEducations.length,
        ),
      ],
    },
  });
  // END OF @todo
}
