export const TOGGLE_MODAL = '@@modals/TOGGLE_MODAL';

/**
 * toggleModal
 * @param {String} key - Modal identifier
 * @param {Boolean} value - Visibility of the modal, true to show
 * @param {Object} [params] - Context-dependent
 *
 * @returns {{
 *   type: String,
 *   key: String,
 *   value: Boolean
 *   params: Object
 * }}
 */
export function toggleModal(key, value, params) {
  const nonEmptyParams = params || {};
  return {
    type: TOGGLE_MODAL,
    key,
    value,
    params: nonEmptyParams,
  };
}

/******* Values for the "params" Object  ********/
/*** For employee signup from invitation
 *
 * @param {{
 *  profile: {
 *    // If invitation available :
 *    email: { String }
 *    firstName: { String }
 *    lastName: { String }
 *    invitationToken: { String }
 *    // Or if invitation already taken :
 *    invitationTokenAlreadyUsed: { Boolean or null}
 *  },
 *  flow: 'employee'
 * }} params
 */
