import { matchPath } from 'react-router';

/**
 * return id parameter
 * @param {String} route
 * @param {String} pathname
 */
export const getPathnameId = (route, pathname) => {
  pathname = pathname ? pathname : window.location.pathname;
  return matchPath(pathname, {
    path: route,
    exact: true,
  })
    ? pathname.split('/')[route.split('/').indexOf(':id')]
    : null;
};

/**
 * Back to top of the page
 */
export const scrollToTopOfPage = () => {
  if (typeof window.scrollTo === 'function') {
    window.scrollTo(0, 0);
  }
};
