import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { compose, withHandlers, withProps } from 'recompose';
import { toggleModal } from '../../actions/modals';
import {
  getNotRepliedConversationsFromIndexSelector,
  countOfnotRepliedConversation,
} from '../../selectors/conversations';
import Routes from '../../routes';
import AvatarShowcase from '../avatar-showcase/AvatarShowcase';

const inCrisisWithIcedUpModalContent = ({
  studentWithNoReply,
  goToFirstNotRepliedConversation,
  count,
}) => (
  <div className="in-warmup-with-icedup">
    <FormattedHTMLMessage
      id="inCrisisWithIcedUpModalContent.description"
      defaultMessage={
        'Votre profil n\'est plus publié <span class="highlight">car votre taux de réponse est passé sous la barrière des 50%.</span> Pour pouvoir être contacté par de nouveaux Membres, n\'hésitez pas à répondre à ceux qui vous ont contacté récemment.'
      }
      description="Description de l echec d echauffement"
    >
      {(txt) => (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: txt }}
        />
      )}
    </FormattedHTMLMessage>

    <AvatarShowcase
      users={studentWithNoReply}
      count={count}
    />

    <div className="actions">
      <Button
        bsStyle="primary"
        onClick={goToFirstNotRepliedConversation}
      >
        <FormattedMessage
          id="inCrisisWithIcedUpModalContent.reply"
          defaultMessage="Répondre"
          description="button pour aller à la première conversation non répondu"
        />
      </Button>
    </div>
  </div>
);

inCrisisWithIcedUpModalContent.propTypes = {
  studentWithNoReply: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  goToFirstNotRepliedConversation: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

export default compose(
  connect((state) => ({
    notRepliedConversations: getNotRepliedConversationsFromIndexSelector(state),
    count: countOfnotRepliedConversation(state),
  })),
  withProps(({ notRepliedConversations }) => ({
    studentWithNoReply: notRepliedConversations.map(
      (conversation) => conversation?.initiator || {},
    ),
  })),
  withHandlers({
    goToFirstNotRepliedConversation:
      ({ dispatch, notRepliedConversations }) =>
      () => {
        dispatch(
          push(
            generatePath(Routes.conversation, {
              id: notRepliedConversations[0].id,
            }),
          ),
        );
        dispatch(toggleModal('unpublished', false));
      },
  }),
)(inCrisisWithIcedUpModalContent);
