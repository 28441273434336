export const HIDE_HOW_IT_WORK_BANNER =
  '@@currentUserData/HIDE_HOW_IT_WORK_BANNER';

/**
 * On edit profile view:
 * When user choose to hide how it work banner
 * save this information on local storage
 * to reset value on refresh
 */
export const hideHowItWorkBanner = () => ({
  type: HIDE_HOW_IT_WORK_BANNER,
});
