import {
  getReadRequestActionType,
  getReadSuccessActionType,
} from '../utils/actions';
import ApiResource from '../api-resources';

const conversationView = (
  state = { isLoading: false, isLoaded: false },
  { type },
) =>
  ({
    '@@router/LOCATION_CHANGE': {
      ...state,
      isLoaded: false,
    },
    [getReadRequestActionType(ApiResource.MESSAGING_CONVERSATION)]: {
      ...state,
      isLoading: true,
    },
    [getReadSuccessActionType(ApiResource.MESSAGING_CONVERSATION)]: {
      ...state,
      isLoading: false,
      isLoaded: true,
    },
  })[type] || state;

export default conversationView;
