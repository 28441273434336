import {
  CREATE_LINKEDIN_AUTHENTICATION_IDENTITY,
  CREATE_IDENTITY_AUTHENTICATION_CREDENTIALS,
  CREATE_VANILLA_AUTHENTICATION_CREDENTIALS,
  CREATE_RESOURCE_AUTHENTICATION_ERROR,
} from '../actions/auth';

const defaultState = {
  step: 0, // Indicate current signup step (from 0 to n)
  pending: false, // Indicate background HTTP requests / wait (user cannot interact)
  pendingInfo: null, // Primarily for debug information
  error: false, // indicate state of last signup operation
  parentTagId: null, // Indicate current parentTagId selected for tag step
  ambassadorsQuantity: 1, // Inidcate current package quantity (Stripe company registration)
  level: 0, // ??
};

const signup = (state = defaultState, action) => {
  switch (action.type) {
    // Creation of user or company or linkedin
    // (and autosignin)
    case CREATE_VANILLA_AUTHENTICATION_CREDENTIALS:
    case CREATE_LINKEDIN_AUTHENTICATION_IDENTITY:
    case CREATE_IDENTITY_AUTHENTICATION_CREDENTIALS:
    case '@@api/CREATE_RESOURCE_USER':
    case '@@api/CREATE_RESOURCE_COMPANY':
    case '@@api/CREATE_RESOURCE_CREDENTIALS': {
      return {
        ...state,
        pending: true,
        error: null,
      };
    }

    case CREATE_RESOURCE_AUTHENTICATION_ERROR:
    case '@@api/CREATE_RESOURCE_IDENTITY_ERROR':
    case '@@api/CREATE_RESOURCE_USER_ERROR':
    case '@@api/CREATE_RESOURCE_COMPANY_ERROR': {
      return {
        ...state,
        step: 1,
        pending: false,
        error: true,
      };
    }
    case '@@api/CREATE_RESOURCE_USER_SUCCESS':
    case '@@api/CREATE_RESOURCE_COMPANY_SUCCESS': {
      return {
        ...state,
        pending: false,
        error: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default signup;
