import { push } from 'connected-react-router';
import { defineMessages } from 'react-intl';
import { take, put, select, race, takeEvery } from 'redux-saga/effects';
import { SIGNOUT, userSignout } from '../actions/signout';
import { displayToastr } from '../actions/toastr';
import { currentRouteSelector } from '../selectors';
import Routes from '../routes';
import ApiResource from '../api-resources';
import { t } from '../utils/translate';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../utils/actions';

const translations = defineMessages({
  errorSignout: {
    id: 'Signout.error',
    defaultMessage: 'La déconnexion a échoué, réessayez.',
    description: "la déconnexion backend n'a pu être effectué",
  },
});

function* signout({ redirectUrl }) {
  yield put(userSignout());

  const { error } = yield race({
    success: take(createSuccessActionType('CREATE', ApiResource.SIGNOUT)),
    error: take(createErrorActionType('CREATE', ApiResource.SIGNOUT)),
  });

  if (error) {
    yield put(displayToastr('error', t(translations.errorSignout)));
    return;
  }

  if (window.localStorage) {
    const currentRoute = yield select(currentRouteSelector);
    let newRoute = currentRoute;

    if (redirectUrl) {
      newRoute = redirectUrl;
    } else if (
      currentRoute === Routes.conversations ||
      currentRoute === Routes.conversation ||
      currentRoute === Routes.appointments ||
      currentRoute === Routes.profile ||
      currentRoute === Routes.dashboard
    ) {
      newRoute = Routes.home;
    }

    yield put(push(newRoute));

    window.localStorage.removeItem('credentials');
    window.localStorage.removeItem('user');
    window.location.reload(true);
  }
}

export function* signoutSaga() {
  yield takeEvery([SIGNOUT], signout);
}
