import { createSelector } from 'reselect';
import { userSelector } from '.';

/**
 * Check on the user if is necessary to update crop informations
 * @param {Object} state - redux store
 */
export const isNecessaryToCropAvatarSelector = createSelector(
  userSelector,
  (user) => user?.mustResizeAvatar || false,
);
