import { put } from 'redux-saga/effects';
import { updateUser } from '../../../actions/user';
import { UpdateUserAttributesAction } from '../actions/user';

export function* handleUserUpdates({
  userId,
  firstName,
  lastName,
  resolve,
  reject,
}: UpdateUserAttributesAction) {
  yield put(
    updateUser(
      userId,
      {
        first_name: firstName,
        last_name: lastName,
      },
      resolve,
      reject,
    ),
  );
}
