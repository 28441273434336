export interface Avatar {
  cardBackground: {
    url: string;
  };
  cropped: {
    url: string;
  };
  lightCardBackground: {
    url: string;
  };
  original: {
    url: string;
  };
  small: {
    url: string;
  };
  smaller: {
    url: string;
  };
  thumb: {
    url: string;
  };
  url: string;
}

export interface JsonApiRelationshipLink {
  id: string;
  type: string;
}
export interface JsonApiPostRelationshipLink {
  id?: string;
  'tmp-id'?: string;
  type: string;
  method?: 'create' | 'update' | 'destroy';
}
export interface JsonApiRelationshipOneToOne {
  data: JsonApiRelationshipLink;
}
export interface JsonApiRelationshipOneToMany {
  data: JsonApiRelationshipLink[];
}
export interface JsonApiPostRelationshipOneToOne {
  data: JsonApiPostRelationshipLink;
}
export interface JsonApiPostRelationshipOneToMany {
  data: JsonApiPostRelationshipLink[];
}

export interface JsonApiResource {
  id: string;
  type: string;
  attributes: {
    [key: string]: any;
  };
  relationships: {
    [key: string]:
      | JsonApiRelationshipOneToMany
      | JsonApiRelationshipOneToOne
      | undefined;
  };
}

export enum ProfileType {
  'companyAdmin' = 'companyAdmin',
  'mentor' = 'mentor',
  'student' = 'student',
  'employee' = 'employee',
  'vip' = 'vip',
  'moderator' = 'moderator',
  'MJGAdmin' = 'MJGAdmin',
  'schoolCoach' = 'schoolCoach',
}

export enum ProfileFieldsetCompletionSection {
  base = 'base',
  generalInformation = 'generalInformation',
  presentation = 'presentation',
  jobPrerequisites = 'jobPrerequisites',
  educations = 'educations',
  promotions = 'promotions',
  internships = 'internships',
}

export enum ProfileFieldsCompletion {
  avatar = 'avatar',
  description = 'description',
  education = 'education',
  firstName = 'firstName',
  languages = 'languages',
  lastName = 'lastName',
  mandatorySkills = 'mandatorySkills',
  meetingPlace = 'meetingPlace',
  phone = 'phone',
  position = 'position',
  promotions = 'promotions',
  recommendedQualifications = 'recommendedQualifications',
  yearsOfExperience = 'yearsOfExperience',
}

export enum Diploma {
  bepCap = 'bep_cap',
  specificFormation = 'specific_formation',
  bac = 'bac',
  bac_2 = 'bac_2',
  bac_3 = 'bac_3',
  bac_4 = 'bac_4',
  bac_5 = 'bac_5',
  bacSuperior_5 = 'bac_superior_5',
}

// User types (from JSON api)
export interface JsonApiBaseUserAttributes {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  softDeletedAt: string | null;
  isAnonymized: boolean;
  mustConfirmEmail: boolean;
  intercomHash: string | null;
  emailLocale: string | null;
}
export interface JsonApiBaseUser extends JsonApiResource {
  id: string;
  attributes: JsonApiBaseUserAttributes;
}
export interface JsonApiMappedUser extends JsonApiBaseUserAttributes {
  id: string;
  type: keyof typeof ProfileType;
  /** @deprecated */
  profile?: JsonApiMappedProfile | undefined;
  profiles: JsonApiMappedProfile[];
  avatar?: Avatar;
  identities: [];
}
export interface JsonApiCurrentUser extends JsonApiMappedUser {}

// Profile types (from JSON api)
export interface JsonApiCommunicationProfileAttributes {
  position?: string;
  description?: string;
  google_place_id?: string;
  meeting_place?: string;
  coordinates?: Number[];
  phone_country_code?: string;
  phone_without_country_code?: string;
  recommended_qualifications_mutually_exclusive_choices?: [
    'no_study_level_required',
  ];
  recommended_qualifications_cumulable_choices?: Diploma[];
}
export interface JsonApiBaseProfileAttributes {
  phoneCountryCode?: Number;
  phoneWithoutCountryCode?: Number;
  recommendedQualificationsCumulableChoices?: Diploma[];
  recommendedQualificationsMutuallyExclusiveChoices?: [
    'no_study_level_required',
  ];
  position?: string;
  description?: string;
  memberPresentation?: string;
  ambassadorPresentation?: string;
}
export interface JsonApiBaseProfile extends JsonApiResource {
  id: string;
  type: ProfileType;
  isVIP: Boolean;
  attributes: JsonApiBaseProfileAttributes;
  relationships: {
    user: JsonApiRelationshipOneToOne;
    profileDraft?: JsonApiRelationshipOneToOne;
    educations: JsonApiRelationshipOneToMany;
    promotions?: JsonApiRelationshipOneToMany;
    internships?: JsonApiRelationshipOneToMany;
  };
}
export interface JsonApiStudent extends JsonApiBaseProfile {
  type: ProfileType.student;
}
export interface JsonApiMentor extends JsonApiBaseProfile {
  type: ProfileType.mentor;
}
export interface JsonApiEmployee extends JsonApiBaseProfile {
  type: ProfileType.employee;
}
export interface JsonApiMjgAdmin extends JsonApiBaseProfile {
  type: ProfileType.MJGAdmin;
}
export interface JsonApiCompanyAdmin extends JsonApiBaseProfile {
  type: ProfileType.companyAdmin;
}
export interface JsonApiModerator extends JsonApiBaseProfile {
  type: ProfileType.moderator;
}
export interface JsonApiSchoolCoach extends JsonApiBaseProfile {
  type: ProfileType.schoolCoach;
}
export interface JsonApiVIP extends JsonApiBaseProfile {
  type: ProfileType.vip;
}

export type JsonApiProfile =
  | JsonApiStudent
  | JsonApiMentor
  | JsonApiEmployee
  | JsonApiMjgAdmin
  | JsonApiCompanyAdmin
  | JsonApiVIP
  | JsonApiModerator;

export interface JsonApiMappedProfile extends JsonApiBaseProfileAttributes {
  id: string;
  type: ProfileType;
  affiliatedToSchoolAssignment?: boolean; // only students
  vipId?: string;
  phone?: string;
  organizationName?: string;
  activeSchoolName?: string;
  organizationLogo?: { small: { url: string } };
  internships?: JsonApiMappedInternship[];
  educations?: JsonApiProfileContentEducationMapped[];
  canContactProfessionals?: boolean;
  companyId?: string;
  companyName?: string;
  confirmedAppointmentCount?: number;
  profileCompletion?: {
    fields?: {
      [key in ProfileFieldsCompletion]?: {
        complete?: boolean;
      };
    };
    fieldsets?: {
      [key in ProfileFieldsetCompletionSection]?: {
        newField: boolean;
        newSection: boolean;
        currentPoints?: number;
        completionPercentage?: number;
        complete: boolean;
      };
    };
  };
  user?: JsonApiMappedUser;
}

// Profile Draft types (from JSON api)
export interface JsonApiBaseDraft extends JsonApiResource {
  id: string;
  attributes: {
    commitStatusCd?: string;
    position?: string;
    description?: string;
  };
}
export interface JsonApiMentorDraft extends JsonApiBaseDraft {}
export interface JsonApiEmployeeDraft extends JsonApiBaseDraft {}
export type JsonApiProfileDraft = JsonApiMentorDraft | JsonApiEmployeeDraft;

// Profile educations
export interface JsonApiProfileContentEducationAttributes {
  id?: string;
  diplomaTitle: string;
  diplomaType?: Diploma;
  startedAt?: string;
  endedAt: string;
  schoolName: string;
  createdAt: string;
  updatedAt: string;
}
export interface JsonApiProfileContentEducation extends JsonApiResource {
  id: string;
  attributes: JsonApiProfileContentEducationAttributes;
}
export interface JsonApiProfileContentEducationMapped
  extends JsonApiProfileContentEducationAttributes {
  id: string;
}

// Profile promotions
export interface JsonApiProfileContentPromotionsAttributes {
  createdAt: string;
  updatedAt: string;
  image?: boolean;
  multimedia?: {
    url?: string;
    original?: {
      url?: string;
    };
    smaller?: {
      url?: string;
    };
  };
}
export interface JsonApiProfileContentPromotions extends JsonApiResource {
  id: string;
  attributes: JsonApiProfileContentPromotionsAttributes;
}
export interface JsonApiProfileContentPromotionsMapped
  extends JsonApiProfileContentPromotionsAttributes {
  id: string;
}

// Profile internships
export interface JsonApiProfileInternshipsAttributes {
  status: string;
  type: string;
  duration: string;
  startsAtMonth: string;
  startsAtYear: string;
  description: string;
}
export interface JsonApiProfileInternships extends JsonApiResource {
  id: string;
  attributes: JsonApiProfileInternshipsAttributes;
}
export interface JsonApiMappedInternship
  extends JsonApiProfileInternshipsAttributes {
  id: string;
}

// signup token
export interface CheckTokenJsonApiPayload {
  attributes: {
    email: string;
    firstName: string;
    lastName: string;
    signUpToken?: string;
    invitationToken?: string;
  };
}

export enum CheckTokenJsonApiErrors {
  sign_up_token_not_found = 'sign_up_token_not_found',
  used_sign_up_token = 'used_sign_up_token',
  conflict = 'conflict',
}

// credentials
export interface JsonApiCredentials {
  attributes?: {
    email?: string;
    authenticationToken?: string;
  };
}

// Conversations
export interface JsonApiConversationAttributes {}

export interface JsonApiConversation extends JsonApiResource {
  id: string;
  attributes: JsonApiConversationAttributes;
}

export interface JsonApiMappedConversation
  extends JsonApiConversationAttributes {
  id: string;
  acknowledged: boolean;
  initiator: JsonApiMappedUser;
  recipient: JsonApiMappedUser;
  appointment?: JsonApiMappedAppointment;
  pastAppointment?: JsonApiMappedAppointment;
  appointmentClaims: JsonApiMappedAppointmentClaim[];
}

// Appointments
export enum AppointmentTypesEnum {
  Phone = 'PHONE',
  Physical = 'PHYSICAL',
  Video = 'VIDEO'
}

export enum AppointmentsStatus {
  took_place = 'took_place',
  did_not_show_up = 'did_not_show_up',
  forgot_to_go = 'forgot_to_go',
  cancelled = 'cancelled',
  rescheduled = 'rescheduled',
  was_not_scheduled = 'was_not_scheduled',
  unbooked = 'unbooked',
  other = 'other',
}
export interface JsonApiAppointmentAttributes {
  from: string;
  to: string;
  reviewFromStudent?: {
    reviewedAt: string | null;
    liked: boolean | null;
    messageForProfessional: string | null;
    canReview: boolean;
    aspiration: object | null;
  };
  reviewFromProfessional?: {
    reviewedAt: string | null;
    messageForStudent: string | null;
    feedback: string | null;
    canReview: boolean;
  };
  statuses: {
    initiator: AppointmentsStatus;
    recipient: AppointmentsStatus;
  };
  status: AppointmentsStatus;
}
export interface JsonApiAppointment extends JsonApiResource {
  id: string;
  attributes: JsonApiAppointmentAttributes;
}
export interface JsonApiMappedAppointment extends JsonApiAppointmentAttributes {
  id: string;
  type: AppointmentTypesEnum;
  meetingPlace?: string;
  initiator: JsonApiMappedUser;
  recipient: JsonApiMappedUser;
  conversation?: JsonApiMappedConversation;
}

export interface JsonApiAppointmentClaimAttributes {
  approved: boolean | undefined | null;
}
export interface JsonApiAppointmentClaim {
  id: string;
  attributes: JsonApiAppointmentClaimAttributes;
}
export interface JsonApiMappedAppointmentClaim
  extends JsonApiAppointmentClaimAttributes {
  id: string;
  accepted: boolean;
}

// Message Template
export interface JsonApiMessageTemplateAttributes {
  title: string;
  body: string;
}
export interface JsonApiMessageTemplate extends JsonApiResource {
  id: string;
  attributes: JsonApiMessageTemplateAttributes;
}
export interface JsonApiMessageTemplateMapped
  extends JsonApiMessageTemplateAttributes {
  id: string;
}

// Acount
export enum SoftDeletionErrors {
  company_admin_cannot_self_soft_delete = 'company_admin_cannot_self_soft_delete',
  company_employee_cannot_self_soft_delete = 'company_employee_cannot_self_soft_delete',
  appointments_planned_in_the_future = 'appointments_planned_in_the_future',
  fdr_in_progress = 'fdr_in_progress',
}
