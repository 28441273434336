import memoize from 'lodash.memoize';
import qs from 'qs';
import { createSelector } from 'reselect';
import { searchParamsToInitialValues } from '../utils/search';
import { flattenRequestParams } from '../utils/json-api';

export const urlSearchFromState = ({ router }) => router?.location?.search;

export const urlSearchSelector = createSelector(
  urlSearchFromState,
  (search) => {
    return search ? qs.parse(search.substr(1)) : {};
  },
);

/**
 * @param {String} facetName
 * @returns {Function}
 */
export const facetValueFromUrlSearch = memoize((facetName) =>
  createSelector(urlSearchFromState, (search) => {
    if (!search) {
      return [];
    }
    const flattens = qs.parse(search.substr(1));
    if (!flattens) {
      return [];
    }
    return (
      searchParamsToInitialValues(flattenRequestParams(flattens))?.[
        facetName
      ] || []
    );
  }),
);

/**
 * @param {Function} facetValueSelector
 * @returns {Function}
 */
export const facetLabelFromUrlSearch = memoize(
  (facetName, facetValueSelector) =>
    createSelector(
      facetValueFromUrlSearch(facetName),
      facetValueSelector,
      (value, options) => {
        if (!Array.isArray(options)) {
          return [];
        }
        if (Array.isArray(value)) {
          return options
            .filter((option) => value.indexOf(option.value) > -1)
            .map(({ label }) => label);
        }
        const found = options.find((option) => option.value === value);
        return [found?.label];
      },
    ),
);

/**
 * Retrieve the state for the current route
 * @param {Object} state - redux store
 */
export const historyStateSelector = ({ router }) =>
  router?.location?.state || {};

/**
 * Retrieve current pathname
 * @param {Object} state - Redux store
 */
export const currentRouterPathnameSelector = ({ router }) =>
  router?.location?.pathname || '';
