import { UsernavItemExternalLinkProps } from 'scenes/layout/header/@types/Usernav';
import React, { useContext } from 'react';
import UsernavContext from '../../../../context/UsernavContext';
import { useSelector } from '../../../../../../../utils/hooks/useRedux';

function UsernavItemExternalLink({
  to,
  label,
  className,
  isNotificationSelector = () => false,
  icon: Icon,
}: UsernavItemExternalLinkProps) {
  const { closeUsernavMenu } = useContext(UsernavContext);
  const hasNotifications = useSelector(isNotificationSelector);
  function onClick() {
    closeUsernavMenu();
  }

  return (
    <a
      href={to}
      className={className}
      onClick={onClick}
    >
      {Icon && <Icon className="icon" />}
      {label}
      {!!hasNotifications && <span className="notification-bubble" />}
    </a>
  );
}

export default UsernavItemExternalLink;
