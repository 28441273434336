import ApiResource from '../api-resources';
import { createResource } from './api';

export const SIGNOUT = '@@signout/REQUEST';

export const signout = (redirectUrl) => ({
  type: SIGNOUT,
  redirectUrl,
});

export const userSignout = () =>
  createResource({
    resourceUri: '/authenticated/signout',
    data: {
      type: ApiResource.SIGNOUT,
    },
  });
