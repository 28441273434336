import { put, take } from 'redux-saga/effects';
import { readHeartbeatResource } from '../actions/hermes';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../utils/actions';
import ApiResource from '../api-resources';

export function* watchHermesVersion() {
  yield put(readHeartbeatResource());
  const heartbeat = yield take([
    createSuccessActionType('READ', ApiResource.MONITORING_HEARTBEAT),
    createErrorActionType('READ', ApiResource.MONITORING_HEARTBEAT),
  ]);
  /* eslint-disable no-console */
  const hermesBranch = Object.values(
    heartbeat?.data?.monitoringHeartbeats || {},
  )?.[0]?.attributes?.branch;
  console.group();
  console.log('%c******** Deployed branches ********', 'color:red;');
  // eslint-disable-next-line
  console.log(
    `%cAphrodite: %c${window.__RUNTIME_CONFIG__.REACT_APP_DEPLOYED_BRANCH}`,
    'font-weight:bold;',
    'font-weight:normal;font-style:italic;',
  );
  console.log(
    `%cHermes: %c${hermesBranch}`,
    'font-weight:bold;',
    'font-weight:normal;font-style:italic;',
  );
  console.log('%c***********************************', 'color:red;');
  console.groupEnd();
  /* eslint-enable no-console */
}
