import { useSelector } from 'react-redux';
import { ProfileType, JsonApiMappedUser } from '../../@types/jsonapi.d';
import { containerLoadStateSelector } from '../../selectors/container-load-state';
import { getUserById } from '../../selectors/typed/user';

interface UsePersonnaPayload {
  type: keyof typeof ProfileType | 'visitor' | undefined;
  boostraping: boolean;
  user: JsonApiMappedUser | undefined;
}
export default function usePersonna(): UsePersonnaPayload {
  const currentUser: JsonApiMappedUser | undefined = useSelector(getUserById());
  const { loaded } = useSelector(
    containerLoadStateSelector('bootstrap-current-user'),
  );

  return {
    type: currentUser?.type,
    boostraping: !loaded,
    user: currentUser,
  };
}
