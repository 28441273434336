import React from 'react';
import { PropsWithChildren, useEffect } from 'react';
import './Modal.scss';
import useModal from './Modal.hook';
import XCloseIcon from 'icons/component-icons/XCloseIcon';

type ModalProps = PropsWithChildren<{
  title?: string;
}>;

export default function Modal({ title, children }: ModalProps) {
  const [, closeModal] = useModal();

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div
      className="appfrontend-modal"
    >
      <button
        className="appfrontend-modal__overlay"
        onClick={closeModal}
      />

      <div className="appfrontend-modal__container">
        <header className="appfrontend-modal__container__header">
          {title && <h2>{title}</h2>}
          <button
            className="appfrontend-modal__container__header__close-button"
            onClick={closeModal}
          >
            <XCloseIcon />
          </button>
        </header>
        <div className="appfrontend-modal__container__content">{children}</div>
      </div>
    </div>
  );
}
