import memoize from 'lodash.memoize';
import { createSelector } from 'reselect';
import {
  JsonApiMessageTemplate,
  JsonApiMessageTemplateMapped,
} from '../../../../@types/jsonapi.d';
import { flattenAttributes } from '../../../../utils/json-api';

export function messageTemplatesFromJsonApi(
  store: ReduxStore,
): JsonApiMessageTemplate[] {
  return store?.api?.messageTemplates || [];
}

export const messageTemplatesSelector = createSelector(
  messageTemplatesFromJsonApi,
  (messageTemplates) => {
    if (!Array.isArray(messageTemplates)) {
      return [];
    }
    return messageTemplates.map((template) =>
      flattenAttributes<JsonApiMessageTemplate, JsonApiMessageTemplateMapped>(
        template,
      ),
    );
  },
);

export const messageTemplateSelector = memoize((templateId?: string) =>
  createSelector(
    messageTemplatesSelector,
    (messageTemplates): JsonApiMessageTemplateMapped | undefined => {
      if (!templateId) {
        return undefined;
      }
      return messageTemplates.find(({ id }) => id === templateId);
    },
  ),
);
