import memoize from 'lodash.memoize';
import { createSelector } from 'reselect';

/**
 * Retrieve all current user data
 * @param {Object} state - redux store
 */
export const currentUserDataFromState = ({ currentUserData }) =>
  currentUserData;

/**
 * Retrieve a data from current user data
 * @param {String} propertyName
 */
export const getCurrentUserData = memoize((propertyName) =>
  createSelector(
    currentUserDataFromState,
    (userData) => userData?.[propertyName],
  ),
);
