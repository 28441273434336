import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { withProps, compose, withHandlers } from 'recompose';

const UnpublishedByRHModalContent = ({ contactRH, unpublisher }) => (
  <div>
    <FormattedHTMLMessage
      id="UnpublishedByRHModalContent.description"
      defaultMessage={
        'Votre profil My Job Glasses a été dépublié par votre administrateur RH. Vous pouvez contacter {firstName} {lastName} ({email}) pour republier votre profil sur la plateforme.'
      }
      description="Message de description sur la depublication RH"
      values={unpublisher}
    >
      {(txt) => (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: txt }}
        />
      )}
    </FormattedHTMLMessage>

    <div className="actions">
      <Button
        bsStyle="primary"
        onClick={contactRH}
      >
        <FormattedMessage
          id="UnpublishedByRHModalContent.contact"
          defaultMessage="Contacter {firstName} {lastName}"
          description="button pour aller à la première conversation non répondu"
          values={unpublisher}
        />
      </Button>
    </div>
  </div>
);

UnpublishedByRHModalContent.propTypes = {
  unpublisher: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  contactRH: PropTypes.func.isRequired,
};

export default compose(
  withProps(({ user }) => ({
    unpublisher: user?.profile?.unpublisherUser || {
      // If unpublished not founded, we replace it by Martine From MJG
      // This case should never happen
      firstName: 'Martine',
      lastName: '',
      email: 'contact@myjobglasses.com',
    },
  })),
  withHandlers({
    contactRH:
      ({ email }) =>
      () => {
        window.location.href = `mailto:${email}`;
      },
  }),
)(UnpublishedByRHModalContent);
