import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import { defineMessages } from 'react-intl';

import { put, take, race, call, select } from 'redux-saga/effects';
import ApiResource from '../api-resources';
import Routes from '../routes';

import { createResource } from '../actions/api';

import {
  getCreateSuccessActionType,
  getCreateErrorActionType,
} from '../utils/actions';
import { extractFirstJsonapiResource } from '../utils/json-api';
import { firstJsonapiError } from '../utils/error';
import { t } from '../utils/translate';
import { presentation_id, matching_id } from '../utils/url-parser';

import { rolledStudentSelector } from '../selectors/red-carpets';

import { redirectToAccount } from '../hoc/protect-route/protectRoute';

const translations = defineMessages({
  successfullyRolledToStudent: {
    id: 'toastr.redCarpetRoll.successfullyRolledToStudent',
    defaultMessage:
      "Votre invitation a bien été envoyée à {studentFirstName}, {schoolName}. Le Membre pourra initier la conversation avec vous s'il accepte votre invitation.",
    description: 'Tapis rouge déroulé avec succès',
  },
  existingConversation: {
    id: 'toastr.redCarpetRoll.existingConversation',
    defaultMessage: 'Vous êtes déjà en contact avec cette personne !',
    description:
      'Tapis rouge non déroulé car déjà en conversation avec Le Membre',
  },
  alreadyRolled: {
    id: 'toastr.redCarpetRoll.alreadyRolled',
    defaultMessage: 'Vous avez déjà déroulé un tapis rouge à ce Membre !',
    description: 'Tapis rouge non déroulé car déjà déroulé',
  },
  notFound: {
    id: 'toastr.redCarpetRoll.notFound',
    defaultMessage: "Ce tapis rouge n'a pas été trouvé",
    description: 'Tapis rouge pas trouvé dans la DB',
  },
  accessDenied: {
    id: 'toastr.redCarpetRoll.accessDenied',
    defaultMessage:
      'Ce tapis rouge ne vous est pas adressé, vous ne pouvez pas le dérouler.',
    description: 'Tapis rouge non déroulé mauvais utilisateur',
  },
  unknown: {
    id: 'toastr.redCarpetRoll.unknown',
    defaultMessage:
      "Nous n'avons pas réussi à dérouler ce tapis rouge, merci de nous contacter",
    description: 'Tapis rouge non déroulé raison inconnue',
  },
});

export function* hydrateRedCarpetsView() {
  if (!matching_id || !presentation_id) {
    return;
  }

  const result = yield call(rollRedCarpetToStudent);

  if (result === 'authentication_required') {
    redirectToAccount();
    return;
  }

  yield put(push(Routes.conversations));
}

/*
 * Call the Hermes API to roll a carpet to the student
 * And shows a toastr as feedback
 *
 * @return { void }
 */
function* rollRedCarpetToStudent() {
  yield put(
    createResource({
      resourceUri: '/employee/red_carpet_rolls',
      data: {
        type: ApiResource.RECOMMENDATION_RED_CARPET_ROLL,
        attributes: {
          presentation_id,
          matching_id,
        },
      },
    }),
  );

  const { success, error } = yield race({
    success: take(
      getCreateSuccessActionType(ApiResource.RECOMMENDATION_RED_CARPET_ROLL),
    ),
    error: take(
      getCreateErrorActionType(ApiResource.RECOMMENDATION_RED_CARPET_ROLL),
    ),
  });

  if (success) {
    const roll = extractFirstJsonapiResource(
      success,
      'recommendationRedCarpetRoll',
    );
    const { schoolName, studentFirstName } = yield select(
      rolledStudentSelector(roll.id),
    );
    toastr.success(
      t(translations.successfullyRolledToStudent, {
        schoolName,
        studentFirstName,
      }),
      { timeOut: 0 },
    );
  } else if (error) {
    const jsonapiError = firstJsonapiError(error);
    const errorName = (jsonapiError && jsonapiError.name) || 'unknown';

    const errorReasons = {
      existing_conversation: translations.existingConversation,
      already_rolled: translations.alreadyRolled,
      not_found: translations.notFound,
      access_denied: translations.accessDenied,
      unknown: translations.unknown,
    };

    if (errorName === 'unknown') {
      window.Rollbar &&
        window.Rollbar.error(
          'Error while attempting to send a red carpet',
          error,
        );
    }

    if (errorName === 'authentication_required') {
      return 'authentication_required';
    }

    const errorMessage = errorReasons[errorName];
    toastr.error(t(errorMessage));
  }
}
