import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import api from './api';
import apiIndexes from './api-indexes';
import containerLoadState from './container-load-state';
import conversationView from './conversation-view';
import conversationsView from './conversations-view';
import currentUserData from './current-user-data';
import identity from './identity';
import modals from './modals';
import navigation from './navigation';
import profileCompletion from './profile-completion';
import profileView from './profile-view';
import resourcesStates from './resources-states';
import search from './search';
import shared from './shared';
import signup from './signup';
import storage from './storage';

export default (history) =>
  combineReducers({
    api,
    apiIndexes,
    router: connectRouter(history),
    form: formReducer,
    toastr: toastrReducer,
    shared,
    modals,
    signup,
    search,
    identity,
    profileView,
    conversationsView,
    conversationView,
    navigation,
    storage,
    profileCompletion,
    containerLoadState,
    resourcesStates,
    currentUserData,
  });
