import { matchPath } from 'react-router';
import { call, put, race, select, take } from 'redux-saga/effects';
import { setContainerLoadState } from '../../../../../../actions/container-load-state';
import { getEmployee } from '../../../../../../actions/profile';
import Routes from '../../../../../../routes';
import { currentRouteSelector } from '../../../../../../selectors';
import { profileSelector } from '../../../../../../selectors/profile';
import {
  createErrorActionType,
  createSuccessActionType,
} from '../../../../../../utils/actions';

/**
 * Retrieve a professional (in cache or request to api)
 */
export function* retrieveProfessional(professionalId: string) {
  const professional = yield select(profileSelector(professionalId));
  // wished professional does not load user relationship
  if (professional && professional?.user?.id) {
    return professional;
  }
  yield put(getEmployee({ id: professionalId }));
  const { success } = yield race({
    success: take(createSuccessActionType('READ', 'profile/employee')),
    error: take(createErrorActionType('READ', 'profile/employee')),
  });
  if (success) {
    return yield select(profileSelector(professionalId));
  }
  return false;
}

/**
 * Hydrate the professional view
 */
export function* hydrateEmployeeProfileView() {
  const currentRoute = yield select(currentRouteSelector);
  const match = matchPath<{ employeeid: string }>(currentRoute, {
    path: Routes.companyAdminShowAmbassador,
    exact: true,
  });
  const matchMjgAdmin = matchPath<{ employeeid: string }>(currentRoute, {
    path: Routes.mjgAdminCompaniesShowEmployee,
    exact: true,
  });
  yield put(setContainerLoadState('professional', { loading: true }));
  const employeeId =
    match?.params?.employeeid || matchMjgAdmin?.params?.employeeid;
  if (employeeId) {
    const professional = yield call(retrieveProfessional, employeeId);
    if (professional) {
      yield put(setContainerLoadState('professional', { success: true }));
    } else {
      yield put(setContainerLoadState('professional', { error: true }));
    }
  } else {
    yield put(setContainerLoadState('professional', { error: true }));
  }
}
