import React from 'react';

interface NoNetworkIllustrationProps {
  className?: string;
}
function NoNetworkIllustration({ className }: NoNetworkIllustrationProps) {
  return (
    <svg
      viewBox="0 0 409 266"
      className={className}
    >
      <g id="student">
        <circle
          fill="#7760DB"
          cx="289.8676758"
          cy="234.5301819"
          r="3.2125316"
        />
        <polygon
          fill="#e0ca4b"
          points="293.0849609,168.5146484 293.0859985,153.8052063 282.70047,154.063858 286.6590576,234.6903229
    293.0802002,234.5304108 293.0849609,168.5146484 "
        />
        <path
          fill="#0E0E11"
          d="M299.2825317,97.2357483h0.0000305c3.7420654,0.7740631,7.4031067-1.6319885,8.1771851-5.3740692
    l0.066864-0.3232193l-6.7756653-1.4015732L299.2825317,97.2357483z"
        />

        <rect
          x="291.7749023"
          y="92.288002"
          transform="matrix(-0.979269 -0.2025642 0.2025642 -0.979269 566.6376953 258.2345886)"
          fill="#D8A966"
          width="9.5163469"
          height="15.6672211"
        />
        <path
          fill="#D8A966"
          d="M290.7440796,99.5381012l10.3655396,2.1441422l4.4991455-21.7504654l-15.2114563-3.1465302
    l-3.4967651,16.9045563C286.3469543,96.3661041,288.067749,98.9845123,290.7440796,99.5381012z"
        />
        <path
          fill="#0E0E11"
          d="M302.2407227,77.816864L302.2407227,77.816864l-2.7152405,13.1264801l7.7908936,1.6115341
    l1.1036377-5.3355103C309.3100586,82.9165726,306.5435181,78.7069473,302.2407227,77.816864z"
        />
        <path
          fill="#0E0E11"
          d="M296.1906128,80.3284607l3.7138062,0.7681961c1.4907532,0.3083649,3.0340881-0.5699539,3.3807678-2.0522232
    c0.355835-1.5214005-0.6133728-3.0278015-2.1339111-3.3422928l-3.7138062-0.7682419
    c-1.4907227-0.3083649-3.0340881,0.5699539-3.3807373,2.0522614
    C293.7008972,78.5075226,294.6700745,80.0139236,296.1906128,80.3284607z"
        />
        <path
          fill="#0E0E11"
          d="M297.3555298,80.5694122l3.7137756,0.7681961c1.4907532,0.3083725,3.0340576-0.5699539,3.3807678-2.0522232
    c0.355835-1.5213547-0.6133423-3.0277939-2.1339111-3.3422928l-3.7138062-0.7682343
    c-1.4907227-0.3083725-3.0340576,0.5699539-3.3807373,2.0522537
    C294.8657837,78.7484741,295.8349609,80.2548752,297.3555298,80.5694122z"
        />
        <path
          fill="#0E0E11"
          d="M291.9728394,79.4560013l3.7137756,0.7681961c1.4907532,0.3083649,3.0340881-0.5699539,3.3807678-2.0522232
    c0.355835-1.5214005-0.6133423-3.0278015-2.1339111-3.3423309l-3.7138062-0.7682037
    c-1.4907227-0.3083649-3.0340271,0.5699539-3.3807373,2.0522614
    C289.4830933,77.6350632,290.4522705,79.1414642,291.9728394,79.4560013z"
        />
        <path
          fill="#0E0E11"
          d="M300.1953735,92.2377548L300.1953735,92.2377548
    c0.5011902,0.1036987,0.9915771-0.2185822,1.0952148-0.71978l0.0469666-0.2268448l-1.815033-0.3754425l-0.046936,0.2268448
    C299.3719177,91.6437302,299.6941833,92.1340942,300.1953735,92.2377548z"
        />
        <path
          fill="#0E0E11"
          d="M288.9147644,83.5415955l4.4161682,0.9134979c1.7727051,0.366684,3.6079102-0.6777191,4.0201721-2.4403763
    c0.4231262-1.8090973-0.7293701-3.6003799-2.5374756-3.974411l-4.4161682-0.9134903
    c-1.7726746-0.366684-3.6079102,0.6777496-4.0201721,2.4403687
    C285.9541626,81.3762817,287.1066589,83.1676025,288.9147644,83.5415955z"
        />
        <path
          fill="#0E0E11"
          d="M290.7688904,83.925148l3.7138367,0.7681961c1.4907227,0.3083649,3.0340881-0.5699539,3.3807373-2.0522232
    c0.355835-1.5214005-0.6133423-3.0278015-2.1338806-3.3422928l-3.7138062-0.7682419
    c-1.4907532-0.3083649-3.0340881,0.5699539-3.3807678,2.0522614
    C288.2791443,82.1042099,289.2483826,83.610611,290.7688904,83.925148z"
        />
        <path
          fill="#0E0E11"
          d="M287.579071,81.0935059l2.0326843,0.4204636l-1.5073242,7.2869415l0,0
    c-1.1226196-0.2322311-1.8444519-1.3305359-1.6122131-2.4531631L287.579071,81.0935059z"
        />

        <rect
          x="292.1114502"
          y="79.8397675"
          transform="matrix(-0.9792695 -0.2025618 0.2025618 -0.9792695 570.7834473 223.3760376)"
          fill="#0E0E11"
          width="9.4211884"
          height="5.2815766"
        />

        <rect
          x="306.6238708"
          y="90.1116333"
          transform="matrix(0.2021212 -0.9793605 0.9793605 0.2021212 156.2313385 372.9487)"
          fill="#0E0E11"
          width="0.7614169"
          height="0.9584678"
        />
        <path
          fill="#D8A966"
          d="M228.8264465,119.7159576c-0.3137817,1.6073227,0.7348175,3.1646423,2.3421021,3.4784317
    s3.1646271-0.7348099,3.4783936-2.3420944c0.3137817-1.6072769-0.734787-3.1646347-2.3420563-3.4783936
    C230.6976013,117.060112,229.1402435,118.1086807,228.8264465,119.7159576z"
        />
        <path
          fill="#FF95A9"
          d="M234.655838,121.6941528c0.241684-1.237999,1.4411926-2.0456772,2.6791992-1.8039932
    c1.2380371,0.2417145,2.0456696,1.4412308,1.8040009,2.6792297c-0.241684,1.2380066-1.4412384,2.0456772-2.679245,1.8040009
    C235.2218018,124.1316757,234.4141235,122.9321518,234.655838,121.6941528z"
        />
        <polygon
          fill="#D8A966"
          points="233.0991821,117.6496277 237.7056732,120.0293808 236.2577057,124.1879501
    231.1685486,123.1943893 "
        />
        <path
          fill="#D8A966"
          d="M288.5613403,116.7297592l-8.3352661-9.220192l-15.0390015,13.9373398l-24.4661865-1.6036072
    l-2.2806549,0.0007248l-1.5438232,0.0012894l-0.4337158,4.5267792l29.3675079,5.733223
    c0.0123901,0.0026245,0.0238953,0.0066986,0.0362854,0.00914c1.214325,0.2369537,2.4115295,0.0280457,3.4253845-0.5100403
    l0.0011597,0.0016785L288.5613403,116.7297592z"
        />
        <polygon
          fill="#D8A966"
          points="236.8949585,119.8468399 236.4610901,124.3736191 238.4411774,124.7602081
    238.4387817,119.8456192 "
        />
        <circle
          fill="#7760DB"
          cx="304.7477112"
          cy="153.8048248"
          r="5.1959305"
        />
        <path
          fill="#e0ca4b"
          d="M310.2009888,148.6089325l-0.2549744,5.1958923l-0.0125427,0.2547455
    c-0.1358337,2.7672729-2.4190979,4.9411469-5.1896362,4.9411469h-16.842926c-2.7705994,0-5.0538635-2.1739502-5.1896667-4.9412231
    l-0.0125122-0.2546692l-0.2550049-5.1958923H310.2009888z"
        />
        <circle
          fill="#e0ca4b"
          cx="321.8926086"
          cy="234.5301819"
          r="3.2125363"
        />
        <circle
          fill="#e0ca4b"
          cx="296.1704407"
          cy="148.5455475"
          r="13.8786497"
        />
        <polygon
          fill="#e0ca4b"
          points="309.8546143,152.8419189 325.0501099,233.9348297 318.7670288,235.2757263
    299.6924438,155.0106659 "
        />
        <path
          fill="#09193b"
          d="M328.3412781,238.3367615h-9.6611938v-3.8065796h6.4290771l3.6896057,2.012207
    c0.3070068,0.1674194,0.4980164,0.4891815,0.4980164,0.8388519l0,0
    C329.2967834,237.9089813,328.8689575,238.3367615,328.3412781,238.3367615z"
        />
        <path
          fill="#FFFFFF"
          d="M289.227417,92.1723328c-0.2316895,1.1328659,0.4989014,2.239006,1.6317749,2.4706726
    s2.2390137-0.4989243,2.4706726-1.6317902L289.227417,92.1723328z"
        />
        <path
          fill="#09193b"
          d="M283.4190063,238.3367615h9.6611938v-3.8065796h-6.4291077l-3.6895447,2.012207
    c-0.3070068,0.1674194-0.4980469,0.4891815-0.4980469,0.8388519l0,0
    C282.463501,237.9089813,282.8912964,238.3367615,283.4190063,238.3367615z"
        />
        <path
          fill="#D8A966"
          d="M303.328064,91.9499512l-2.0326843-0.4204712l0.4204407-2.032692
    c0.2322388-1.1226273,1.3305664-1.8444214,2.453186-1.6122208l0,0c1.1226196,0.2322311,1.8444519,1.3305359,1.6122131,2.4531631
    l0,0C305.5490112,91.4603577,304.4506836,92.1821899,303.328064,91.9499512z"
        />
        <polygon
          fill="#09193b"
          points="289.0608521,116.3763351 275.1357727,126.2286072 268.2281494,118.2760849
    280.4036865,106.4154587 "
        />
        <path
          fill="#09193b"
          d="M279.6957397,118.3432617c3.1422424,2.7937317,7.9542847,2.5112152,10.7480164-0.631012
    s2.5112305-7.9542694-0.631012-10.7480011c-3.1422119-2.7937393-7.9542542-2.5112534-10.7479858,0.6309738
    C276.2710266,110.7374802,276.5534973,115.5495224,279.6957397,118.3432617z"
        />
        <path
          fill="#09193b"
          d="M312.0625916,110.5896378l-0.4719543,9.634285l-0.3806458,7.7616196l-1.0087585,20.540062h-27.7573242
    l-1.0087585-20.540062l-0.3806763-7.7616196l-0.4719849-9.634285c-0.1522522-3.04142,2.2763672-5.5918808,5.3215942-5.5918808
    h20.8332214C309.7825317,104.997757,312.2110596,107.5482178,312.0625916,110.5896378z"
        />
        <polygon
          fill="#e0ca4b"
          points="311.5906372,120.2239227 311.2099915,127.9855423 281.4351501,127.9855423
    281.0544739,120.2239227 "
        />
        <path
          fill="#D8A966"
          d="M302.7339478,104.9343109c0,3.7761688-3.0604858,6.8366241-6.8365479,6.8366241
    c-3.7761536,0-6.8365479-3.0604553-6.8365479-6.8366241H302.7339478z"
        />
        <ellipse
          fill="#D8A966"
          cx="294.8256836"
          cy="105.4799576"
          rx="4.979794"
          ry="1.8518738"
        />

        <ellipse
          transform="matrix(0.3776737 -0.9259387 0.9259387 0.3776737 100.8437347 328.509552)"
          fill="#0E0E11"
          cx="294.8111267"
          cy="89.2337494"
          rx="0.7324559"
          ry="0.5676039"
        />

        <ellipse
          transform="matrix(0.3776737 -0.9259387 0.9259387 0.3776737 99.8608398 324.2619019)"
          fill="#0E0E11"
          cx="291.1596985"
          cy="87.8411255"
          rx="0.7324559"
          ry="0.5676039"
        />

        <line
          fill="none"
          stroke="#0E0E11"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="292.2183838"
          y1="85.7673645"
          x2="290.5557556"
          y2="86.2031479"
        />

        <line
          fill="none"
          stroke="#0E0E11"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="297.0007019"
          y1="88.0431061"
          x2="294.9872131"
          y2="86.8806229"
        />
        <g>
          <g>
            <path
              fill="#e0ca4b"
              d="M233.7662048,148.8326721l-0.787262,1.0907288
        c-0.8047028,1.1149139-2.3753204,1.3687286-3.4902496,0.5640106l-31.9607086-23.0681305
        c-1.1149292-0.8047104-1.3687439-2.3753281-0.5640259-3.4902496l0.7872467-1.0907288
        c0.804718-1.1149216,2.3753357-1.3687286,3.4902496-0.5640182l31.9607239,23.0681381
        C234.3171082,146.1471252,234.5709076,147.7177429,233.7662048,148.8326721z"
            />
            <path
              fill="#e0ca4b"
              d="M248.6796722,129.5554352l-0.7872467,1.0907288
        c-0.804718,1.1149292-2.3753357,1.3687439-3.4902496,0.5640259l-31.9607239-23.0681305
        c-1.1149292-0.804718-1.3687286-2.3753281-0.5640259-3.4902573l0.7872467-1.0907211
        c0.804718-1.1149292,2.3753357-1.3687363,3.4902649-0.5640259l31.9607086,23.0681305
        C249.2305756,126.8699036,249.4843903,128.4405212,248.6796722,129.5554352z"
            />
            <path
              fill="#99DDF2"
              d="M281.4160156,167.6070404c-2.9086304,0.2647552-4.0248108,4.7261353-7.6413269,2.1158752
        c-0.0260315-0.0187836-20.4181213-14.7370911-20.4181213-14.7370911
        c-1.7967987-1.2968597-2.2058258-3.8280487-0.9089661-5.6248322l1.5719604-2.177948
        c1.296875-1.7967987,3.8280334-2.2058258,5.6248474-0.9089661l20.4181519,14.7371063
        c1.7967834,1.296875,2.2058105,3.8280487,0.9089661,5.6248322c0,0-0.0979919,0.1357727-0.2450562,0.3394928"
            />
            <path
              fill="#99DDF2"
              d="M250.3660889,124.6709747l18.0032043,12.9940796
        c1.3378906,0.9656525,1.6424561,2.8503876,0.6768188,4.1882935l-16.9239807,23.4480438
        c-0.9656525,1.3379059-2.8503876,1.6424866-4.1883087,0.6768188l-18.0031891-12.9940796
        c-1.3379211-0.9656525-1.6424866-2.8503876-0.6768341-4.1882935l16.9239807-23.4480362
        C247.1434326,124.0098877,249.0281677,123.7053146,250.3660889,124.6709747z"
            />
            <path
              fill="#99DDF2"
              d="M238.4444427,104.4725342l24.5370026,17.7099533
        c1.3379211,0.9656601,1.6424866,2.8503952,0.6768188,4.1883011l-27.9276581,38.6935883
        c-0.9656677,1.3379059-2.8504028,1.6424866-4.1883087,0.6768188L207.0052948,148.03125
        c-1.3379059-0.9656525-1.6424866-2.8503876-0.6768341-4.1883087l27.9276886-38.6935806
        C235.2218018,103.8114471,237.1065369,103.5068817,238.4444427,104.4725342z"
            />
          </g>
        </g>
        <path
          opacity="0.3"
          fill="#0E0E11"
          d="M313.0969238,116.2392502c1.8529663-2.4725342,1.769165-5.9872437-0.3723755-8.3751831
    c-2.4384155-2.7189941-6.6192627-2.9464111-9.3381958-0.5079956c-0.71875,0.6445312-1.2581177,1.4125977-1.6254272,2.2415161
    l-10.1286621,12.2734985l0.3405151,0.2796021l-5.7938843,7.4015579l-22.1517944,11.0393066l-2.041748,1.0162964
    l-1.1139526,0.5551147l-4.6798706-0.0717773l0.0018311,0.0145264c-0.2837524-0.0046997-0.5708618,0.0160522-0.8583374,0.0952148
    c-1.621521,0.4464722-2.5740356,2.1229248-2.1275635,3.7443848c0.4465332,1.621521,2.1229858,2.5739746,3.7444458,2.1275024
    l5.1341553-1.4137573c0.0004272-0.0001221,0.0007935-0.0003662,0.0012207-0.0004883l0.000061,0.0001221l1.9450073-0.5355835
    l-0.0334473-0.0671997l27.0799561-7.4569092c0.012207-0.0032959,0.0241699-0.0047607,0.036377-0.0080566
    c0.9553833-0.2631836,1.7699585-0.7805176,2.4016113-1.4539795l0.0046387,0.0037842l7.2678833-7.7506104l0.2680054,0.2200317
    l11.4976807-12.7438431c0.1029663-0.1065674,0.2009888-0.2158203,0.2954712-0.3275146l0.2609253-0.2891846L313.0969238,116.2392502
    z"
        />
        <g>
          <g>
            <ellipse
              transform="matrix(0.8656497 -0.5006502 0.5006502 0.8656497 -14.5486174 168.9524841)"
              fill="#09193b"
              cx="307.5224609"
              cy="111.5836105"
              rx="6.6128702"
              ry="6.6128454"
            />
            <g>
              <path
                fill="#D8A966"
                d="M261.9520569,145.8471527l-1.6273804-4.2463379l1.3817139-0.6885376l2.041748-1.0162506
          l22.1517944-11.0393524l16.3249207-20.8547668l10.607605,7.5521698l-19.5899658,20.8910828l-0.0046082-0.0037231
          c-0.6316528,0.6734161-1.446228,1.1907806-2.4016113,1.4539337c-0.012207,0.0032959-0.0241394,0.004776-0.0363464,0.0080566
          L261.9520569,145.8471527z"
              />

              <ellipse
                transform="matrix(0.9079111 -0.4191628 0.4191628 0.9079111 -36.9821358 120.5503006)"
                fill="#D8A966"
                cx="255.864502"
                cy="144.4412994"
                rx="3.0452454"
                ry="3.0452194"
              />
              <path
                fill="#D8A966"
                d="M258.9987488,144.3678894c-0.3348694-1.2161255,0.3795471-2.4734497,1.5956421-2.8083344
          c1.216095-0.3348694,2.4734192,0.3795319,2.8083191,1.5956268c0.3348694,1.216095-0.3795166,2.4734192-1.5956116,2.8083344
          C260.5909424,146.2983856,259.3336182,145.5839844,258.9987488,144.3678894z"
              />
              <polygon
                fill="#D8A966"
                points="255.9125519,141.3956604 261.2367859,141.4772949 261.8070984,145.9635162
          256.6729431,147.377243 "
              />
              <polygon
                fill="#D8A966"
                points="260.1810303,141.7167664 261.8083496,145.9631348 263.753418,145.4275513
          261.5628052,141.028183 "
              />
              <polygon
                fill="#09193b"
                points="312.8324585,115.5540771 300.7784424,128.9146118 291.353302,121.1755676
          302.2248535,108.0019073 "
              />
            </g>
          </g>
        </g>
        <path
          fill="none"
          stroke="#99DDF2"
          strokeWidth="2"
          strokeMiterlimit="10"
          d="M280.3633118,166.9011688
    c7.2803955,5.9927216,13.4229126,15.5569,10.2843018,24.4487915c-1.8385925,5.2088318-6.4462585,8.884552-11.0022583,12.0078735
    c-10.4797058,7.1842194-22.5525818,13.7737274-27.6647797,25.4056854
    c-2.6394653,6.0056305-2.6563416,13.9597626,2.3624115,18.1842804c2.0848694,1.7549438,4.754303,2.6330414,7.3778992,3.3700562
    c31.7176819,8.9099884,65.4916992,2.6607056,98.3984375,4.2563324c16.1899109,0.7850494,32.5388794,3.469696,48.7041626,4.2424927"
        />
      </g>
      <g id="Calque_3">
        <path
          fill="#E2AE44"
          d="M128.5743408,71.4623871l-1.9788589,0.0478134l0.7116394,29.4497375l5.681221-0.1372833
    c4.9225006-0.1189194,8.6621246-4.4685822,8.0415497-9.3532486l-1.3262329-10.4384689
    C138.9958496,75.4597244,134.1886597,71.3267136,128.5743408,71.4623871z"
        />
        <path
          fill="#e0ca4b"
          d="M159.1838074,116.380928c-0.4543915,2.0376968-2.4746399,3.3212357-4.5123444,2.8668365
    c-2.037735-0.4543762-3.321228-2.4746475-2.8668518-4.5123825c0.4543915-2.0376968,2.4746399-3.3212357,4.5123596-2.866806
    C158.3547058,112.3229523,159.6382141,114.3432312,159.1838074,116.380928z"
        />
        <path
          fill="#e0ca4b"
          d="M129.1859589,108.6435699c0.5840607-2.8130722,3.3379669-4.6200867,6.1511993-4.0361481
    c0.225296,0.0467758,0.4414978,0.1123047,0.6531219,0.1857376l0.0013733-0.0051117l20.7466278,7.2000732l-2.0125122,7.271431
    l-21.5037842-4.4646301C130.408905,114.2108612,128.6017609,111.4568024,129.1859589,108.6435699z"
        />
        <path
          fill="#09193b"
          d="M137.1062469,138.0769348v-0.0000458h-8.2294464h-10.3796082l-1.0028839,0.7793121
    c-3.3784561,2.6251221-5.354805,6.6636658-5.354805,10.9420776v4.0022278c0,3.094162,2.5083084,5.602478,5.602478,5.602478
    h16.1612778c3.0942535,0,5.6026001-2.5084381,5.602478-5.6026459l-0.0001526-5.4806213
    C139.5055084,144.7662201,138.6843414,141.260788,137.1062469,138.0769348z"
        />
        <circle
          fill="#FF9C35"
          cx="133.0943756"
          cy="109.0109787"
          r="5.2023048"
        />
        <path
          fill="#e0ca4b"
          d="M138.2881165,109.2514877l-1.3467102,29.1014023h-18.6090393l-7.6901321-30.5615692
    c-0.5087051-2.0217361,1.0198517-3.9826508,3.10466-3.9826508h19.3444901
    C136.0589294,103.80867,138.4253082,106.2870941,138.2881165,109.2514877z"
        />
        <path
          fill="#e0ca4b"
          d="M126.1888809,112.8737717l8.2930832-6.2879486l6.6501465,8.7675629l-0.004776,0.0035248
    c0.3698883,0.4874954,0.6637878,1.0449829,0.8474579,1.6667328c0.8138428,2.7555466-0.7601318,5.6489868-3.5156403,6.4628296
    c-2.1337433,0.6303558-4.3459167-0.1754379-5.6154022-1.8488617l-0.0047607,0.0037231L126.1888809,112.8737717z"
        />
        <path
          fill="#FFFFFF"
          d="M136.9875031,123.7004471c-1.0500641,0-2.0684662-0.318222-2.9446564-0.9202499l0.4533234-0.6596527
    c1.0922852,0.7509766,2.4671326,0.9728699,3.7364502,0.5983887c1.1274109-0.3330307,2.0579071-1.0852814,2.6195221-2.1182251
    c0.5623322-1.0328903,0.6881866-2.2227402,0.3552399-3.3503571c-0.1493073-0.504509-0.3908234-0.9788742-0.7175293-1.4098282
    l-0.4896545-0.6453629l0.0050507-0.0034409l-3.1869354-4.2012253l0.6377563-0.483696l3.6793976,4.8511124l-0.0043182,0.0034409
    c0.3833618,0.5070648,0.6674957,1.0661926,0.8437347,1.6620941c0.3939209,1.3328705,0.2446594,2.7390518-0.4197083,3.9597549
    c-0.6635742,1.2206192-1.7633057,2.1096954-3.0959015,2.503273
    C137.9785919,123.6284943,137.4834442,123.7004471,136.9875031,123.7004471z"
        />
        <path
          fill="#FFFFFF"
          d="M125.3565979,123.7004471c-1.0500717,0-2.0685196-0.318222-2.9442673-0.9202881l0.4533234-0.6595764
    c1.0922775,0.7509384,2.4674911,0.9728317,3.7360153,0.5983505c0.4830093-0.1426239,0.9316483-0.3622437,1.3345871-0.6528091
    l0.4681625,0.6490021c-0.4760056,0.3435516-1.0063171,0.6031113-1.5760956,0.7713852
    C126.3480377,123.6284103,125.8528976,123.7004471,125.3565979,123.7004471z"
        />
        <path
          fill="#09193b"
          d="M133.3376465,234.398407h-5.2022552V238h7.28862c0.6159973,0,1.1153717-0.4993744,1.1153717-1.1153717l0,0
    c0-0.3991852-0.2133331-0.7678986-0.5594025-0.9669037L133.3376465,234.398407z"
        />
        <polygon
          fill="#FDC9A3"
          points="128.1359406,181.5578766 128.1359406,234.3982849 133.3382568,234.3982849
    137.1879578,181.5578766 "
        />
        <path
          fill="#FDC9A3"
          d="M121.6894073,104.5084991c0.3918991,2.3993454,2.6546173,4.0266647,5.0539627,3.6347733
    s4.0266647-2.6546555,3.6347733-5.0540009c-0.3918915-2.3993378-2.6546631-4.0266647-5.0539627-3.6347656
    C122.9248352,99.8463974,121.2974777,102.1091614,121.6894073,104.5084991z"
        />

        <rect
          x="120.758049"
          y="93.0290375"
          transform="matrix(0.986921 -0.161205 0.161205 0.986921 -14.2335615 21.4640427)"
          fill="#FDC9A3"
          width="8.8038921"
          height="10.8407421"
        />
        <path
          fill="#FDC9A3"
          d="M125.4814072,74.3887634l-0.6758194,0.110405
    c-4.3131104,0.7044754-7.2384415,4.7720337-6.5339279,9.0851059l2.4029007,14.711235l8.1804657-1.3361893
    c4.1082611-0.6710587,6.8946533-4.5454178,6.2236023-8.6536331l-1.2982635-7.9484024
    C133.1368408,76.4174194,129.4212341,73.7452316,125.4814072,74.3887634z"
        />
        <path
          fill="#FDC9A3"
          d="M118.8804474,89.5948944l1.8161621-0.2966461l-0.5932999-3.6323318l-1.8161697,0.2966537
    c-1.0030441,0.163826-1.683342,1.1097717-1.5195084,2.1128159l0,0
    C116.9314651,89.0784302,117.8774033,89.758728,118.8804474,89.5948944z"
        />
        <path
          fill="#FFFFFF"
          d="M128.2512207,91.1450653c0.207016,1.1978455,1.345871,2.0010834,2.5437317,1.794075
    c1.1978455-0.207016,2.0010834-1.3458786,1.7940674-2.5437317L128.2512207,91.1450653z"
        />
        <polygon
          fill="#FDC9A3"
          points="112.5204315,154.8644867 104.087532,234.1411743 109.2388153,234.8464813 123.0969543,155.0349731
      "
        />
        <ellipse
          fill="#0E0E11"
          cx="127.8147049"
          cy="85.3666763"
          rx="0.4240891"
          ry="0.5472593"
        />
        <ellipse
          fill="#0E0E11"
          cx="132.6578522"
          cy="85.0345383"
          rx="0.4240891"
          ry="0.5472593"
        />

        <line
          fill="none"
          stroke="#0E0E11"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="131.3027802"
          y1="82.8989182"
          x2="133.0506744"
          y2="83.3570557"
        />

        <line
          fill="none"
          stroke="#0E0E11"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="125.6694107"
          y1="83.9564209"
          x2="128.0314178"
          y2="83.327919"
        />
        <path
          fill="#FDC9A3"
          d="M165.5819092,85.7216721c1.2884979,1.2107697,1.3515472,3.2368164,0.1408234,4.5252914
    c-1.2107697,1.2885056-3.2368164,1.3515472-4.5253296,0.1408234c-1.2885132-1.2107697-1.3515472-3.2368164-0.1407776-4.525322
    C162.2673492,84.5739899,164.2933807,84.5109482,165.5819092,85.7216721z"
        />
        <polygon
          fill="#FDC9A3"
          points="160.5343475,86.6050339 157.9978333,91.595314 161.8890991,94.3267899 165.7227325,90.2469635 "
        />
        <polygon
          fill="#e0ca4b"
          points="158.9360046,116.1389771 162.5121155,93.0501404 157.9250336,91.752739 153.191864,112.3725586
    "
        />
        <path
          fill="#E2AE44"
          d="M124.1303558,101.0367203l-3.7649994,0.0909653
    c-3.6207581,0.0875168-6.5045929-3.0080261-6.161293-6.6135178l1.2455521-13.080925
    c0.5256424-5.5204315,5.0944595-9.7768021,10.6382141-9.9107819l0.5076523-0.0122604l0.5019836,6.0769348
    c0.0499725,2.0677872-0.695961,5.6470718-1.7398224,7.4327469l-2.8297958,3.7767334
    c-2.1709213,3.7136841-0.8193359,8.6849289,1.6024704,12.2401123L124.1303558,101.0367203z"
        />
        <path
          fill="#E2AE44"
          d="M123.9829559,73.9596252l5.2965546,6.1689606c1.898407,2.2110748,4.6894379,3.4527588,7.6028442,3.3823547
    l0,0l0.1277161,5.285675l-0.1997528-8.2666779c-0.0917816-3.7976608-3.2447815-6.8019028-7.04245-6.7101212L123.9829559,73.9596252
    z"
        />
        <path
          fill="none"
          stroke="#0E0E11"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
    M131.6112671,86.5631332l2.2314453-0.1059952c0.7555237-0.0358887,1.3443298-0.683403,1.3084412-1.4389343l0,0
    c-0.0358887-0.7555313-0.6834106-1.3443298-1.4389343-1.3084412l-2.2314453,0.1059952
    c-0.7555237,0.035881-1.3443298,0.683403-1.3084412,1.4389343l0,0
    C130.2082214,86.0102234,130.8557434,86.5990143,131.6112671,86.5631332z"
        />
        <path
          fill="none"
          stroke="#0E0E11"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
    M125.5506516,86.9447174l2.2314453-0.1059875c0.7555313-0.0358887,1.3443222-0.6834106,1.3084335-1.4389343v-0.0000076
    c-0.0358887-0.7555237-0.6834106-1.3443222-1.4389343-1.3084335l-2.2314453,0.1059875
    c-0.7555237,0.0358887-1.3443222,0.6834106-1.3084335,1.4389343v0.0000076
    C124.1476059,86.3918076,124.7951202,86.9806061,125.5506516,86.9447174z"
        />

        <line
          fill="none"
          stroke="#0E0E11"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="130.1723328"
          y1="85.2546921"
          x2="129.0905304"
          y2="85.2546921"
        />
        <polygon
          fill="#09193b"
          points="137.3474884,182.2080078 109.3003006,182.2080078 112.1629333,153.3198853
    139.4979553,153.3198853 "
        />
        <path
          fill="#09193b"
          d="M109.2897949,233.4785004h-5.2022629l-0.4852142,3.601593h7.77388
    c0.6159973,0,1.1153412-0.4993744,1.1153412-1.1153717l0,0c0-0.3991852-0.2133408-0.7678986-0.5593719-0.9669037
    L109.2897949,233.4785004z"
        />
        <g>
          <g>
            <path
              fill="#99DDF2"
              d="M135.5662231,48.5272827c2.9031219,0.319767,4.8853455-3.8300056,7.9096375-0.5518494
        c0.021759,0.0235939,17.0745544,18.5078735,17.0745544,18.5078735c1.5025635,1.6286926,1.399353,4.1906281-0.2293396,5.6931915
        l-1.9741821,1.8212891c-1.6286926,1.5025635-4.1906281,1.3993607-5.6931763-0.229332l-17.074585-18.5078964
        c-1.5025635-1.6286926-1.399353-4.1906281,0.2293396-5.6931877c0,0,0.1230469-0.1135292,0.307724-0.2838974"
            />
            <path
              fill="#99DDF2"
              d="M157.4443054,96.7865295l-15.0550842-16.3188782
        c-1.1188202-1.212738-1.0419769-3.1203842,0.1707611-4.2391968l21.2543182-19.6083031
        c1.212738-1.1188202,3.1203766-1.0419769,4.2391968,0.1707611l15.0550842,16.3188744
        c1.1188202,1.2127457,1.0419769,3.1203842-0.1707611,4.2392044l-21.2543182,19.6082993
        C160.4707642,98.0761108,158.5631256,97.9992676,157.4443054,96.7865295z"
            />
            <path
              fill="#99DDF2"
              d="M165.1049957,118.9544373L144.5860443,96.713028
        c-1.1188202-1.212738-1.0419769-3.1203766,0.1707611-4.2391968l35.0735474-32.3573112
        c1.212738-1.1188202,3.1203766-1.0419769,4.2391968,0.1707611l20.5189514,22.2414055
        c1.1188202,1.212738,1.0419769,3.1203766-0.1707764,4.2391968l-35.0735321,32.3573151
        C168.1314545,120.2440186,166.2238159,120.1671753,165.1049957,118.9544373z"
            />
            <path
              fill="#e0ca4b"
              d="M164.9521027,122.7498932l-1.3954163-1.512558
        c-1.1188202-1.2127457-1.0419769-3.1203842,0.1707611-4.2392044l39.0218811-35.9998856
        c1.212738-1.1188202,3.1203766-1.0419769,4.2391968,0.1707611l1.3954315,1.5125656
        c1.1188202,1.212738,1.0419769,3.1203766-0.1707611,4.2391968l-39.0218964,35.9998932
        C167.9785614,124.0394745,166.0709229,123.9626389,164.9521027,122.7498932z"
            />
          </g>
          <path
            fill="none"
            stroke="#99DDF2"
            strokeWidth="2"
            strokeMiterlimit="10"
            d="M137.2414703,48.9097633
      c-7.040451-7.4575272-14.581192-14.6902275-24.2102127-18.6982288c-6.6226807-2.7566376-14.310463-3.6089211-20.9553757-0.9063206
      c-7.0616989,2.8721161-12.1024094,9.381176-15.1196671,16.3820915
      c-3.0172653,7.0009193-4.3128891,14.5957413-6.0337677,22.0224037
      c-4.0714951,17.5710449-10.6250916,34.4995346-18.4682007,50.7415924
      c-9.4011192,19.4684982-21.0841084,38.4890213-38.2521286,51.6291275
      c-4.3872032,3.3578949-9.0894861,6.2588043-14.0161438,8.6894531"
          />
        </g>
        <path
          opacity="0.2"
          fill="#0E0E11"
          d="M154.9320374,96.1696777c-1.3400879-0.8369141-3.1048584-0.4290771-3.9417114,0.9109497
    l-2.7069702,4.2209473c-0.1120605,0.0807495-0.2156372,0.175415-0.3148193,0.2775269l-13.2932739,10.9013672l-19.1807861-7.3416138
    c-2.6832275-1.0269775-5.6912231,0.3156738-6.7182007,2.9990845c-1.0270386,2.6832275,0.3155518,5.69104,2.9988403,6.7182007
    c0.2149048,0.0822754,0.432251,0.1438599,0.6500854,0.1962891l-0.0016479,0.0050049l21.350647,5.1395264l0.0175171-0.0566406
    c0.4553833,0.1193848,0.9390869,0.1606445,1.4347534,0.0974121c1.1422729-0.145752,2.0861206-0.7963257,2.6710815-1.6896362
    l0.0185547,0.0302124l13.9382324-14.1077881l3.4663696-3.7384644l-0.010498-0.0088501
    c0.1991577-0.1772461,0.3844604-0.3742065,0.5328369-0.6118164
    C156.6799622,98.7713623,156.2720642,97.0065918,154.9320374,96.1696777z"
        />
        <g>
          <g>
            <path
              fill="#e0ca4b"
              d="M138.3201141,115.2885513c-0.2642365-2.0709534-2.1572876-3.535614-4.2282562-3.2713776
        c-2.0709839,0.2642136-3.535614,2.1572952-3.2713928,4.2282867c0.2642365,2.070961,2.1572876,3.535614,4.2282715,3.271347
        C137.1197357,119.2526016,138.5843506,117.3595123,138.3201141,115.2885513z"
            />
            <path
              fill="#e0ca4b"
              d="M108.5985565,107.4170151c-1.0270309,2.6832352,0.3155594,5.6910172,2.9988632,6.7182312
        c0.2148972,0.0822449,0.4322586,0.1438065,0.6500473,0.1962433l-0.0016098,0.0050354l21.3506241,5.1395111l2.2314911-7.2072525
        l-20.5112228-7.8508301C112.6335144,103.3909225,109.6255493,104.7335815,108.5985565,107.4170151z"
            />
            <path
              fill="#FDC9A3"
              d="M154.7543335,95.4487305c-1.3400726-0.8369064-3.1048431-0.429039-3.9417419,0.9109802
        c-0.8368988,1.3400726-0.4290314,3.1048431,0.9110107,3.9417648c1.3400574,0.8369064,3.1048279,0.429039,3.9417419-0.9110336
        C156.5022278,98.0504227,156.0943604,96.2856522,154.7543335,95.4487305z"
            />
            <polygon
              fill="#FDC9A3"
              points="155.1429901,100.0111084 151.6766205,103.7495728 148.0794067,100.6215286
        150.8125916,96.3597107 "
            />
            <path
              fill="#FDC9A3"
              d="M151.0796967,100.7114258c1.0014343,0.8692398,1.1085968,2.3857117,0.2393188,3.3871536
        c-0.8692322,1.0014267-2.3857117,1.1085892-3.387146,0.2393188c-1.0014343-0.8692398-1.1085968-2.3857574-0.2393188-3.3871536
        C148.5617828,99.9493103,150.0783081,99.8421402,151.0796967,100.7114258z"
            />
            <polygon
              fill="#e0ca4b"
              points="134.1615295,112.0351105 148.0794067,100.6215286 151.6766205,103.7495728
        137.7384186,117.857399 "
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NoNetworkIllustration;
