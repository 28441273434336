import find from 'lodash.find';
import uniq from 'lodash.uniq';
import moment from 'moment-timezone';

/**
 * Test if user got a profile of type
 * @param {Object} user
 * @param {String} type
 */
const doesUserHaveProfile = (user, type) => {
  if (user?.type === type) {
    return true;
  }
  // @deprecated : https://github.com/Startouf/MyJobGlasses/issues/3317
  if (user?.profile?.type === type) {
    return true;
  }
  if (
    Array.isArray(user?.profiles) &&
    find(user.profiles, (profile) => profile.type === type)
  ) {
    return true;
  }
  return false;
};

/**
 * Extract all types of profile for a user
 * @param {JsonApiCurrentUser} user
 * @example extractUserProfileTypes({ ... }) = ['moderator', 'MJGAdmin'...];
 */
export const extractUserProfileTypes = (user) => {
  let types = [];
  if (user.type) {
    types.push(user.type);
  }
  if (user.profile && user.profile.type) {
    types.push(user.profile.type);
  }
  if (user.profiles) {
    types = types.concat(user.profiles.map((profile) => profile.type));
  }
  return uniq(types);
};

/**
 * Return true if user passed is a mentor
 * @param {JsonApiCurrentUser} user
 */
export const isMentor = (user) => doesUserHaveProfile(user, 'mentor');

/**
 * Return true if user passed is a student
 * @param {JsonApiCurrentUser} user
 */
export const isStudent = (user) => doesUserHaveProfile(user, 'student');

/**
 * Return true if user passed is an ambassador
 * @param {JsonApiCurrentUser} user
 */
export const isAmbassador = (user) => doesUserHaveProfile(user, 'employee');

/**
 * Return true if user passed is a VIP
 * @param {JsonApiCurrentUser} user
 */
export const isVIP = (user) => doesUserHaveProfile(user, 'vip');

/**
 * Return true if user passed is a moderator
 * @param {JsonApiCurrentUser} user
 */
export const isModerator = (user) => doesUserHaveProfile(user, 'moderator');

/**
 * Return true is user passed is a professionnal
 * @param {JsonApiCurrentUser} user
 */
export const isProfessional = (user) =>
  isMentor(user) || isAmbassador(user) || isVIP(user);

/**
 * Return true is user passed is a MJG Admin
 * @param {JsonApiCurrentUser} user
 */
export const isMjgAdmin = (user) => doesUserHaveProfile(user, 'MJGAdmin');

/**
 * @deprecated - use #canProfileRequestPublication
 * @param {JsonApiCurrentUser} user
 */
export const isProfileComplete = (user) => canProfileRequestPublication(user);

/**
 * Return true if the profile is complete at 100%
 * @param {JsonApiCurrentUser} user
 */
export const canProfileRequestPublication = (user) =>
  user && user.profile && user.profile.canRequestPublication;

/**
 * Return true if user request publication on his profile
 * @param {Object} user
 */
export const isProfilePublicationRequested = (user) =>
  user && user.profile && !!user.profile.publicationRequestedAt;

/**
 * Return true if user is published
 * @param {Object} user
 */
export const isProfilePublished = (user) =>
  user && user.profile && user.profile.published;

/**
 * Return true if user has a pending profile draft
 * @param {Object} user
 */
export const hasPendingProfileDraftSelector = (user) =>
  user?.profile?.draft?.commitStatusCd === 'changes_to_be_committed';

/**
 * Return true if user has a pending profile draft
 * @param {Object} user
 */
export const hasCommittedProfileDraftSelector = (user) =>
  user?.profile?.draft?.commitStatusCd === 'committed';

/**
 * Get the best version of user avatar
 * @param {Object} user - User on which we want to retrieve avatar
 * @param {String} mode - Which version of avatar
 * @returns {String|undefined} The requested avatar OR the default avatar OR null
 */
export const getUserAvatar = (user, mode) =>
  user &&
  user.avatar &&
  ((user.avatar[mode] && user.avatar[mode].url) ||
    (user.avatar && user.avatar.url));

/**
 * Extract linkedin avatar url from user identity
 * @param {Object} user
 */
export const getLinkedinUserAvatarUrl = (user) =>
  user?.identities?.[0]?.oauthInfo?.image;

/**
 * Transform an api professional profile to Result Search Object
 * @param {Object} professional
 * @example professional = {
 *    id: '5sd4q6f4dsf6qsf4d6fqsd',
 *    attributes: {
 *      resultScore: 0.65,
 *      ...
 *    },
 *    ...
 * }
 * => searchResult = {
 *    id: '5sd4q6f4dsf6qsf4d6fqsd',
 *    resultScore: 0.65,
 * }
 * @return {Object} A search result representation
 */
export const transformProfessionalToSearchResult = (professional) => ({
  id: professional.id,
  resultScore:
    (professional.attributes && professional.attributes.resultScore) || 0,
});

/**
 * Check if a user was published
 * @param {Object} user - user to test
 */
export const isPublished = (user = {}) => {
  const mainProfile = getUserMainProfile(user);
  return mainProfile?.published || false;
};

/**
 * Check if user need to confirm his email
 * @param {Object} user
 */
export const doesUserNeedToConfirmEmail = (user = {}) =>
  user?.mustConfirmEmail || false;

/**
 * Retrieve primary profile of user
 * @param {JsonApiCurrentUser} user
 * @return {JsonApiMappedProfile}
 */
export const getUserMainProfile = (user) => {
  const profiles = user?.profiles || [];
  const profile = profiles.find(
    ({ type }) => ['student', 'mentor', 'employee'].indexOf(type) > -1,
  );
  if (profile) {
    return profile;
  }
  return user?.profiles?.[0] || {};
};

/**
 * Sort education futher first
 * @param {Object} profile
 * @param {Array} [profile.educactions]
 * @returns {Array}
 */
export const getProfileEducationsSorted = (profile) => {
  if (!Array.isArray(profile?.educations)) {
    return [];
  }
  return profile?.educations?.sort(({ endedAt: a }, { endedAt: b }) =>
    moment(b).diff(moment(a)),
  );
};
