import React from 'react';
import NoNetworkIllustration from '../../../icons/NoNetworkIllustration';
import { t } from '../../../utils/translate';
import translations from './NoNetwork.translations';

import styles from './NoNetwork.module.scss';

interface NoNetworkProps {
  refetch?: ((variables?: any) => Promise<any>) | (() => void);
}
function NoNetwork({ refetch }: NoNetworkProps) {
  return (
    <div className={styles.noNetwork}>
      <NoNetworkIllustration className={styles.illustration} />
      <p className={styles.description}>{t(translations.description)}</p>
      {typeof refetch === 'function' && (
        <button
          type="button"
          className={styles.button}
          onClick={() => refetch()}
        >
          {t(translations.refetchButton)}
        </button>
      )}
    </div>
  );
}

export default NoNetwork;
