import { createResourceSuccess, createResourceError } from './api';

// Linkedin authentication is expected to return either an Identity (registration) or credentials (signin)
//  But success/error is context dependent : a linkedin signup returning credentials == "account already exists error"

export const CREATE_VANILLA_AUTHENTICATION_CREDENTIALS =
  '@@api/CREATE_VANILLA_AUTHENTICATION_CREDENTIALS';
export const CREATE_LINKEDIN_AUTHENTICATION_IDENTITY =
  '@@api/CREATE_LINKEDIN_AUTHENTICATION_IDENTITY';
export const CREATE_IDENTITY_AUTHENTICATION_CREDENTIALS =
  '@@api/CREATE_IDENTITY_AUTHENTICATION_CREDENTIALS';

// Success / errors are flavor-independent

export const CREATE_RESOURCE_AUTHENTICATION_ERROR =
  '@@api/CREATE_RESOURCE_AUTHENTICATION_ERROR';
export const CREATE_RESOURCE_CREDENTIALS_SUCCESS =
  '@@api/CREATE_RESOURCE_CREDENTIALS_SUCCESS';
export const CREATE_RESOURCE_IDENTITY_SUCCESS =
  '@@api/CREATE_RESOURCE_IDENTITY_SUCCESS';

//
export const authenticationRequestTypes = [
  CREATE_VANILLA_AUTHENTICATION_CREDENTIALS,
  CREATE_IDENTITY_AUTHENTICATION_CREDENTIALS,
  CREATE_LINKEDIN_AUTHENTICATION_IDENTITY,
];

export const authSuccessActionMapper = () => {
  return {
    [CREATE_IDENTITY_AUTHENTICATION_CREDENTIALS]: authSuccessActionResolver,
    [CREATE_LINKEDIN_AUTHENTICATION_IDENTITY]: authSuccessActionResolver,
    [CREATE_VANILLA_AUTHENTICATION_CREDENTIALS]: authSuccessActionResolver,
  };
};

export const authSuccessActionResolver = (type, response, request) => {
  const requestType = request && request.data && request.data.type;
  // handle signup with linkedin success
  if (response.identity) {
    // The identity will be used very shortly, use a simple jsonapi extraction
    return createResourceSuccess({
      resourceType: 'identity',
      data: response,
    });
  }
  // handle signup with linkedin on existing account
  else if (requestType === 'identity' && response.credentials) {
    return createResourceError({
      resourceType: 'identity',
      data: response,
    });
  }
  // handle other case
  else if (response.credentials) {
    return createResourceSuccess({
      resourceType: 'credentials',
      data: response,
    });
  }
};

export const authErrorActionMapper = () => {
  return {
    [CREATE_IDENTITY_AUTHENTICATION_CREDENTIALS]: authErrorActionResolver,
    [CREATE_VANILLA_AUTHENTICATION_CREDENTIALS]: authErrorActionResolver,
    [CREATE_LINKEDIN_AUTHENTICATION_IDENTITY]: authErrorActionResolver,
  };
};

export const authErrorActionResolver = (error) => {
  return {
    type: CREATE_RESOURCE_AUTHENTICATION_ERROR,
    error: error,
  };
};

/*
 * @param { String } Authentication flavor in ['vanilla', 'linkedin']
 * type { String } Authentication flow in ['signin', 'signup']
 */
export const createAuthentication = (flavor, flow, attributes) => {
  const authenticationFactory = {
    vanilla: {
      signin: {
        credentials: vanillaCredentials,
        action: createVanillaSignin,
      },
      signup: {
        credentials: vanillaCredentials,
        action: createVanillaSignin,
      },
    },
    identity: {
      signin: {
        credentials: notAllowed,
        action: notAllowed,
      },
      signup: {
        credentials: identityCredentials,
        action: createIdentitySignin,
      },
    },
    linkedin: {
      signin: {
        credentials: linkedinAuthCode,
        action: createOauthSignin,
      },
      signup: {
        credentials: linkedinAuthCode,
        action: createOauthSignup,
      },
    },
  };
  const selectedProvider = authenticationFactory[flavor] || notAllowed(flavor);
  const selectedFlow = selectedProvider[flow] || notAllowed(selectedProvider);
  const selectedCredentials = (
    selectedFlow.credentials || notAllowed(selectedFlow)
  )(attributes);
  return (selectedFlow.action || notAllowed(selectedFlow))(selectedCredentials);
};

const notAllowed = (what) => {
  throw new Error(what, 'is not allowed as type/flavor for signin');
};

const vanillaCredentials = ({ email, password }) => {
  return { email, password };
};

const linkedinAuthCode = ({ code }) => {
  return {
    authorization_code: code,
    provider: 'linkedin',
    // eslint-disable-next-line
    redirect_uri: window.__RUNTIME_CONFIG__.REACT_APP_LINKEDIN_CALLBACK_URL,
  };
};

const identityCredentials = ({ email, identity_id }) => {
  return { email, identity_id };
};

const createVanillaSignin = (attributes, resolve, reject) => {
  return createRawAuthentication(
    CREATE_VANILLA_AUTHENTICATION_CREDENTIALS,
    'credentials',
    attributes,
    resolve,
    reject,
  );
};

const createOauthSignup = (attributes, resolve, reject) => {
  return createRawAuthentication(
    CREATE_LINKEDIN_AUTHENTICATION_IDENTITY,
    'identity',
    attributes,
    resolve,
    reject,
  );
};

const createOauthSignin = (attributes, resolve, reject) => {
  return createRawAuthentication(
    CREATE_LINKEDIN_AUTHENTICATION_IDENTITY,
    'credentials',
    attributes,
    resolve,
    reject,
  );
};

const createIdentitySignin = (attributes, resolve, reject) => {
  return createRawAuthentication(
    CREATE_IDENTITY_AUTHENTICATION_CREDENTIALS,
    'credentials',
    attributes,
    resolve,
    reject,
  );
};

const createRawAuthentication = (
  actionName,
  jsonapiType,
  attributes,
  resolve,
  reject,
) => {
  return {
    type: actionName,
    resourceUri: '/authentications',
    resourceType: jsonapiType,
    data: { attributes, type: jsonapiType },
    resolve,
    reject,
  };
};
