import { push } from 'connected-react-router';
import { generatePath } from 'react-router';
import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import {
  getPendingMentorModerationList,
  getMentorModeration,
  getNextModeration,
  getStandbyMentorModerationList,
  getPendingSchoolModerations,
  getSchoolModeration,
  getNextSchoolModeration,
} from '../actions/moderator';
import { addToIndex, buildIndex } from '../actions/api-indexes';
import { intializeSectors } from '../actions/company';
import ApiResource from '../api-resources';
import Routes from '../routes';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../utils/actions';
import { getErrorStatusCode } from '../utils/json-api';

/**
 * Actions to process on mentor moderation page loading (for pending)
 */
export function* hydrateMentorModerationList() {
  yield put(getPendingMentorModerationList());
}

/**
 * Actions to process on mentor moderation page loading (for standby)
 */
export function* hydrateMentorModerationStandbyList() {
  yield put(getStandbyMentorModerationList());
}

/**
 * Actions to process before loading moderation page
 * If moderation is already moderate, request next moderation
 */
export function* hydrateMentorModerationPageView(id) {
  yield put(intializeSectors());
  yield put(getMentorModeration(id));
  const { success, error } = yield race({
    success: take(
      createSuccessActionType('READ', ApiResource.MODERATION_MENTOR),
    ),
    error: take(createErrorActionType('READ', ApiResource.MODERATION_MENTOR)),
  });
  if (error) {
    const errorCode = getErrorStatusCode(error);
    if (errorCode === 409) {
      yield call(goToNextModeration);
    }
  } else if (success) {
    const moderations = success?.data?.moderationMentor;
    const moderation = Object.values(moderations);
    if (moderation?.[0]?.attributes?.moderationStatus === 'approved') {
      yield call(goToNextModeration);
    }
  }
}

/**
 * Actions to process on school moderation list view
 */
export function* hydratePendingSchoolModerationList() {
  yield put(getPendingSchoolModerations());
}

/**
 * Actions to process on school moderation view
 * @param {String} id
 */
export function* hydratePendingSchoolModerationView(id) {
  yield put(getSchoolModeration(id));
}

/**
 * Build list on receiving data
 */
export function* autobuildModerationIndex() {
  yield takeEvery(
    [
      createSuccessActionType('READ_LIST', ApiResource.MODERATION_MENTOR),
      createSuccessActionType(
        'READ_LIST',
        ApiResource.MODERATION_MENTOR_STANDBY,
      ),
      createSuccessActionType('READ_LIST', ApiResource.MODERATION_SCHOOL),
    ],
    function* (payload) {
      let page = 1;
      const requestDataParams = payload?.requestData?.params;
      if (requestDataParams) {
        page = requestDataParams['page[number]'] || page;
      }

      let total = 0;
      const meta = Object.values(payload.data.meta || {});
      if (meta.length) {
        total = meta?.[0]?.meta?.stats?.total?.count;
      }

      const indexKeys = {
        [ApiResource.MODERATION_MENTOR]: {
          indexKey: 'pendingModerationList',
          resultKey: 'moderationMentor',
        },
        [ApiResource.MODERATION_MENTOR_STANDBY]: {
          indexKey: 'standbyModerationList',
          resultKey: 'moderationMentor',
        },
        [ApiResource.MODERATION_SCHOOL]: {
          indexKey: 'pendingSchoolModerationList',
          resultKey: 'moderationSchool',
        },
      };
      const requestType = payload?.requestData?.data?.type || '';
      const indexKey = indexKeys?.[requestType]?.indexKey || '';
      const moderationList =
        payload?.data?.[indexKeys?.[requestType]?.resultKey || ''];
      const pageSize = payload?.requestData?.params?.page?.size || 20;
      const totalPages = Math.ceil(total / pageSize);

      if (page > 1) {
        yield put(
          addToIndex(indexKey, Object.keys(moderationList || {}), {
            page,
            total,
            totalPages,
          }),
        );
      } else {
        yield put(
          buildIndex(indexKey, Object.keys(moderationList || {}), {
            page: 1,
            total,
            totalPages,
          }),
        );
      }
    },
  );
}

/**
 * Handle approval & rejection for redirect to view page
 */
export function* handleModerationAfterUpdateStatus() {
  yield takeEvery(
    createSuccessActionType('UPDATE', ApiResource.MODERATION_MENTOR_APPROVAL),
    goToNextModeration,
  );
  yield takeEvery(
    createSuccessActionType('UPDATE', ApiResource.MODERATION_MENTOR_REJECTION),
    goToNextModeration,
  );
  yield takeEvery(
    createSuccessActionType('UPDATE', ApiResource.MODERATION_MENTOR_STANDBY),
    goToNextModeration,
  );
  yield takeEvery(
    createSuccessActionType('UPDATE', ApiResource.MODERATION_SCHOOL_APPROVE),
    goToNextSchoolModeration,
  );
}

/**
 * Handle actions to execute to go to the next moderation
 * Or to the list if 404 returned
 */
function* goToNextModeration() {
  yield put(getNextModeration());
  const { success, error } = yield race({
    success: take(
      createSuccessActionType('READ', ApiResource.MODERATION_MENTOR),
    ),
    error: take(createErrorActionType('READ', ApiResource.MODERATION_MENTOR)),
  });
  if (success) {
    const moderationMentors = success?.data?.moderationMentor || {};
    const moderationID = Object.keys(moderationMentors)[0];
    yield put(
      push(generatePath(Routes.mentorModerationView, { id: moderationID })),
    );
    window.scrollTo(0, 0);
  } else if (error) {
    const errorCode = getErrorStatusCode(error);
    if (errorCode === 404) {
      yield put(push(Routes.pendingMentorModerationsList));
    }
  }
}

/**
 * Handle school moderation approve to go to next moderation
 */
function* goToNextSchoolModeration() {
  yield put(getNextSchoolModeration());
  const { success, error } = yield race({
    success: take(
      createSuccessActionType('READ', ApiResource.MODERATION_SCHOOL),
    ),
    error: take(createErrorActionType('READ', ApiResource.MODERATION_SCHOOL)),
  });
  if (success) {
    const monderationSchool = success?.data?.moderationSchool;
    const moderationID = Object.keys(monderationSchool)[0];
    yield put(
      push(generatePath(Routes.schoolModerationView, { id: moderationID })),
    );
    window.scrollTo(0, 0);
  } else if (error) {
    const errorCode = getErrorStatusCode(error);
    if (errorCode === 404) {
      yield put(push(Routes.schoolModerationList));
    }
  }
}

// @todo SUPPRESS THIS WITH https://github.com/Startouf/MyJobGlasses/issues/2908
export function* autoupdateLocalCompanyLogoWithServerData() {
  yield takeEvery(
    [
      createSuccessActionType('UPLOAD', ApiResource.MODERATION_MENTOR),
      createSuccessActionType('UPDATE', ApiResource.MODERATION_MENTOR),
    ],
    function* (payload) {
      yield put({
        type: 'FORCE_DIRTY_PATCH_API',
        data: {
          profileMentors: Object.values(payload?.data?.profileMentor),
          profileMentorDrafts: Object.values(payload?.data?.profileMentorDraft),
        },
      });
    },
  );
}
