export const INCREASE_TO_CURRENT_SECTION =
  '@@profileCompletion/INCREASE_TO_CURRENT_SECTION';
export const PROFILE_REGISTER_FIELDS =
  '@@profileCompletion/PROFILE_REGISTER_FIELDS';
export const SCROLL_TO_FIRST_SECTION_IN_ERROR =
  '@@profileCompletion/SCROLL_TO_FIRST_SECTION_IN_ERROR';
export const SET_PROFILE_PAGE_CONTEXT =
  '@@profileCompletion/SET_PROFILE_PAGE_CONTEXT';

/**
 * Set current modified section if up to previous
 * @param {Integer} current - current order
 */
export const increaseToCurrentSection = (current) => ({
  type: INCREASE_TO_CURRENT_SECTION,
  current,
});

/**
 * Register fieldset fields list
 * @param {String} fielsetName
 * @param {Array<String>} fieldsName
 */
export const registerFieldsetFields = (fieldsetName, fieldNames, order) => ({
  type: PROFILE_REGISTER_FIELDS,
  fieldsetName,
  fieldNames,
  order,
});

/**
 * Auto scroll to first section in error
 */
export const scrollToFirstSectionInError = () => ({
  type: SCROLL_TO_FIRST_SECTION_IN_ERROR,
});

/**
 * Set context for profile page
 * @param {String} context
 */
export const setProfilePageContext = (context) => ({
  type: SET_PROFILE_PAGE_CONTEXT,
  context,
});
