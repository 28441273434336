import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UsernavContext from '../../../../context/UsernavContext';
import { useSelector } from '../../../../../../../utils/hooks/useRedux';
import { UsernavItemLinkProps } from 'scenes/layout/header/@types/Usernav';

const UsernavItemLink = ({
  to,
  label,
  className,
  isNotificationSelector = () => false,
  icon: Icon,
}: UsernavItemLinkProps) => {
  const { closeUsernavMenu } = useContext(UsernavContext);
  const hasNotifications = useSelector(isNotificationSelector);

  return (
    <Link
      to={to}
      className={className}
      onClick={() => closeUsernavMenu()}
    >
      {Icon && <Icon className="icon" />}
      {label}
      {!!hasNotifications && <span className="notification-bubble" />}
    </Link>
  );
};

export default UsernavItemLink;
