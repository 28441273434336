import { put, race, take } from 'redux-saga/effects';
import { JsonApiCommunicationProfileAttributes } from '../../../@types/jsonapi.d';
import { snakeCaseObjectKeys } from '../../../utils/dev-tools';
import {
  updateProfileResource,
  UpdateProfileAttributesActions,
} from '../actions/profile';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../../../utils/actions';

/**
 *
 * @param {Object} action
 * @param {String} action.type
 * @param {Object} action.values
 * @param {Function} [action.resolve]
 * @param {Function} [action.reject]
 */
interface HandleProfileUpdatesParams extends UpdateProfileAttributesActions {}

export function* handleProfileUpdates({
  values,
  resolve,
  reject,
}: HandleProfileUpdatesParams) {
  const {
    profileId,
    profileType: userType,
    location,
    phoneCountryCode,
    recommendedQualificationsCumulableChoices,
    recommendedQualificationsMutuallyExclusiveChoices,
    ...otherValues
  } = values;
  let handledValue: JsonApiCommunicationProfileAttributes = {};
  if (location) {
    handledValue.google_place_id = location.placeId;
    handledValue.meeting_place = location.address;
    // on the bad order due to bad API 💩
    handledValue.coordinates = [location.lng, location.lat];
  }
  if (phoneCountryCode) {
    handledValue.phone_country_code = `${phoneCountryCode}`;
  }
  if (
    Array.isArray(recommendedQualificationsMutuallyExclusiveChoices) &&
    recommendedQualificationsMutuallyExclusiveChoices.includes(
      'no_study_level_required',
    )
  ) {
    handledValue.recommended_qualifications_mutually_exclusive_choices = [
      'no_study_level_required',
    ];
    handledValue.recommended_qualifications_cumulable_choices = [];
  } else {
    handledValue.recommended_qualifications_cumulable_choices =
      recommendedQualificationsCumulableChoices;
  }
  handledValue = {
    ...handledValue,
    ...snakeCaseObjectKeys({
      ...otherValues,
    }),
  };
  yield put(
    updateProfileResource({
      userType,
      profileId,
      attributes: handledValue,
    }),
  );

  const { error } = yield race({
    success: take(createSuccessActionType('UPDATE', `profile/${userType}`)),
    error: take(createErrorActionType('UPDATE', `profile/${userType}`)),
  });
  if (error && typeof reject === 'function') {
    reject(error?.error);
  } else if (!error && typeof resolve === 'function') {
    resolve();
  }
}
