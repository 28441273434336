import React from 'react';

export default function LikeCount() {
  return (
    <svg
      width="21px"
      height="19px"
      viewBox="0 0 21 19"
    >
      <defs></defs>
      <g
        id="UI"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="profil_pro"
          transform="translate(-35.000000, -624.000000)"
          fillRule="nonzero"
          fill="#09193b"
        >
          <g
            id="icn_like_blue"
            transform="translate(35.000000, 624.000000)"
          >
            <path
              d="M20.9039691,8.80147278 C20.9039691,7.88831043 20.1958368,7.17198807 19.295925,7.17198807 L14.4705574,7.17076541 C14.2136069,7.17076541 13.3124691,6.77958894 14.0206015,5.67083835 C14.4705574,4.82370659 14.4705574,1.62826424 14.4705574,1.62826424 C14.4705574,0.716322353 13.762425,0 12.8625132,0 C11.9613662,0 11.2532647,0.716353647 11.2532647,1.62948471 C11.2532647,6.38838118 6.75,7.82352941 6.75,7.82352941 L6.75,16.2986024 C8.80923529,17.0161765 9.32311765,18.2544847 11.8974706,18.2544847 L16.7228382,18.2544847 C17.6239853,18.2544847 18.3308824,17.5369106 18.3308824,16.625 C18.3308824,16.2338235 18.2018034,15.9074259 18.0087794,15.6470588 C18.9099265,15.6470588 19.6168235,14.9294847 19.6168235,14.0175741 C19.6168235,13.6263976 19.4877446,13.2352212 19.2307941,12.9088235 C19.8737647,12.6484471 20.2597941,12.0616918 20.2597941,11.4088965 C20.2597941,11.01772 20.1307151,10.6265435 19.8737647,10.3001459 C20.4540132,10.1045576 20.9039691,9.51779294 20.9039691,8.80143929 L20.9039691,8.80147278 Z"
              id="Shape"
            ></path>
            <path
              d="M3.21608824,6.84 L0,6.84 L0,17.9237506 L3.21732353,17.9237506 C4.31148529,17.9237506 5.14747059,17.0105882 5.14747059,15.9678682 L5.14626401,8.79588235 C5.14626401,7.68713176 4.31027872,6.84 3.21611696,6.84 L3.21608824,6.84 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
