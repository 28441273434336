import {
  JsonApiBaseProfile,
  JsonApiBaseUser,
  JsonApiCurrentUser,
} from '../../../../@types/jsonapi.d';

interface InitiatorReviewee {
  initiator?: JsonApiBaseUser;
  initiatorProfile?: JsonApiBaseProfile;
}
interface RecipientReviewee {
  recipient?: JsonApiBaseUser;
  recipientProfile?: JsonApiBaseProfile;
}
export function getReviewee(
  { initiator, initiatorProfile }: InitiatorReviewee,
  { recipient, recipientProfile }: RecipientReviewee,
  currentUser: JsonApiCurrentUser,
) {
  if (currentUser?.id === recipient?.id && initiator && initiatorProfile) {
    return {
      id: initiator.id,
      ...initiator.attributes,
      profile: initiatorProfile.attributes,
    };
  }
  if (currentUser?.id === initiator?.id && recipient && recipientProfile) {
    return {
      id: recipient.id,
      ...recipient.attributes,
      profile: recipientProfile.attributes,
    };
  }
  return null;
}
