import { createMatchSelector } from 'connected-react-router';
import isEmpty from 'lodash.isempty';
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import ApiResource from '../../../../api-resources';
import { setContainerLoadState } from '../../../../actions/container-load-state';
import Routes from '../../../../routes';
import { messagingAppointmentsSelector } from '../../../../selectors';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../../../../utils/actions';
import {
  extractErrorsFromPayload,
  extractFirstJsonapiResource,
} from '../../../../utils/json-api';
import {
  CHOOSE_MISSED_APPOINTMENT_REASON,
  SNOOZE_REVIEW,
  missedAppointment,
  SET_REVIEW,
  createReviewResource,
  snoozeReviewResource,
} from '../actions/review';
import { messagingAppointmentClaimsSelector } from '../selector/review';

/**
 *
 * @param {String} id
 */
function* deleteAppointment(id) {
  const appointments = yield select(messagingAppointmentsSelector);
  const appointmentClaims = yield select(messagingAppointmentClaimsSelector);
  const appointmentsIndex = appointments.findIndex(
    (appointment) => appointment?.id === id,
  );
  const appointmentClaimsIndex = appointmentClaims.findIndex(
    (appointmentClaim) => appointmentClaim?.id === id,
  );

  if (appointmentsIndex > -1) {
    yield put({
      type: 'DIRTY_PATCH_API_DELETE',
      data: {
        messagingAppointments: [
          ...appointments.slice(0, appointmentsIndex),
          ...appointments.slice(appointmentsIndex + 1, appointments.length),
        ],
      },
    });
  }
  if (appointmentClaimsIndex > -1) {
    yield put({
      type: 'DIRTY_PATCH_API_DELETE',
      data: {
        messagingAppointmentClaims: [
          ...appointmentClaims.slice(0, appointmentClaimsIndex),
          ...appointmentClaims.slice(
            appointmentClaimsIndex + 1,
            appointmentClaims.length,
          ),
        ],
      },
    });
  }
}

/**
 *
 * @param {Object} successData
 */
function* addAppointment(successData) {
  const appointment = extractFirstJsonapiResource(
    successData,
    'messagingAppointment',
  );
  const appointmentClaim = extractFirstJsonapiResource(
    successData,
    'messagingAppointmentClaim',
  );

  if (!isEmpty(appointment)) {
    const appointments = yield select(messagingAppointmentsSelector);
    yield put({
      type: 'DIRTY_PATCH_API',
      data: {
        messagingAppointments: [...appointments, appointment],
      },
    });
  }
  if (!isEmpty(appointmentClaim)) {
    const appointmentClaims = yield select(messagingAppointmentClaimsSelector);
    yield put({
      type: 'DIRTY_PATCH_API',
      data: {
        messagingAppointmentClaims: [...appointmentClaims, appointmentClaim],
      },
    });
  }
}

/**
 *
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.isProfessional
 * @param {String} params.question1
 * @param {String} params.answer1
 * @param {String} params.question2
 * @param {String} params.answer2
 * @param {String} params.question3
 * @param {String} params.answer3
 * @param {String} params.liked
 * @param {String} params.messageForProfessional
 * @param {String} params.messageForHr
 * @param {String} params.feedback
 * @param {Function} params.resolve
 * @param {Function} params.reject
 */
function* handleSetReview({
  id,
  isProfessional,
  question1,
  answer1,
  question2,
  answer2,
  question3,
  answer3,
  liked,
  messageForProfessional,
  messageForHr,
  feedback,
  contractType,
  duration,
  startsAtMonth,
  startsAtYear,
  zipCode,
  wantToBeRecontacted,
  domains,
  internationalOutsideFrance,
  administrativeAreas,
  resolve,
  reject,
}) {
  const type = isProfessional ? 'from_professional' : 'from_student';
  const data = {
    question_1: {
      text: question1,
      answer: answer1,
    },
    question_2: {
      text: question2,
      answer: answer2,
    },
    question_3: {
      text: question3,
      answer: answer3,
    },
    liked,
    message_for_professional: messageForProfessional,
    message_for_hr: messageForHr,
    feedback,
  };
  data.want_to_be_recontacted = wantToBeRecontacted;
  if (wantToBeRecontacted) {
    data.aspiration = {
      type: contractType,
      duration,
      domains,
      mobile: !zipCode?.length,
      starts_at_month: startsAtMonth,
      starts_at_year: startsAtYear,
      postal_code: zipCode,
      international_outside_france: internationalOutsideFrance,
      france_level_1_administrative_areas: administrativeAreas,
    };
  }
  yield put(
    createReviewResource({
      type,
      id,
      data,
    }),
  );
  const { success, error } = yield race({
    success: take(
      createSuccessActionType(
        'CREATE',
        ApiResource.MESSAGING_APPOINTMENT_REVIEW,
      ),
    ),
    error: take(
      createErrorActionType('CREATE', ApiResource.MESSAGING_APPOINTMENT_REVIEW),
    ),
  });
  yield call(deleteAppointment, id);
  if (error) {
    const errorExtracted = extractErrorsFromPayload(error);
    const errorCode = errorExtracted?.[0]?.name;
    switch (errorCode) {
      case 'already_reviewed' || 'already_resolved':
        break;
      case 'cannot_review_cancelled_appointment':
        break;
      case 'too_early_for_review':
        break;
      default:
        break;
    }
    if (typeof reject === 'function') {
      reject(error.error);
    }
    return;
  }
  yield call(addAppointment, success);
  resolve();
}

/**
 *
 * @param {Object} params
 * @param {String} params.id
 * @param {String} params.reason
 * @param {String} params.comment
 * @param {Function} params.resolve
 * @param {Function} params.reject
 */
function* handleMissedAppointment({ id, reason, comment, resolve, reject }) {
  yield put(missedAppointment({ id, reason, comment }));
  const { success, error } = yield race({
    success: take(createSuccessActionType('CREATE', ApiResource.ABSENCE)),
    error: take(createErrorActionType('CREATE', ApiResource.ABSENCE)),
  });
  yield call(deleteAppointment, id);
  if (error) {
    const errorExtracted = extractErrorsFromPayload(error);
    const errorCode = errorExtracted?.[0]?.name;
    switch (errorCode) {
      case 'already_reviewed' || 'already_resolved':
        break;
      case 'cannot_review_cancelled_appointment':
        break;
      case 'too_early_for_review':
        break;
      default:
        break;
    }
    if (typeof reject === 'function') {
      reject(error.error);
    }
    return;
  }
  yield call(addAppointment, success);
  resolve();
}

/**
 * Snooze appointment review
 * @param {Object} params
 * @param {boolean} params.id
 * @param {function} params.resolve
 * @param {function} params.reject
 */
function* handleSnoozeReview({ id, resolve, reject }) {
  yield put(snoozeReviewResource({ id }));
  const { error } = yield race({
    success: take(
      createSuccessActionType('CREATE', `${ApiResource.MESSAGING_APPOINTMENT}`),
    ),
    error: take(
      createErrorActionType('CREATE', `${ApiResource.MESSAGING_APPOINTMENT}`),
    ),
  });
  if (error) {
    const errorExtracted = extractErrorsFromPayload(error);
    const errorCode = errorExtracted?.[0]?.name;
    switch (errorCode) {
      case 'already_snoozing' || 'cannot_snooze_more_than_once':
        break;
      case 'cannot_snooze':
        break;
      default:
        break;
    }
    yield put(setContainerLoadState('review', { error: true }));
    if (typeof reject === 'function') {
      reject(error.error);
    }
    return;
  }
  yield put(setContainerLoadState('review', { success: true }));
  resolve();
}

/**
 *
 */
export function* hydrateReviewView() {
  yield takeEvery([SET_REVIEW], handleSetReview);
  yield takeEvery([CHOOSE_MISSED_APPOINTMENT_REASON], handleMissedAppointment);
  yield takeEvery([SNOOZE_REVIEW], handleSnoozeReview);
}
