import hash from 'object-hash';
import pluralize from 'pluralize';
import ApiResource from '../api-resources';
import {
  readResource,
  updateResource,
  createResource,
  uploadResource,
  deleteResource,
} from './api';
export const HYDRATE_PROFILE = '@@profile/HYDRATE';
export const CONTACT_PROFILE = '@@profile/CONTACT_PROFILE';
export const UPLOAD_AVATAR = '@@profile/UPLOAD_AVATAR';
export const UPDATE_ORGANIZATION_LOGO = '@@profile/UPDATE_ORGANIZATION_LOGO';
export const CROP_AVATAR = '@@profile/CROP_AVATAR';
export const UPDATE_AVATAR = '@@profile/UPDATE_AVATAR';
export const UPDATE_PROFILE_INFOS = '@@profile/UPDATE_PROFILE_INFOS';
export const UPDATE_ADDRESS = '@@profile/UPDATE_ADDRESS';
export const UPDATE_COORDINATES_SUCCESS =
  '@@profile/UPDATE_COORDINATES_SUCCESS';
export const UPDATE_COORDINATES_ERROR = '@@profile/UPDATE_COORDINATES_ERROR';
export const INITIALIZE_ROOT_TAGS = '@@profile/INITIALIZE_ROOT_TAGS';

export const getEmployee = ({ id }) =>
  readResource({
    resourceUri: `/employees/${id}`,
    data: {
      type: 'profile/employee',
    },
    params: {
      include: ['user', 'promotions', 'educations', 'profile_draft'].join(','),
    },
  });

export const getProfile = () =>
  readResource({
    resourceUri: '/bootstraps',
    data: {
      type: 'user',
    },
  });

export const getEmployeeFromInvitation = (invitationToken) =>
  readResource({
    resourceUri: '/registration/company/employees/new',
    data: {
      type: 'profile/employee',
    },
    params: {
      invitation_token: invitationToken,
    },
  });

export const hydrateProfile = (profile) => ({
  type: HYDRATE_PROFILE,
  profile,
});

export const contactProfile = (id) => ({
  type: CONTACT_PROFILE,
  id,
});

export const updateProfile = (
  { profileId, userType, attributes = {}, relationships = {}, included = {} },
  resolve,
  reject,
) =>
  updateResource(
    {
      resourceUri: `/${pluralize(userType)}/${profileId}`,
      data: {
        type: `profile/${userType}`,
        attributes,
        relationships,
      },
      included,
    },
    resolve,
    reject,
  );

export const updateProfileInfos = ({ values, userType }, resolve, reject) => ({
  type: UPDATE_PROFILE_INFOS,
  values,
  userType,
  resolve,
  reject,
});

export const updateProfileDescription = (
  { attributes, userType },
  resolve,
  reject,
) =>
  updateResource(
    {
      resourceUri: `/${pluralize(userType)}`,
      data: {
        type: `profile/${userType}`,
        attributes,
      },
    },
    resolve,
    reject,
  );

export const updateProfileMappable = (
  { meeting_place, latitude, longitude, type },
  resolve,
  reject,
) =>
  updateResource(
    {
      resourceUri: `/${pluralize(type)}`,
      data: {
        type: `profile/${type}`,
        attributes: {
          meeting_place,
          coordinates: [longitude, latitude], // WARNING Api is accepting [x,y] = [longitude, latitude] NOT [latitude, longitude]
        },
      },
    },
    resolve,
    reject,
  );

export const updateProfileEducationAndTags = (
  { attributes = {}, educations = [], deletedIds = [], type },
  resolve,
  reject,
) =>
  updateResource(
    {
      resourceUri: `/${pluralize(type)}`,
      data: {
        type: ApiResource.PROFILE,
        attributes,
        relationships: {
          educations: {
            data: [
              ...educations.map((education) => ({
                type: 'profile/content/education',
                [education.id ? 'id' : 'temp-id']: education.id
                  ? education.id
                  : hash(education),
                method: education.id ? 'update' : 'create',
              })),
              ...deletedIds.map((id) => ({
                type: 'profile/content/education',
                id,
                method: 'destroy',
              })),
            ],
          },
        },
      },
      included: educations.map((education) => ({
        type: 'profile/content/education',
        [education.id ? 'id' : 'temp-id']: education.id
          ? education.id
          : hash(education),
        attributes: {
          ...education,
          current: false,
        },
      })),
    },
    resolve,
    reject,
  );

export const askProfilePublication = ({ profileType }, resolve, reject) =>
  createResource(
    {
      resourceUri: `/${profileType}/publication`,
      data: {
        type: `profile/${profileType}`,
        attributes: {},
      },
      params: {
        include: ['promotions', 'profile_draft.company_sector'].join(','),
      },
    },
    resolve,
    reject,
  );

export const updateOnlineProfile = ({ profileType }, resolve, reject) =>
  updateResource(
    {
      resourceUri: `/${profileType}/publication`,
      data: {
        type: `profile/${profileType}`,
        attributes: {},
      },
    },
    resolve,
    reject,
  );

export const updateOrganizationLogo = (file, resolve, reject) => ({
  type: UPDATE_ORGANIZATION_LOGO,
  file,
  resolve,
  reject,
});

export const updateAddress = (address) => ({
  type: UPDATE_ADDRESS,
  address,
});

export const updateCoordinatesSuccess = ({ lat, lng }) => ({
  type: UPDATE_COORDINATES_SUCCESS,
  coordinates: { lat, lng },
});

export const updateCoordinatesError = (error) => ({
  type: UPDATE_COORDINATES_ERROR,
  error,
});

/**
 * [CREATE] Upload a promotion
 * @param {File} media
 * @param {string} profileId
 */
export const uploadNewPromotion = (media, profileId, resolve, reject) => {
  const body = new FormData();
  if (media) {
    body.append('promotion[multimedia]', media);
  }

  return uploadResource(
    {
      resourceUri: `/employees/${profileId}/promotions`,
      data: {
        type: ApiResource.EMPLOYEE_PROMOTION,
      },
      body,
    },
    resolve,
    reject,
  );
};

/**
 * Delete a promotion
 * @param {String} id - id of media to delete
 * @param {String} profileId
 */
export const deletePromotion = (id, profileId, resolve, reject) =>
  deleteResource(
    {
      resourceUri: `/employees/${profileId}/promotions/${id}`,
      data: {
        type: ApiResource.EMPLOYEE_PROMOTION,
      },
    },
    resolve,
    reject,
  );
