export const SET_COMPANY_ID = '@@identity/SET_COMPANY_ID';
export const SET_AUTH_TOKEN = '@@identity/SET_AUTH_TOKEN';
export const HYDRATE_IDENTITY = '@@identity/HYDRATE';

export const setCompanyId = (id) => ({
  type: SET_COMPANY_ID,
  id,
});

export const setAuthToken = (token) => ({
  type: SET_AUTH_TOKEN,
  token,
});

export const hydrateIdentity = (identity) => ({
  type: HYDRATE_IDENTITY,
  identity,
});
