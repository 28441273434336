import ApiResource from '../api-resources';
import { readResource } from './api';

export const READ_HEARTBEAT = '@@hermes/PING';

export const readHeartbeatResource = () =>
  readResource({
    resourceUri: '/heartbeats/new',
    data: { type: ApiResource.MONITORING_HEARTBEAT },
  });
