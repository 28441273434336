import { defineMessages } from 'react-intl';
import { t } from '../utils/translate';

const translations = defineMessages({
  sectorAudit: {
    id: 'enums.company.sectorAuditCounselling',
    defaultMessage: 'Audit, conseil',
    description: 'Page profile Ambassadeur - Secteur Audit, conseil',
  },
  sectorFinance: {
    id: 'enums.company.sectorFinance',
    defaultMessage: 'Banque, finance, assurance',
    description:
      'Page profile Ambassadeur - Secteur Banque, finance, assurance',
  },
  sectorEnergyEnvironmentConstruction: {
    id: 'enums.company.sectorEnergyEnvironmentConstruction',
    defaultMessage: 'Energie, environnement, construction',
    description:
      'Page profile Ambassadeur - Secteur Energie, environnement, construction',
  },
  sectorIT: {
    id: 'enums.company.sectorIT',
    defaultMessage: 'Informatique, web/app, médias',
    description:
      'Page profile Ambassadeur - Secteur Informatique, web/app, médias',
  },
  sectorProductionDistribution: {
    id: 'enums.company.sectorProductionDistribution',
    defaultMessage: 'Production, distribution',
    description: 'Page profile Ambassadeur - Secteur Production, distribution',
  },
  sectorTransportationEmbarked: {
    id: 'enums.company.sectorTransportationEmbarked',
    defaultMessage: 'Transports, systèmes embarqués',
    description:
      'Page profile Ambassadeur - Secteur Transports, systèmes embarqués',
  },
  sectorPublicOrganisation: {
    id: 'enums.company.sectorPublicOrganisation',
    defaultMessage: 'Secteur public, organisations',
    description:
      'Page profile Ambassadeur - Secteur Secteur public, organisations',
  },
  sectorEntertainment: {
    id: 'enums.company.sectorEntertainment',
    defaultMessage: 'Tourisme, divertissement',
    description: 'Page profile Ambassadeur - Secteur Tourisme, divertissement',
  },
  sectorLuxury: {
    id: 'enums.company.sectorLuxury',
    defaultMessage: 'Luxe',
    description: 'Page profile Ambassadeur - Secteur Luxe',
  },
  sectorOther: {
    id: 'enums.company.sectorOther',
    defaultMessage: 'Autre',
    description: 'Page profile Ambassadeur - Secteur Autre',
  },
  sectorUnknown: {
    id: 'enums.company.sectorUnknown',
    defaultMessage: 'Inconnu',
    description: 'Page profile Ambassadeur - Secteur Inconnu',
  },
  companySizesOnlyOne: {
    id: 'enums.company.companySizesOnlyOne',
    defaultMessage: '1 seul',
    description: 'Page profile Ambassadeur - Taille de compagny : 1 seul',
  },
  companySizesUnder10: {
    id: 'enums.company.companySizesUnder10',
    defaultMessage: 'Entre 2 et 10',
    description:
      'Page profile Ambassadeur - Taille de compagny : Entre 1 et 10',
  },
  companySizesUnder50: {
    id: 'enums.company.companySizesUnder50',
    defaultMessage: 'Entre 11 et 50',
    description:
      'Page profile Ambassadeur - Taille de compagny : Entre 11 et 50',
  },
  companySizesUnder200: {
    id: 'enums.company.companySizesUnder200',
    defaultMessage: 'Entre 51 et 200',
    description:
      'Page profile Ambassadeur - Taille de compagny : Entre 51 et 200',
  },
  companySizesUnder500: {
    id: 'enums.company.companySizesUnder500',
    defaultMessage: 'Entre 201 et 500',
    description:
      'Page profile Ambassadeur - Taille de compagny : Entre 201 et 500',
  },
  companySizesUnder1000: {
    id: 'enums.company.companySizesUnder1000',
    defaultMessage: 'Entre 501 et 1.000',
    description:
      'Page profile Ambassadeur - Taille de compagny : Entre 501 et 1.000',
  },
  companySizesUnder5000: {
    id: 'enums.company.companySizesUnder5000',
    defaultMessage: 'Entre 1.001 et 5.000',
    description:
      'Page profile Ambassadeur - Taille de compagny : Entre 1.001 et 5.000',
  },
  companySizesUnder10000: {
    id: 'enums.company.companySizesUnder10000',
    defaultMessage: 'Entre 5.001 et 10.000',
    description:
      'Page profile Ambassadeur - Taille de compagny : Entre 5.001 et 10.000',
  },
  companySizesOver10000: {
    id: 'enums.company.companySizesOver10000',
    defaultMessage: 'Plus de 10.000',
    description:
      'Page profile Ambassadeur - Taille de compagny : Plus de 10.000',
  },
  companySizesUnknown: {
    id: 'enums.company.companySizesUnknown',
    defaultMessage: 'Inconnu',
    description: 'Page profile Ambassadeur - Taille de compagny : Inconnu',
  },
  companyTypePME: {
    id: 'enums.company.companyTypePME',
    defaultMessage: 'Entre 1 et 50 employés',
    description: 'Enum des types d entreprise',
  },
  companyTypeMedium: {
    id: 'enums.company.companyTypeMedium',
    defaultMessage: 'Entre 51 et 1000 employés',
    description: 'Enum des types d entreprise',
  },
  companyTypeBigger: {
    id: 'enums.company.companyTypeBigger',
    defaultMessage: '+ de 1000 employés',
    description: 'Enum des types d entreprise',
  },

  leadSourceWordOfMouth: {
    id: 'enums.company.leadSourceWordOfMouth',
    defaultMessage: 'Bouche-à-oreille',
    description:
      'Option bouche à oreille dans le formulaire de contact entreprise',
  },
  leadSourceSearchEngine: {
    id: 'enums.company.leadSourceSearchEngine',
    defaultMessage: 'Moteur de recherche',
    description:
      'Option Moteur de recherche dans le formulaire de contact entreprise',
  },
  leadSourceSocialNetwork: {
    id: 'enums.company.leadSourceSocialNetwork',
    defaultMessage: 'Réseaux sociaux',
    description:
      'Option Réseaux sociaux dans le formulaire de contact entreprise',
  },
  leadSourceEvent: {
    id: 'enums.company.leadSourceEvent',
    defaultMessage: 'Événement',
    description: 'Option Événement dans le formulaire de contact entreprise',
  },
  leadSourceOther: {
    id: 'enums.company.leadSourceOther',
    defaultMessage: 'Autre',
    description: 'Option Autre dans le formulaire de contact entreprise',
  },
  seniorLevelOfExperience: {
    id: 'enums.company.seniorLevelOfExperience',
    defaultMessage: 'Senior',
    description: 'Option Autre dans le formulaire de contact entreprise',
  },
  intermediateLevelOfExperience: {
    id: 'enums.company.intermediateLevelOfExperience',
    defaultMessage: 'Confirmé',
    description: 'Option Autre dans le formulaire de contact entreprise',
  },
  juniorLevelOfExperience: {
    id: 'enums.company.juniorLevelOfExperience',
    defaultMessage: 'Junior',
    description: 'Option Autre dans le formulaire de contact entreprise',
  },
});

export const sectorsLabels = {
  audit_counselling: t(translations.sectorAudit),
  finance: t(translations.sectorFinance),
  energy_environment_construction: t(
    translations.sectorEnergyEnvironmentConstruction,
  ),
  it: t(translations.sectorIT),
  production_distribution: t(translations.sectorProductionDistribution),
  transportation_embarked: t(translations.sectorTransportationEmbarked),
  public_organisation: t(translations.sectorPublicOrganisation),
  entertainment: t(translations.sectorEntertainment),
  luxury: t(translations.sectorLuxury),
};

export const companySizes = {
  only_one: t(translations.companySizesOnlyOne),
  under_10: t(translations.companySizesUnder10),
  under_50: t(translations.companySizesUnder50),
  under_200: t(translations.companySizesUnder200),
  under_500: t(translations.companySizesUnder500),
  under_1000: t(translations.companySizesUnder1000),
  under_5000: t(translations.companySizesUnder5000),
  under_10000: t(translations.companySizesUnder10000),
  over_10000: t(translations.companySizesOver10000),
  unknown: t(translations.companySizesUnknown),
};

export const leadSource = {
  word_of_mouth: t(translations.leadSourceWordOfMouth),
  search_engine: t(translations.leadSourceSearchEngine),
  social_network: t(translations.leadSourceSocialNetwork),
  event: t(translations.leadSourceEvent),
  other: t(translations.leadSourceOther),
};

export const companyType = [
  {
    key: 'only_one,under10,under_50',
    label: t(translations.companyTypePME),
  },
  {
    key: 'under_200,under_500,under_1000',
    label: t(translations.companyTypeMedium),
  },
  {
    key: 'under_5000,under_10000,over_10000',
    label: t(translations.companyTypeBigger),
  },
];

export const levelOfExperiences = {
  junior: t(translations.juniorLevelOfExperience),
  intermediate: t(translations.intermediateLevelOfExperience),
  senior: t(translations.seniorLevelOfExperience),
};
