import {
  BUILD_INDEX,
  ADD_TO_INDEX,
  SET_INDEX_LOADING_STATE,
  FORCE_LAST_PAGE,
  SET_INDEX_ERROR,
} from '../actions/api-indexes';
import { arrayUniq } from '../utils/primitive/array';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case BUILD_INDEX:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          error: undefined,
          loading:
            typeof state[action.key] === 'boolean' ? state[action.key] : false,
          page: action.page || {},
          results: action.data,
        },
      };

    case ADD_TO_INDEX:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          error: undefined,
          loading:
            typeof state[action.key] === 'boolean' ? state[action.key] : false,
          page: action.page || {},
          results: arrayUniq([
            ...(state?.[action.key]?.results || []),
            ...action.data,
          ]),
        },
      };

    case FORCE_LAST_PAGE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          error: undefined,
          page: {
            ...state[action.key].page,
            numberOfPages: state?.[action.key]?.page?.currentPage,
          },
        },
      };

    case SET_INDEX_LOADING_STATE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          loading: action.state,
        },
      };

    case SET_INDEX_ERROR:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          error: action.error,
        },
      };

    default:
      return state;
  }
};
