import { select, put, takeEvery } from 'redux-saga/effects';
import { reportActionToAhoy } from '../actions/ahoy';
import { currentRouterPathnameSelector } from '../selectors/router';

/**
 * Trigger handleWatchToastr on every toastr action
 */
export function* watchToastrSaga() {
  yield takeEvery('@ReduxToastr/toastr/ADD', handleWatchToastr);
}

/**
 * trigger reportActionToAhoy for all toastr except success toastr
 * @param {Object} params
 * @param {Object} params.payload
 * @param {string} params.payload.title
 * @param {string} params.payload.type
 * @param {string} params.payload.message
 */
function* handleWatchToastr(params) {
  const pathname = yield select(currentRouterPathnameSelector);
  const { title, type, message } = params.payload;
  if (type !== 'success') {
    yield put(
      reportActionToAhoy('toastr', {
        title,
        type,
        message,
        current_url: pathname,
      }),
    );
  }
}
