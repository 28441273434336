export const CLOSE_CONNEXION = '@@realtime/CLOSE_CONNEXION';
export const CONNEXION_START = '@@realtime/CONNEXION_START';
export const CONNEXION_CLOSED = '@@realtime/CONNEXION_CLOSED';
export const RECEIVE_DATA = '@@realtime/RECEIVE_DATA';

export const closeConnexion = () => ({
  type: CLOSE_CONNEXION,
});

export const receiveData = (eventName, payload) => ({
  type: RECEIVE_DATA,
  eventName,
  payload,
});
