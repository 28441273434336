import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';
import { reduxForm, Field, Form } from 'redux-form';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Button } from 'react-bootstrap';
import isEmpty from 'lodash.isempty';
import LanguageField from '../language-field/LanguageField';
import { wrapDispatchWithPromise } from '../../utils/forms';
import { updateProfile } from '../../actions/profile';
import { currentUserWithDraftModeSelector } from '../../selectors';
import { toggleModal } from '../../actions/modals';
import ModalStatusForm from '../modal-status-form/ModalStatusForm';
import { t } from '../../utils/translate';

import './RequestLanguage.scss';
import { getUserMainProfile } from '../../utils/user';

const translations = defineMessages({
  languagesError: {
    id: 'RequestLanguage.languagesError',
    defaultMessage: 'Merci de choisir au moins une langue.',
    description: 'Erreur lors du choix des langues dans la modale',
  },
});

const validate = ({ languages }) => {
  if (isEmpty(languages)) {
    return {
      languages: t(translations.languagesError),
    };
  }
  return {};
};

const RequestLanguage = ({ handleSubmit, valid }) => (
  <Form
    onSubmit={handleSubmit}
    className="request-language-form"
  >
    <ModalStatusForm isValid={valid} />
    <FormattedMessage
      id="RequestLanguage.intro"
      defaultMessage="Quelles sont vos langues de rencontre possibles ?"
      description="Message d'introduction à pourquoi ajouter une langue"
    >
      {(txt) => <p className="request-language-intro">{txt}</p>}
    </FormattedMessage>
    <Field
      name="languages"
      component={LanguageField}
    />
    <Button
      bsStyle="primary"
      type="submit"
      disabled={!valid}
    >
      <FormattedMessage
        id="RequestLanguage.submitButton"
        defaultMessage="Enregistrer"
        description="Bouton pour enregistrer le choix des langues"
      />
    </Button>
  </Form>
);

RequestLanguage.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default compose(
  connect((state) => ({
    user: currentUserWithDraftModeSelector(state),
  })),
  withProps(({ user }) => ({
    profile: getUserMainProfile(user),
  })),
  withHandlers({
    onSubmit:
      ({ dispatch, user, profile }) =>
      (attributes) => {
        return wrapDispatchWithPromise(
          dispatch,
          updateProfile,
          {
            profileId: profile.id,
            userType: user.type,
            attributes,
          },
          {
            displayToastr: false,
          },
        ).then(() => {
          dispatch(toggleModal('requestLanguage', false));
        });
      },
  }),
  reduxForm({
    form: 'requestLanguage',
    validate,
  }),
)(RequestLanguage);
