import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { ProfileType } from '../../../../@types/jsonapi.d';
import { StorageEntries } from '../../../../@types/storage-entries';
import { removeInLocalStorage } from '../../../../actions/storage';
import usePersonna from '../../../../utils/hooks/usePersonna';
import Routes from '../../../../routes';
import useAffiliateToAssignment from './use-affiliate-to-assignment/useAffiliateToAssignment';
import { useAssignmentAffiliationToken } from './useAssignmentAffiliationToken';

export default function useAssignmentAffiliationMethods() {
  const dispatch = useDispatch();

  const [affiliateToAssignment] = useAffiliateToAssignment();
  const assignmentAffiliationToken = useAssignmentAffiliationToken();

  // identify the user
  const { type } = usePersonna();
  useEffect(() => {
    if (type === ProfileType.student && assignmentAffiliationToken) {
      affiliateToAssignment({
        variables: {
          assignmentID: assignmentAffiliationToken,
        },
        refetchQueries: ['GET_STUDENT_DASHBOARD_VIEW'],
      }).finally(() => {
        dispatch(
          removeInLocalStorage(StorageEntries.assignmentAffiliationToken),
        );
        dispatch(push(Routes.dashboard));
      });
    }
  }, [type, assignmentAffiliationToken]);
}
