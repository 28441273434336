import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal as toggleModalAction } from '../../actions/modals';
import RequestLanguageModal from '../../components/request-language-modal/RequestLanguageModal';
import UnpublishedModal from '../../components/unpublished-modal/UnpublishedModal';

function Modals() {
  const modals = useSelector((state) => state?.modals);
  const dispatch = useDispatch();
  function toggleModal(name, value, params) {
    dispatch(toggleModalAction(name, value, params));
  }
  return (
    <div>
      <RequestLanguageModal
        show={modals?.requestLanguage?.isOpened || false}
        onHide={() => toggleModal('requestLanguage', false)}
        {...(modals?.requestLanguage?.params || {})}
      />
      <UnpublishedModal
        show={modals?.unpublished?.isOpened || false}
        onHide={() => toggleModal('unpublished', false)}
        {...(modals?.unpublished?.params || {})}
      />
    </div>
  );
}

export default Modals;
