import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { withProps, compose, withHandlers } from 'recompose';

const UnpublishedByMJGModalContent = ({ email, contactMJG }) => (
  <div>
    <FormattedHTMLMessage
      id="UnpublishedByMJGModalContent.description"
      defaultMessage={
        '<span class="highlight">Votre profil My Job Glasses a été dépublié par l\'administration My Job Glasses.</span> Vous pouvez contacter {email} pour le republier.'
      }
      description="Message de description sur la depublication RH"
      values={{
        email,
      }}
    >
      {(txt) => (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: txt }}
        />
      )}
    </FormattedHTMLMessage>

    <div className="actions">
      <Button
        bsStyle="primary"
        onClick={contactMJG}
      >
        <FormattedMessage
          id="UnpublishedByMJGModalContent.contact"
          defaultMessage="Contacter My Job Glasses"
          description="button pour aller à la première conversation non répondu"
        />
      </Button>
    </div>
  </div>
);

UnpublishedByMJGModalContent.propTypes = {
  email: PropTypes.string.isRequired,
  contactMJG: PropTypes.func.isRequired,
};

export default compose(
  withProps(() => ({
    email: 'contact@myjobglasses.com',
  })),
  withHandlers({
    contactMJG:
      ({ email }) =>
      () => {
        window.location.href = `mailto:${email}`;
      },
  }),
)(UnpublishedByMJGModalContent);
