import querystring from 'querystring';
import forEach from 'lodash.foreach';
import normalize from 'json-api-normalizer';
import { take, takeEvery, call, put, select } from 'redux-saga/effects';
import {
  createResourceSuccess,
  createResourceError,
  readResourceSuccess,
  readResourceError,
  readListResourceSuccess,
  readListResourceError,
  updateResourceSuccess,
  updateResourceError,
  deleteResourceSuccess,
  deleteResourceError,
  uploadResourceError,
  uploadResourceSuccess,
  headRessourceSuccess,
  headRessourceError,
} from '../actions/api';
import {
  authenticationRequestTypes,
  authSuccessActionMapper,
  authErrorActionMapper,
} from '../actions/auth';
import ApiResource from '../api-resources';
import { utmSelector } from '../selectors/storable';
import { languageWithoutRegionCode } from '../locales/configure';
import { createRequestActionType } from '../utils/actions';
import { checkStatus, parseJSON, isUnconfirmedAccess } from '../utils/response';
import { requestUserEmailConfirmation } from './signin';

// eslint-disable-next-line
const apiUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_HOST;

const getResources = () => Object.values(ApiResource);

// @return headers for authentication
export function getHeaders() {
  const headers = {};

  headers['X-User-Language'] = languageWithoutRegionCode;

  return headers;
}

/**
 * Return utm for netizen
 * @returns {Object}
 */
export function* getUtm() {
  const utm = yield select(utmSelector);
  return utm;
}

export const request = (
  method,
  { id, resourceUri, data, meta, included, params, headers, body },
) =>
  new Promise((resolve, reject) => {
    let url = `${apiUrl}${resourceUri}`;
    const options = {
      method,
      headers,
      credentials: 'include', // Add cookies to forward ahoy js cookie
    };

    if (id) {
      url = `${url}/${id}`;
    }

    if (params) {
      url = `${url}?${querystring.stringify(params)}`;
    }

    // set body to request
    if (
      !body &&
      data &&
      (data.attributes || data.relationships || data.included)
    ) {
      options.body = JSON.stringify({ data, meta, included });
    } else if (body) {
      options.body = body;
    }

    // only set content type when no body
    if (!body) {
      options.headers['Content-Type'] = 'application/vnd.api+json';
    }

    fetch(url, options)
      .then(checkStatus)
      .then(method === 'HEAD' ? resolve : parseJSON)
      .then((data) => normalize(data, { endpoint: resourceUri })) // add endpoint param to be able to retrieve meta
      .then(resolve)
      .catch((error) => {
        error.response
          ? parseJSON(error.response)
              .then((err) => reject(err.errors))
              .catch((err) => reject(err.errors))
          : reject(error);
      });
  });

export const post = (params) => request('POST', params);
export const patch = (params) => request('PATCH', params);
export const get = (params) => request('GET', params);
export const head = (params) => request('HEAD', params);
export const del = (params) => request('DELETE', params);

export const newupload = (params) => request('POST', params);
export const upload = ({ method, uri, headers, body }) =>
  new Promise((resolve, reject) => {
    fetch(`${apiUrl}${uri}`, {
      method,
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(normalize)
      .then(resolve)
      .catch((error) => {
        error.response
          ? error.response.json().then((err) => reject(err.errors))
          : reject(error);
      });
  });

/*
 * Our API design
 *
 * Main REST API Design by Julien Lietart
 *   Idea : most of our API calls have either SUCCESS (2xx) / ERRORS (3xx 4xx 5xx+) response types
 *   (and this maps quite well to Promises .then() / .catch())
 *   In this case, the convention is that the saga takes {METHOD}_RESOURCE_{RESOURCE_NAME} actions
 *   (see getResources())
 *   And will dispatch either
 *     - a {METHOD}_RESOURCE_{RESOURCE_NAME}_SUCCESS action
 *     - a {METHOD}_RESOURCE_{RESOURCE_NAME}_ERROR action
 *   In this case we only need "Boolean/Promise-like" handlers
 *   We still need to register api resource types (see api-resources.js)
 *
 *   those are referred to as "Boolean" handlers
 *
 * Tweaks by CyrilDD
 *   Sometimes API calls are not expected to just be "boolean"
 *     - May return Conflicts, Duplication, redirections, etc.
 *     - May return polymorphic data: authentication => identity (Oauth) OR credentials (vanilla)
 *   In this case we need to
 *     - Manually map our API response to custom function that processes the response
 *     - For success  => const successActionMapper
 *     - For error => const errorActionMapper
 *   See an example of custom interceptors in actions/auth.js
 *
 *   Those are referred to as "Custom" handlers
 */

/* */
export function* createResource() {
  // Define all action types that should be intercepted by this sage
  const requestActionTypes = getResources()
    .map((r) => createRequestActionType('CREATE', r))
    .concat(authenticationRequestTypes);

  /* In case you need to intercept some success/errors and dispatch
   *   and dispatch a better variety of action (not just _SUCCESS / _ERROR)
   *   register the actions success/error in the arrays
   *`successActionMapper` and  `errorActionMapper`
   */

  while (true) {
    // eslint-disable-line
    const { resolve, reject, type, ...params } = yield take(requestActionTypes);

    // retrieve headers
    const headers = getHeaders();

    // add utm to query if exist
    const utm = yield* getUtm();
    enrichJsonapiPayloadWithUTM(params, utm);

    try {
      const response = yield call(post, { ...params, headers });
      yield call(createSuccessActionDispatch, type, response, params, resolve);
    } catch (error) {
      handleGlobalErrors(error);
      yield call(createErrorActionDispatch, type, error, params, reject);
    }
  }
}

export function* createSuccessActionDispatch(
  type,
  responseData,
  requestParams,
  resolve,
) {
  const successActionMapper = authSuccessActionMapper();
  if (successActionMapper[type]) {
    yield put(successActionMapper[type](type, responseData, requestParams));
  } else {
    yield put(
      createResourceSuccess({
        resourceType: requestParams.data.type,
        data: responseData,
        reset: requestParams.reset,
      }),
    );
  }

  // Used for reduxForm
  if (resolve) {
    resolve(responseData);
  }
}

export function* createErrorActionDispatch(
  type,
  responseError,
  requestParams,
  reject,
) {
  const errorActionMapper = authErrorActionMapper();
  if (errorActionMapper[type]) {
    yield put(errorActionMapper[type](responseError));
  } else {
    yield put(
      createResourceError({
        resourceType: requestParams.data.type,
        error: responseError,
      }),
    );
  }

  // Used for reduxForm
  if (reject) {
    reject(responseError);
  }
}

export function* readResource(params) {
  const { data } = params;

  try {
    const headers = getHeaders();
    const getData = yield call(get, { ...params, headers });

    yield put(
      readResourceSuccess({
        resourceType: data.type,
        data: getData,
        requestData: params,
      }),
    );
  } catch (error) {
    handleGlobalErrors(error);
    yield put(readResourceError({ resourceType: data.type, error }));
  }
}

export function* headResource(params) {
  const { data, resolve, reject } = params;

  try {
    const headers = getHeaders();
    const getData = yield call(head, { ...params, headers });

    yield put(
      headRessourceSuccess({
        resourceType: data.type,
        data: getData,
        requestData: params,
      }),
    );
    if (resolve) {
      resolve();
    }
  } catch (error) {
    handleGlobalErrors(error);
    yield put(headRessourceError({ resourceType: data.type, error }));
    if (reject) {
      reject(error);
    }
  }
}

export function* readListResource(params) {
  const { data, reset, resolve, reject } = params;

  try {
    const headers = getHeaders();
    const getData = yield call(get, { ...params, headers });

    yield put(
      readListResourceSuccess({
        resourceType: data.type,
        reset,
        data: getData,
        requestData: params,
      }),
    );
    if (resolve) {
      resolve();
    }
  } catch (error) {
    handleGlobalErrors(error);
    yield put(readListResourceError({ resourceType: data.type, error }));
    if (reject) {
      reject(error);
    }
  }
}

export function* updateResource(params) {
  const { data, resolve, reject } = params;

  try {
    // retrieve headers
    const headers = getHeaders();
    // add utm to query if exist
    const utm = yield* getUtm();
    enrichJsonapiPayloadWithUTM(params, utm);
    // perform request
    const postData = yield call(patch, { ...params, headers });

    yield put(
      updateResourceSuccess({
        resourceType: data.type,
        data: postData,
        requestData: data,
      }),
    );

    if (resolve) {
      resolve();
    }
  } catch (error) {
    handleGlobalErrors(error);
    yield put(updateResourceError({ resourceType: data.type, error }));

    if (reject) {
      reject(error);
    }
  }
}

export function* deleteResource(params) {
  const { data, resolve, reject } = params;

  try {
    const headers = getHeaders();
    const deleteData = yield call(del, { ...params, headers });

    yield put(
      deleteResourceSuccess({
        resourceType: data.type,
        data: deleteData,
      }),
    );
    typeof resolve === 'function' && resolve();
  } catch (error) {
    handleGlobalErrors(error);
    yield put(deleteResourceError({ resourceType: data.type, error }));
    typeof reject === 'function' && reject();
  }
}

export function* uploadResource(params) {
  const { data, resolve, reject } = params;
  try {
    // retrieve headers
    const headers = getHeaders();

    // add utm to query if exist
    const utm = yield* getUtm();
    enrichJsonapiPayloadWithUTM(params, utm);
    const uploadData = yield call(newupload, { ...params, headers });

    yield put(
      uploadResourceSuccess({
        resourceType: data.type,
        data: uploadData,
      }),
    );
    if (resolve) {
      resolve();
    }
  } catch (error) {
    handleGlobalErrors(error);
    yield put(uploadResourceError({ resourceType: data.type, error }));

    if (reject) {
      reject();
    }
  }
}

export function* watchReadResource() {
  try {
    const requestActionTypes = getResources().map((r) =>
      createRequestActionType('READ', r),
    );
    yield takeEvery(requestActionTypes, readResource);
  } catch (e) {
    console.log(e); // eslint-disable-line
  }
}

export function* watchReadListResource() {
  const requestActionTypes = getResources().map((r) =>
    createRequestActionType('READ_LIST', r),
  );
  yield takeEvery(requestActionTypes, readListResource);
}

export function* watchHeadResource() {
  try {
    const requestActionTypes = getResources().map((r) =>
      createRequestActionType('HEAD', r),
    );
    yield takeEvery(requestActionTypes, headResource);
  } catch (e) {
    console.log(e); // eslint-disable-line
  }
}

export function* watchUpdateResource() {
  const requestActionTypes = getResources().map((r) =>
    createRequestActionType('UPDATE', r),
  );
  yield takeEvery(requestActionTypes, updateResource);
}

export function* watchDeleteResource() {
  const requestActionTypes = getResources().map((r) =>
    createRequestActionType('DELETE', r),
  );
  yield takeEvery(requestActionTypes, deleteResource);
}

export function* watchUploadResource() {
  const requestActionTypes = getResources().map((r) =>
    createRequestActionType('UPLOAD', r),
  );
  yield takeEvery(requestActionTypes, uploadResource);
}

function handleGlobalErrors(error) {
  if (isUnconfirmedAccess(error)) {
    requestUserEmailConfirmation();
  }
}

/**
 * Add UTM to params directly by reference (can return it too)
 * @param {Object} params - with refence
 * @param {Object} utm - UTM to add to request
 */
const enrichJsonapiPayloadWithUTM = (params, utm) => {
  if (Object.keys(utm).length > 0) {
    if (params.body instanceof FormData) {
      forEach(Object.keys(utm), (key) => {
        params.body.append(`meta[utm][${key}]`, utm[key]);
      });
    } else if (params.meta) {
      params.meta = {
        ...params.meta,
        utm,
      };
    } else {
      params.meta = {
        utm,
      };
    }
  }
  return params;
};
