import { take, put, race } from 'redux-saga/effects';
import { INITIALIZE_SECTORS, getCompanySectors } from '../actions/company';
import {
  createSuccessActionType,
  createErrorActionType,
} from '../utils/actions';
import ApiResource from '../api-resources';

/**
 * Initilize sector on only one shot
 */
export function* ensureSectorListInitialized() {
  let success;
  while (!success) {
    yield take(INITIALIZE_SECTORS);
    yield put(getCompanySectors());
    ({ success } = yield race({
      success: take(
        createSuccessActionType('READ_LIST', ApiResource.COMPANY_SECTOR),
      ),
      error: take(
        createErrorActionType('READ_LIST', ApiResource.COMPANY_SECTOR),
      ),
    }));
  }
}
