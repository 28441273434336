export enum StorageEntries {
  assignmentAffiliationToken = 'assignmentAffiliationToken',
  mentorInvitationCode = 'mentor_invitation_code',
  credentials = 'credentials',
  user = 'user',
  persistedMessages = 'persisted-messages',
  me = 'me',
}

export interface LocalStorageMessage {
  id: string;
  text: string;
  fromMessageTemplateId?: string;
}
export interface StoragePersistedMessagesEntry {
  // conversation id
  [key: string]: Array<LocalStorageMessage>;
}
