import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { branch, renderComponent, compose } from 'recompose';
import ReactStars from 'react-stars';
import LikeCount from '../../../icons/LikeCount';
import viewBlue from '../../../icons/view-blue.png';

import './ProfileStats.scss';

const StudentProfileStats = ({ rating, totalRatings }) => (
  <div className="profile-stats">
    <>
      {rating && (
        <div className="rating">
          <div className="react-stars">
            <ReactStars
              count={5}
              value={rating}
              size={18}
              color2={'#09193b'}
              edit={false}
            />
          </div>
          <div className="rating-number">
            <FormattedMessage
              id="ProfileOverview.rating"
              defaultMessage="{rating}/5 - {totalRatings} avis"
              values={{ rating, totalRatings }}
            />
          </div>
        </div>
      )}
    </>
  </div>
);
StudentProfileStats.propTypes = {
  rating: PropTypes.number,
  totalRatings: PropTypes.number,
};

const ProfessionalProfileStats = ({ profileViews, likeCount }) => (
  <div className="profile-stats">
    {+profileViews > 0 && (
      <div className="profile-views">
        <img
          src={viewBlue}
          alt="profile-views"
        />
        <FormattedMessage
          id="ProfileOverview.profileViewsCount"
          defaultMessage={`{profileViews, plural,
                            =0 {# vue}
                            one {# vue}
                            other {# vues}
                          }`}
          values={{ profileViews }}
          description="Modale de contact"
        />
      </div>
    )}
    {+likeCount > 0 && (
      <div className="like-count">
        <LikeCount />
        <FormattedMessage
          id="ProfileOverview.likeCount"
          defaultMessage={`{likeCount, plural,
                          =0 {# Membre}
                          one {# Membre}
                          other {# Membres}
                        }`}
          values={{ likeCount }}
          description="Modale de contact"
        />
      </div>
    )}
  </div>
);
ProfessionalProfileStats.propTypes = {
  profileViews: PropTypes.number,
  likeCount: PropTypes.number,
};

export const ProfileStats = compose(
  branch(
    ({ isProfessional }) => isProfessional,
    renderComponent(StudentProfileStats),
  ),
)(ProfessionalProfileStats);
