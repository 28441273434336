import { gql } from '@apollo/client';

export const GET_CURRENT_USER_WANT_TO_SHARE = gql`
  query GetCurrentUserWantToShare {
    currentUserV2 {
      wantToShare
    }
  }
`;

export const CURRENT_USER_CONTEXT_QUERY = gql`
  query GetCurrentUserContext($completionContext: CompletionContextEnum!) {
    currentUserV2 {
      id

      intercomHash

      email
      lastName
      firstName
      birthdate
      avatars {
        url
      }

      mustConfirmEmail

      hibernations {
        id
        effectiveWakeAt
        reason
        requestedWakeAt
        scheduledStartAt
      }

      completionFor(completionContext: $completionContext) {
        percentage
        complete
      }
      memberCompletion: completionFor(completionContext: CONTACT_AMBASSADOR) {
        percentage
        complete
      }
      stepByStepCompletion: completionFor(completionContext: STEP_BY_STEP) {
        complete
      }
      ambassadorCompletion: completionFor(completionContext: REQUEST_PUBLICATION) {
        percentage
        complete
      }

      premiumAmbassador
      premiumSchoolAmbassador

      canShare
      canContact
      currentUserCanContact
      currentUserCanContactErrors

      professionalId
      studentId

      isCoach
      companyAdminProfiles {
        id
        company {
          id
          name
        }
      }
      isMjgAdmin
      isAmbassador
      bypassInteractionRules

      firstPublishedAt

      primarySituation {
        key
        name
      }

      secondarySituation {
        key
        name
      }

      accompaniedStudyLevels {
        key
        name
      }
      jobSeekerSupportPrograms
      messageExplanationDraft
      messagePresentationDraft

      publicationStatus
      availableConversationTypes {
        key
        name
      }
      newMessagesFromMembersCount
      newMessagesFromAmbassadorsCount

      createdAt
      updatedAt

      wishesCount

      locatedInLaReunion
    }
  }
`;
