import { gql } from '@apollo/client';

export const AFFILIATE_TO_ASSIGNMENT = gql`
  mutation AFFILIATE_TO_ASSIGNMENT($assignmentID: String!) {
    affiliateToAssignment(assignmentAffiliationToken: $assignmentID) {
      earlyAffiliationWarning
      assignmentActivityStartsAt
    }
  }
`;
