import camelCase from 'lodash.camelcase';
import { UPDATE_RESOURCE_STATE } from '../actions/resources-states';

const initialState = {};

/**
 * resourcesStates reducer
 * @param {Object} state - current state
 * @param {String} params.id - ID of the resource to save state
 * @param {String} action.resourceType - type of the resource
 * @param {Object} action.state - State of the resource
 * @param {String} action.state.code - Code of the status (eg: unpublished_professional)
 */
export default function resourcesStates(state = initialState, action) {
  switch (action.type) {
    case UPDATE_RESOURCE_STATE: {
      const resourceKey = camelCase(action.resourceType);
      return {
        ...state,
        [resourceKey]: {
          ...(state[resourceKey] || {}),
          [action.id]: action.state,
        },
      };
    }
    default:
      return state;
  }
}
