import React from 'react';
import { Link } from 'react-router-dom';
import LinkedinIcon from 'icons/component-icons/LinkedinIcon';
import FacebookIcon from 'icons/component-icons/FacebookIcon';
import YoutubeIcon from 'icons/component-icons/YoutubeIcon';
import InstagramIcon from 'icons/component-icons/InstagramIcon';
import XTwitterIcon from 'icons/component-icons/XTwitterIcon';

import styles from './SocialMediaLink.module.scss';

export default function SocialMediaLink() {
  return (
    <div className={styles.socialMediaLink}>
      <Link
        to={{ pathname: 'https://www.linkedin.com/company/my-job-glasses' }}
        target="_blank"
        className={styles.link}
      >
        <LinkedinIcon />
      </Link>
      <Link
        to={{ pathname: 'https://www.facebook.com/myjobglasses' }}
        target="_blank"
        className={styles.link}
      >
        <FacebookIcon />
      </Link>
      <Link
        to={{
          pathname: 'https://www.youtube.com/channel/UC2wiKrNrAX4QYg0ZyMFqLNw',
        }}
        target="_blank"
        className={styles.link}
      >
        <YoutubeIcon />
      </Link>
      <Link
        to={{ pathname: 'https://www.instagram.com/myjobglasses' }}
        target="_blank"
        className={styles.link}
      >
        <InstagramIcon />
      </Link>
      <Link
        to={{ pathname: 'https://twitter.com/myjobglasses' }}
        target="_blank"
        className={styles.link}
      >
        <XTwitterIcon />
      </Link>
    </div>
  );
}
