import { createResourceError } from '../actions/api';

const CREATE_RESOURCE_IDENTITY_SUCCESS =
  '@@api/CREATE_RESOURCE_IDENTITY_SUCCESS';

const errorLinkedinAlreadyTaken = (action) => ({
  resourceType: 'identity',
  error: [
    {
      data: action.data,
      status: 422,
      meta: {
        code: 'linkedin_taken',
      },
    },
  ],
});

/**
 * As explained in the bible, our app must not redirect a linkedin signin attemtp to a signup in case the account does not exist
 * (cf https://docs.google.com/document/d/1PMx4gHUEagp4BK7YwTllLocaNVDBWNQrcu8trQZaSto/edit#heading=h.7mumqq6ccxp2)
 * However, the Hermes API as it is now will always return an identity_id.
 * Therefore it's up to us to either transform this identity_id into a signin error (if the attempted action was a signin)
 * or go on with signup flow (if the attempted action was to signup)
 */
export const createLinkedinAuthMiddleware = () => () => (next) => (action) => {
  let actionReturned = action;

  if (
    action.type === CREATE_RESOURCE_IDENTITY_SUCCESS &&
    action.data.credentials
  ) {
    actionReturned = createResourceError(errorLinkedinAlreadyTaken(action));
  }
  return next(actionReturned);
};
