enum ApiResource {
  ABSENCE = 'absence',
  COMPANY = 'company',
  COMPANY_MOP = 'company/mop',
  COMPANY_SECTOR = 'company/sector',
  COMPANY_CONTACT_REQUEST = 'company/contact_request',
  CONVERSATION = 'conversation',
  CREDENTIALS = 'credentials',
  EMAIL = 'email',
  EMPLOYEE_PROFILE = 'profile/employee',
  EMPLOYEE_PROMOTION = 'employee/promotion',
  FACETS = 'facets',
  FEATURED = 'featured',
  PROFESSIONAL_EMPLOYEE = 'professional/employee',
  PROFESSIONAL_HIBERNATION = 'professional/hibernation',
  PROFILE_EMPLOYEE = 'profile/employee',
  PROFILE_MENTOR = 'profile/mentor',
  PROFILE_COMPANY_ADMIN = 'profile/company_admin',
  SCHOOL_TOKEN = 'profile/school_coach',
  PROFILE_CONTENT_EDUCATION = 'profile/content/education',
  PROFILE_INTERNSHIP = 'profile/internship',
  PROFILE_DESCRIPTION_PART = 'profile/description/part',
  PROFILE_DESCRIPTION_PARTS_MERGE = 'profile/description/part/merge',
  PROFILE_MANDATORY_SKILL = 'profile/mandatory/skill',
  RECOMMENDATION_RED_CARPET_ROLL = 'recommendation/red_carpet_roll',
  IDENTITY = 'identity',
  MENTOR_PROFILE = 'profile/mentor',
  MENTOR_ACQUISITION = 'mentor/acquisition',
  MENTOR_ACQUISITION_SIGNUP_TOKEN = 'mentor_acquisition/sign_up_token',
  MESSAGE_TEMPLATE = 'message_template',
  MESSAGING_CONVERSATION = 'messaging/conversation',
  MESSAGING_MESSAGE = 'messaging/message',
  MESSAGING_NOT_REPLIED_CONVERSATION = 'messaging/conversation/notreplied',
  MESSAGING_APPOINTMENT = 'messaging/appointment',
  MESSAGING_APPOINTMENT_REVIEW = 'messaging/appointment/review',
  MESSAGING_APPOINTMENT_CLAIM = 'messaging/appointment/claim',
  MESSAGING_APPOINTMENT_CLAIM_APPROVE = 'messaging/appointment/claim/approve',
  MESSAGING_APPOINTMENT_CLAIM_DENY = 'messaging/appointment/claim/deny',
  MODERATION_MENTOR = 'moderation/mentor',
  MODERATION_MENTOR_APPROVAL = 'moderation/mentor/approval',
  MODERATION_MENTOR_REJECTION = 'moderation/mentor/reject',
  MODERATION_MENTOR_STANDBY = 'moderation/mentor/standby',
  MODERATION_SCHOOL = 'moderation/school',
  MODERATION_SCHOOL_APPROVE = 'moderation/school/approval',
  MONITORING_HEARTBEAT = 'monitoring/heartbeat',
  PASSWORD = 'password',
  PAYMENT_SUBSCRIPTION = 'payment/subscription',
  PROFILE = 'profile',
  REPORT_STUDENT_BEHAVIOR = 'professional/student_report',
  RESET_PASSWORD = 'reset_password',
  CHANGE_PASSWORD = 'change_password_with_reset_token',
  REPORT_PROFESSIONAL_BEHAVIOR = 'student/professional_report',
  STUDENT_PROFILE = 'profile/student',
  STUDENT_WISH = 'student/wish',
  TAG = 'tag',
  USER = 'user',
  USER_SOFT_DELETION = 'user/soft_deletion',
  USER_CONFIRMATION = 'user/confirmation',
  USER_AVATAR_INFORMATIONS = 'user/avatar_informations',
  SIGNOUT = 'authenticated/signout',
}

export default ApiResource;
