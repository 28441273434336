import React from 'react';
import { UsernavItemProps } from '../../@types/Usernav';
import UsernavItemCompanyLink from './components/usernav-item-company-link/UsernavItemCompanyLink';
import UsernavItemExternalLink from './components/usernav-item-external-link/UsernavItemExternalLink';
import UsernavItemLink from './components/usernav-item-link/UsernavItemLink';
import UsernavItemSwitchAccount from './components/usernav-item-switch-account/UsernavItemSwitchAccount';
import UsernavItemSwitchExternalAccount from './components/usernav-item-switch-account/UsernavItemSwitchExternalAccount';

const UsernavItem = (props: UsernavItemProps) => {
  switch (props.type) {
    case 'company-link':
      return <UsernavItemCompanyLink {...props} />;
    case 'external-link':
      return <UsernavItemExternalLink {...props} />;
    case 'link':
      return <UsernavItemLink {...props} />;
    case 'switch-account':
      return <UsernavItemSwitchAccount {...props} />;
    case 'external-switch':
      return <UsernavItemSwitchExternalAccount {...props} />;
    default:
      return null;
  }
};

export default UsernavItem;
