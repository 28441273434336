import uniqid from 'uniqid';
import pluralize from 'pluralize';
import {
  JsonApiBaseProfileAttributes,
  JsonApiCommunicationProfileAttributes,
  JsonApiMappedInternship,
  JsonApiProfileContentEducationAttributes,
  ProfileType,
} from '../../../@types/jsonapi.d';
import { updateResource } from '../../../actions/api';
import ApiResource from '../../../api-resources';

export const UPDATE_PROFILE_ATTRIBUTES = '@@profile/UPDATE_PROFILE_ATTRIBUTES';
export const UPDATE_PROFILE_EDUCATIONS = '@@profile/UPDATE_PROFILE_EDUCATIONS';
export const DELETE_PROFILE_EDUCATIONS = '@@profile/DELETE_PROFILE_EDUCATIONS';
export const UPDATE_PROFILE_INTERNSHIPS =
  '@@profile/UPDATE_PROFILE_INTERNSHIPS';
export const DELETE_PROFILE_INTERNSHIPS =
  '@@profile/DELETE_PROFILE_INTERNSHIPS';
export const HIDE_HOW_DOES_IT_WORKS_FROM_EDIT_PROFILE_VIEW =
  '@@profile/HIDE_HOW_DOES_IT_WORKS_FROM_EDIT_PROFILE_VIEW';

export type UpdateProfileAttributesValues =
  Partial<JsonApiBaseProfileAttributes> & {
    location?: {
      placeId: string;
      address: string;
      lng: Number;
      lat: Number;
    };
    profileId: string;
    profileType: keyof typeof ProfileType;
  };
export interface UpdateProfileAttributesActions {
  type: string;
  values: UpdateProfileAttributesValues;
  resolve: Function;
  reject: Function;
}
export const updateProfileAttributes = (
  values: UpdateProfileAttributesValues,
  resolve: Function,
  reject: Function,
): UpdateProfileAttributesActions => ({
  type: UPDATE_PROFILE_ATTRIBUTES,
  values,
  resolve,
  reject,
});

interface UpdateProfileResourceParams {
  userType: keyof typeof ProfileType;
  profileId: String;
  attributes: JsonApiCommunicationProfileAttributes;
}
export const updateProfileResource = ({
  userType,
  profileId,
  attributes,
}: UpdateProfileResourceParams) =>
  updateResource({
    resourceUri: `/${pluralize(userType)}/${profileId}`,
    data: {
      type: `profile/${userType}`,
      attributes,
    },
  });

interface UpdateProfileEducationsParams
  extends JsonApiProfileContentEducationAttributes {
  profileId: string;
  profileType: ProfileType;
}
export const updateProfileEducations = (
  {
    id,
    schoolName,
    diplomaType,
    diplomaTitle,
    startedAt,
    endedAt,
    profileId,
    profileType,
  }: UpdateProfileEducationsParams,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) => ({
  type: UPDATE_PROFILE_EDUCATIONS,
  values: {
    id,
    schoolName,
    diplomaType,
    diplomaTitle,
    startedAt,
    endedAt,
    profileId,
    profileType,
  },
  resolve,
  reject,
});

/**
 * Request remove of a profile education
 */
export const deleteProfileEducation = (
  resourceId: string,
  profileId: string,
  profileType: ProfileType,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) => ({
  type: DELETE_PROFILE_EDUCATIONS,
  resourceId,
  profileId,
  profileType,
  resolve,
  reject,
});

/**
 * Add/Update a profile education
 */
interface UpdateProfileEducationResourceParams {
  education: JsonApiProfileContentEducationAttributes;
  profileId: string;
  userType: ProfileType;
}
export const updateProfileEducationResource = (
  { education, profileId, userType }: UpdateProfileEducationResourceParams,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) => {
  const id = education.id || uniqid();
  return updateResource(
    {
      resourceUri: `/${pluralize(userType)}/${profileId}`,
      data: {
        type: ApiResource.PROFILE_CONTENT_EDUCATION,
        relationships: {
          educations: {
            data: [
              {
                type: 'profile/content/education',
                [education.id ? 'id' : 'temp-id']: id,
                method: education.id ? 'update' : 'create',
              },
            ],
          },
        },
      },
      included: [
        {
          type: 'profile/content/education',
          [education.id ? 'id' : 'temp-id']: id,
          attributes: {
            ...education,
            current: false,
          },
        },
      ],
    },
    resolve,
    reject,
  );
};

/**
 * Delete a profile education by the resource ID
 */
interface DeleteProfileEducationResourceParams {
  userType: ProfileType;
  profileId: string;
  deleteResourceId: string;
}
export const deleteProfileEducationResource = (
  {
    userType,
    profileId,
    deleteResourceId,
  }: DeleteProfileEducationResourceParams,
  resolve?: PromiseResolveFn,
  reject?: PromiseRejectFn,
) =>
  updateResource(
    {
      resourceUri: `/${pluralize(userType)}/${profileId}`,
      data: {
        type: ApiResource.PROFILE_CONTENT_EDUCATION,
        relationships: {
          educations: {
            data: [
              {
                id: deleteResourceId,
                type: 'profile/content/education',
                method: 'destroy',
              },
            ],
          },
        },
      },
    },
    resolve,
    reject,
  );

/**
 * Request hide of how it works banner
 */
export const hideHowDoesItWorksFromEditProfileView = () => ({
  type: HIDE_HOW_DOES_IT_WORKS_FROM_EDIT_PROFILE_VIEW,
});

interface UpdateProfileInternshipsParams extends JsonApiMappedInternship {
  profileId: string;
}
export const updateProfileInternships = (
  {
    id,
    status,
    type,
    duration,
    startsAtMonth,
    startsAtYear,
    description,
    profileId,
  }: UpdateProfileInternshipsParams,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) => ({
  type: UPDATE_PROFILE_INTERNSHIPS,
  values: {
    id,
    status,
    type,
    duration,
    startsAtMonth,
    startsAtYear,
    description,
    profileId,
  },
  resolve,
  reject,
});

/**
 * Add/Update a profile internship
 */
interface UpdateProfileInternshipsResourceParams {
  internship: JsonApiMappedInternship;
}
export const updateProfileInternshipsResource = (
  profileId: string,
  { internship }: UpdateProfileInternshipsResourceParams,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) => {
  const id = internship.id || uniqid();
  return updateResource(
    {
      resourceUri: `/students/${profileId}`,
      data: {
        type: ApiResource.PROFILE_INTERNSHIP,
        relationships: {
          internships: {
            data: [
              {
                type: 'profile/internship',
                [internship.id ? 'id' : 'temp-id']: id,
                method: internship.id ? 'update' : 'create',
              },
            ],
          },
        },
      },
      included: [
        {
          type: 'profile/internship',
          [internship.id ? 'id' : 'temp-id']: id,
          attributes: {
            ...internship,
          },
        },
      ],
    },
    resolve,
    reject,
  );
};

/**
 * Request remove of a profile internship
 */
export const deleteProfileInternship = (
  resourceId: string,
  profileId: string,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) => ({
  type: DELETE_PROFILE_INTERNSHIPS,
  resourceId,
  profileId,
  resolve,
  reject,
});

/**
 * Delete a profile internship by the resource ID
 * @param {Object} params
 * @param {String} params.deleteResourceId
 * @param {Function} resolve
 * @param {Function} reject
 */
interface DeleteProfileInternshipResourceParams {
  deleteResourceId: string;
}
export const deleteProfileInternshipResource = (
  profileId: string,
  { deleteResourceId }: DeleteProfileInternshipResourceParams,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
) =>
  updateResource(
    {
      resourceUri: `/students/${profileId}`,
      data: {
        type: ApiResource.PROFILE_INTERNSHIP,
        relationships: {
          internships: {
            data: [
              {
                id: deleteResourceId,
                type: 'profile/internship',
                method: 'destroy',
              },
            ],
          },
        },
      },
      included: [
        {
          type: 'profile/internship',
          [deleteResourceId ? 'id' : 'temp-id']: deleteResourceId,
        },
      ],
    },
    resolve,
    reject,
  );
