import { createSelector } from 'reselect';
import {
  allProfilesSelector,
  usersSelector,
  userSelector,
  messagingAppointmentsSelector,
} from '../../../../selectors';
import { isStudent } from '../../../../utils/user';
import { findRelationship } from '../../../../utils/json-api';
import { getReviewee } from '../utils/review';

/**
 *
 * @param {Object} state
 */
export const messagingAppointmentClaimsSelector = ({
  api: { messagingAppointmentClaims },
}) => messagingAppointmentClaims || [];

/**
 *
 * @param {Object} state
 */
export const messagingConversationsSelector = ({
  api: { messagingConversations },
}) => messagingConversations || [];

/**
 * Return ONE appointment to review
 * @param {String} id
 * @return {Object}
 */
export const appointmentToReviewSelector = (id) =>
  createSelector(
    userSelector,
    usersSelector,
    allProfilesSelector,
    messagingAppointmentsSelector,
    messagingConversationsSelector,
    (currentUser, users, profiles, appointments, conversations) => {
      if (appointments.length === 0) {
        return {};
      }

      let appointmentToReview = {};
      const appointment = appointments.find((a) => a.id === id);

      const conversation = findRelationship(
        appointment,
        conversations,
        'conversation',
      );
      const initiator = findRelationship(conversation, users, 'initiator');
      const initiatorProfile = findRelationship(
        conversation,
        profiles,
        'initiatorProfile',
      );
      const recipient = findRelationship(conversation, users, 'recipient');
      const recipientProfile = findRelationship(
        conversation,
        profiles,
        'recipientProfile',
      );

      const reviewee = getReviewee(
        { initiator, initiatorProfile },
        { recipient, recipientProfile },
        currentUser,
      );

      if (![appointment, conversation, reviewee].includes(null)) {
        appointmentToReview = {
          id: appointment.id,
          ...appointment.attributes,
          conversation,
          reviewer: currentUser,
          reviewee,
        };
      }

      return appointmentToReview;
    },
  );

/**
 * return all appointments to review
 * @return {Array}
 */
export const appointmentsToReviewSelector = createSelector(
  userSelector,
  usersSelector,
  allProfilesSelector,
  messagingAppointmentsSelector,
  messagingAppointmentClaimsSelector,
  messagingConversationsSelector,
  (
    currentUser,
    users,
    profiles,
    appointments,
    appointmentClaims,
    conversations,
  ) => {
    if (appointments.length === 0 && appointmentClaims.length === 0) {
      return [];
    }

    const userType = isStudent(currentUser)
      ? 'reviewFromStudent'
      : 'reviewFromProfessional';

    const appointmentToReview = [...appointments, ...appointmentClaims]
      // eslint-disable-next-line arrow-body-style
      .filter((appointment) => {
        return (
          appointment.attributes[userType] &&
          appointment.attributes[userType].mustReview
        );
      })
      .reduce((acc, appointment) => {
        const conversation = findRelationship(
          appointment,
          conversations,
          'conversation',
        );
        const initiator = findRelationship(conversation, users, 'initiator');
        const initiatorProfile = findRelationship(
          conversation,
          profiles,
          'initiatorProfile',
        );
        const recipient = findRelationship(conversation, users, 'recipient');
        const recipientProfile = findRelationship(
          conversation,
          profiles,
          'recipientProfile',
        );

        const reviewee = getReviewee(
          { initiator, initiatorProfile },
          { recipient, recipientProfile },
          currentUser,
        );

        if (
          ![conversation, reviewee].includes(null) &&
          !reviewee?.isAnonymized
        ) {
          acc.push({
            id: appointment.id,
            ...appointment.attributes,
            conversation,
            reviewer: currentUser,
            reviewee,
          });
        }
        return acc;
      }, []);

    return appointmentToReview;
  },
);

/**
 * return all appointments to review (ambivalence)
 * @return {Array}
 */
export const appointmentsToReviewAmbivalenceSelector = createSelector(
  userSelector,
  usersSelector,
  allProfilesSelector,
  messagingAppointmentsSelector,
  messagingAppointmentClaimsSelector,
  messagingConversationsSelector,
  (
    currentUser,
    users,
    profiles,
    appointments,
    appointmentClaims,
    conversations,
  ) => {
    const appointmentToReview = [...appointments, ...appointmentClaims].reduce(
      (acc, appointment) => {
        const conversation = findRelationship(
          appointment,
          conversations,
          'conversation',
        );
        const initiator = findRelationship(conversation, users, 'initiator');
        const initiatorProfile = findRelationship(
          conversation,
          profiles,
          'initiatorProfile',
        );
        const recipient = findRelationship(conversation, users, 'recipient');
        const recipientProfile = findRelationship(
          conversation,
          profiles,
          'recipientProfile',
        );

        const reviewee = getReviewee(
          { initiator, initiatorProfile },
          { recipient, recipientProfile },
          currentUser,
        );

        if (
          ![conversation, reviewee].includes(null) &&
          !reviewee?.isAnonymized
        ) {
          acc.push({
            id: appointment.id,
            ...appointment.attributes,
            conversation,
            reviewer: currentUser,
            reviewee,
          });
        }
        return acc;
      },
      [],
    );

    return appointmentToReview;
  },
);
