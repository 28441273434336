import { HYDRATE_USER } from '../actions/user';

const initialState = {
  user: null,
};

export default function shared(state = initialState, action) {
  switch (action.type) {
    case HYDRATE_USER:
      return {
        ...state,
        user: action.user,
      };

    case '@@api/READ_RESOURCE_MESSAGING_CONVERSATION_SUCCESS':
      return {
        ...state,
        conversationId: Object.keys(action.data.messagingConversation || {})[0],
      };

    default:
      return state;
  }
}
