import { push } from 'connected-react-router';
import { defineMessages } from 'react-intl';
import { put, select, take } from 'redux-saga/effects';
import { toggleModal, TOGGLE_MODAL } from '../actions/modals';
import { modalSelector } from '../selectors';
import { needFillLanguageSelector } from '../selectors/profile';
import { isNecessaryToCropAvatarSelector } from '../selectors/user';
import Routes from '../routes';

export const signinSagaTranslations = defineMessages({
  errorCannotReconnect: {
    id: 'toastr.signin.errorCannotReconnect',
    defaultMessage: "Nous n'avons pas pu vous reconnecter",
    description: 'Erreur de reconnexion',
  },
  userEmailConfirmationError: {
    id: 'toastr.signin.userEmailConfirmationError',
    defaultMessage:
      'Désolé, une erreur est survenue. Veuillez essayer à nouveau.',
    description: 'Message lors de la demande de renvoi du mail de confirmation',
  },
  errorCropAvatarToastr: {
    id: 'toastr.signin.errorCropAvatar',
    defaultMessage:
      'Vous avez rencontré une erreur lors du recadrage de votre photo. Veuillez réessayer ou nous contacter.',
    description:
      'Message d erreur lorsque l utilisateur echoue à cropper son avatar',
  },
  oauthEmailAlreadyUsed: {
    id: 'toastr.signin.oauthEmailAlreadyUsed',
    defaultMessage:
      'Un compte existe déjà avec votre adresse email LinkedIn. Merci de vous y connecter avec votre mot de passe',
    description: 'Error when user not found',
  },
});

/**
 * request to user to confirm his email before resend confirmation email
 */
export function requestUserEmailConfirmation() {
  // eslint-disable-next-line
  if (window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL) {
    // eslint-disable-next-line
    window.location.href =
      window.__RUNTIME_CONFIG__.REACT_APP_ACCOUNT_FRONTEND_URL;
  }
}

/**
 * Display requestLanguage modal if necessary
 */
export function* requestIfNecessaryLanguages() {
  const modal = yield select(modalSelector, 'signup');
  const isSignupModalOpened = modal?.isOpened;
  if (isSignupModalOpened) {
    return;
  }
  const needFillLanguage = yield select(needFillLanguageSelector);
  if (needFillLanguage) {
    yield put(toggleModal('requestLanguage', true));
    yield take(TOGGLE_MODAL);
  }
}

/**
 * Check if attributes mustResizeAvatar is set on user
 * Then, start to request avatar to user
 */
export function* requestIfNecessaryAvatarCropInformations() {
  const isNecessaryToCrop = yield select(isNecessaryToCropAvatarSelector);
  if (isNecessaryToCrop) {
    yield put(push(Routes.profileAvatarEdit));
  }
}
