import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose, branch, renderComponent, renderNothing } from 'recompose';
import { userSelector } from '../../selectors';
import ModalStatusForm from '../modal-status-form/ModalStatusForm';
import inCrisisWithIcedUpModalContent from './inCrisisWithIcedUpModalContent';
import InWarmupWithIcedUpModal from './InWarmupWithIcedUpModal';
import UnpublishedByRHModalContent from './UnpublishedByRHModalContent';
import UnpublishedByMJGModalContent from './UnpublishedByMJGModalContent';

import './UnpublishedModal.scss';

const modalLayout = (type) => (WrappedComponent) => {
  /* eslint-disable react/display-name, react/prop-types */
  return ({ show, onHide, user, ...props }) => (
    <Modal
      id="unpublished-modal"
      show={show}
      onHide={onHide}
      dialogClassName={`unpublished-modal unpublished-modal-${type}`}
    >
      <Modal.Header>
        <FormattedMessage
          id="UnpublishedModal.hi"
          defaultMessage="Bonjour {firstname}"
          description="Message de bonjour dans la modal de gel (echauffement)"
          values={{
            firstname: user && user.firstName,
          }}
        >
          {(txt) => <p className="greeting-phrase">{txt}</p>}
        </FormattedMessage>
        <FormattedMessage
          id="UnpublishedModal.unpublished"
          defaultMessage="Votre profil n'est plus publié"
          description="Message d information sur la dépublication"
        >
          {(txt) => <p className="unpublished-phrase">{txt}</p>}
        </FormattedMessage>
        <ModalStatusForm isValid={false} />
      </Modal.Header>
      <Modal.Body>
        <WrappedComponent
          user={user}
          {...props}
        />
      </Modal.Body>
    </Modal>
  );
  /* eslint-enable */
};

export const UnpublishModalContent = compose(
  branch(
    ({ inWarmup, icedUp }) => inWarmup && icedUp,
    renderComponent(modalLayout('inwarmup')(InWarmupWithIcedUpModal)),
  ),
  branch(
    ({ inCrisis, icedUp }) => inCrisis && icedUp,
    renderComponent(modalLayout('incrisis')(inCrisisWithIcedUpModalContent)),
  ),
  branch(
    ({ unpublished, byRH }) => unpublished && byRH,
    renderComponent(
      modalLayout('unpublished-by-rh')(UnpublishedByRHModalContent),
    ),
  ),
  branch(
    ({ unpublished, byMJG }) => unpublished && byMJG,
    renderComponent(
      modalLayout('unpublished-by-mjg')(UnpublishedByMJGModalContent),
    ),
  ),
)(renderNothing());

export default compose(
  connect((state) => ({
    user: userSelector(state),
  })),
  branch(
    ({ inWarmup, inCrisis, icedUp, unpublished, byMJG, byRH }) =>
      !((icedUp && (inWarmup || inCrisis)) || (unpublished && (byMJG || byRH))),
    renderNothing,
  ),
)(UnpublishModalContent);
