import { nfd } from 'unorm';
import snakeCase from 'lodash.snakecase';

/**
 * Only return not empty key/value
 * @param {Object} obj
 */
export const clearObjectBlankValues = (obj) => {
  if (!obj) {
    return {};
  }
  const processedObject = Object.assign({}, obj); // prevent from modify object by reference
  Object.keys(processedObject).forEach(
    (key) => processedObject[key] == null && delete processedObject[key],
  );
  return processedObject;
};

/**
 * Fallback on callback if intercom widget blocked
 * @param {Function} callback - Callback to be called if we detect that the intercom widget was blocked by adblocker
 */
export const handleBlockedIntercomWidget = (callback) => {
  new Promise((resolve, reject) => {
    window.Intercom('onShow', () => {
      resolve();
    });
    setTimeout(() => {
      reject();
    }, 1000);
  }).catch(() => callback());
};

/**
 * Base64 encode a string with Unicode compliance
 * @param {String} str
 * @source https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
 */
export const b64EncodeUnicode = (str) =>
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode('0x' + p1),
    ),
  );

/**
 * Base64 decode a string with Unicode compliance
 * @param {String} str
 * @source https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
 */
export const b64DecodeUnicode = (str) =>
  // Going backwards: from bytestream, to percent-encoding, to original string.
  decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

/**
 * Get only uniq items from an array
 * To be used with Array.proptotypes.filter()
 * @param {*} elem - current element
 * @param {Integer} pos - position of current element
 * @param {Array} arr - reference to array on which perform uniqueness
 */
export const uniqFilterPattern = (elem, pos, arr) => arr.indexOf(elem) === pos;

/**
 * Normalize a string without accents
 * @param {String} str
 * Use NFD to ungroup char composition
 * then we remove all composition parameters (like ^, ¨, or `)
 * @url http://unicode.org/reports/tr15/
 * @example normalizeString('éléphantôt') = 'elephantot'
 */
export const normalizeString = (str) =>
  nfd(str).replace(/[\u0300-\u036f]/g, '');

/**
 * Transform keys from an object to camelcase
 * @param {Object} obj
 * @returns {Object}
 */
export const snakeCaseObjectKeys = (obj) => {
  const newObj = {};
  for (let key in obj) {
    newObj[snakeCase(key)] = obj[key];
  }
  return newObj;
};

/**
 * Check if object is empty {}
 * @param {Object} object
 * @return {Boolean}
 */
export const isNotEmptyObject = (object) => {
  return Object.entries(object).length > 0;
};

/**
 * return element value from position
 * @param {Number} position
 * @param {Object} object
 * @return {any}
 */
export const objectValueFromPosition = (position, object) => {
  return Object.values(object)[position];
};

/**
 * @param {Array} value
 * @param {Number} indexOfValue
 * @returns {Array}
 */
export const arrayRemoveItemByIndex = (value, indexOfValue) => {
  if (indexOfValue === -1) {
    return value;
  }
  return value.slice(0, indexOfValue).concat(value.slice(indexOfValue + 1));
};
