export const AUTO_SIGNIN = '@@signin/AUTO';
export const AUTO_SIGNIN_SUCCESS = '@@signin/AUTO_SUCCESS';

/* Authentication credentials can be
 * - { flavor: 'vanilla', email, password } (vanilla Auth)
 * - { flavor, code } (oauth 2 Auth code)
 * - { flavor: 'identity', email, identity_id } (identity Auth)
 *
 * Other special params can be passed
 * - { authFlow } indicates whether this comes from signin or signup
 * - { flow } indicates which type of user is authenticating
 * - { context } is an agnostic contect object that can be used to identity
 *   special steps (such as signin required before doing this or that action)
 * (especially used for determining signup endpoint & flow logic)

/* Action that represents a vanilla sign in with user + pw */
export const autoSignin = ({
  email,
  password,
  code,
  flow,
  identity_id,
  authFlow,
  flavor,
  context,
}) => ({
  type: AUTO_SIGNIN,
  email: email.toLowerCase(),
  password,
  code,
  identity_id,
  flow,
  flavor,
  authFlow,
  context,
});

/**
 *
 * @param {Object} params
 * @param {String} params.flow
 * @param {String} params.flavor
 * @param {String} params.context
 * @param {String} params.authFlow
 */
export const autoSigninSuccess = ({ flow, flavor, context, authFlow }) => ({
  type: AUTO_SIGNIN_SUCCESS,
  context,
  flow,
  flavor,
  authFlow,
});
