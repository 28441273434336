export const JUST_SIGNED_UP_CONTEXT = '*context/JUST_SIGNED_UP';

export const CONTACTING_PRO_CONTEXT = '*context/CONTACTING_PRO';
export const VISITING_PROFILE_PAGE_CONTEXT = '*context/VISITING_PROFILE_PAGE';
export const TRACK_VIEWED_PROFESSIONAL = '*context/TRACK_VIEWED_PROFESSIONAL';
export const TRACK_VIEWED_OWN_PROFESSIONAL_PROFILE =
  '*context/TRACK_VIEWED_OWN_PROFESSIONAL_PROFILE';

export const VIEWING_SEARCH_RESULTS_CONTEXT = '*context/VIEWING_SEARCH_RESULTS';

export const STAY_ON_CURRENT_PAGE = '*context/STAY_ON_CURRENT_PAGE';
export const VIEW_PROFESSIONAL_FROM_SIMPROS =
  '*context/VIEW_PROFESSIONAL_FROM_SIMPROS';
