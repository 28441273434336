import {
  createErrorActionType,
  createSuccessActionType,
  createRequestActionType,
} from '../utils/actions';
import { SET_SEARCH_LOADED_STATE } from '../actions/search';
import { SET_CONTAINER_LOAD_STATE } from '../actions/container-load-state';

const defaultState = {
  Conversations: {
    loading: false,
  },
  Search: {
    loaded: false,
  },
};

/*
 * [CHORE] Regroupper ici les loadings des pages
 * @url https://github.com/Startouf/MyJobGlasses/issues/3196
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    // List of conversations page
    case createRequestActionType('READ_LIST', 'messaging/conversation'):
      return {
        ...state,
        Conversations: {
          ...state.Conversations,
          loading: true,
        },
      };
    case createSuccessActionType('READ_LIST', 'messaging/conversation'):
    case createErrorActionType('READ_LIST', 'messaging/conversation'):
      return {
        ...state,
        Conversations: {
          ...state.Conversations,
          loading: false,
        },
      };

    // Search
    case SET_SEARCH_LOADED_STATE:
      return {
        ...state,
        Search: {
          ...state.Search,
          loaded: action.state,
        },
      };

    case SET_CONTAINER_LOAD_STATE:
      return {
        ...state,
        [action.containerName]: action.state,
      };

    // default section
    default:
      return state;
  }
};
