import { useState, useEffect } from 'react';
import qs from 'querystring';
import trimStart from 'lodash.trimstart';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { localStorageSelector } from '../../../../selectors/storable';
import { FDRRouteParams } from '../../../../@types/routes-params.d';
import { StorageEntries } from '../../../../@types/storage-entries';

export function useUrlAssignmentAffiliationToken(): string {
  const [token, setToken] = useState<string>('');

  // handle affiliation token on url
  const { search } = useLocation();
  useEffect(() => {
    const urlSearchParams: FDRRouteParams = qs.parse(trimStart(search, '?'));
    if (urlSearchParams?.assignment_affiliation_token) {
      setToken(urlSearchParams?.assignment_affiliation_token);
    }
  }, [search]);
  return token;
}

export function useAssignmentAffiliationToken(): string {
  const [token, setToken] = useState<string>('');

  // handle affiliation token on url
  const urlToken = useUrlAssignmentAffiliationToken();
  useEffect(() => {
    if (urlToken) {
      setToken(urlToken);
    }
  }, [urlToken]);

  // handle saved token
  const savedToken = useSelector(
    localStorageSelector(StorageEntries.assignmentAffiliationToken),
  );
  useEffect(() => {
    if (savedToken) {
      setToken(savedToken);
    }
  }, [savedToken]);

  return token;
}
