import find from 'lodash.find';
import moment from 'moment-timezone';
import {
  AppointmentsStatus,
  JsonApiMappedConversation,
  JsonApiMappedUser,
  JsonApiAppointment,
  JsonApiMappedAppointment,
  JsonApiMappedAppointmentClaim,
  JsonApiConversation,
  JsonApiBaseUser,
  JsonApiBaseProfile,
} from '../@types/jsonapi.d';
import { flattenAttributes, findRelationship } from './json-api';
import { mapConversation } from './conversation';

export function mapAppointment(
  appointment: JsonApiAppointment,
  conversations?: JsonApiConversation[],
  messages?: [],
  users?: JsonApiBaseUser[],
  profiles?: JsonApiBaseProfile[]
): JsonApiMappedAppointment {
  return {
    ...flattenAttributes<JsonApiAppointment, JsonApiMappedAppointment>(
      appointment
    ),
    conversation:
      mapConversation(
        findRelationship(appointment, conversations || [], 'conversation'),
        messages || [],
        users || [],
        profiles || []
      ) || undefined,
  };
}

export function conversationUserType(
  conversation: JsonApiMappedConversation,
  user: JsonApiMappedUser
): 'initiator' | 'recipient' | undefined {
  if (user?.id === conversation?.initiator?.id) {
    return 'initiator';
  }
  if (user?.id === conversation?.recipient?.id) {
    return 'recipient';
  }
  return undefined;
}

export function appointmentStatus(
  appointment: JsonApiMappedAppointment,
  user: JsonApiMappedUser
): AppointmentsStatus | undefined {
  if (!appointment.conversation) {
    return undefined;
  }
  const userType = conversationUserType(appointment.conversation, user);
  if (!userType) {
    return undefined;
  }
  return appointment.statuses[userType];
}

export function isCancelled(status: AppointmentsStatus): boolean {
  switch (status) {
    case AppointmentsStatus.rescheduled:
    case AppointmentsStatus.did_not_show_up:
    case AppointmentsStatus.cancelled:
    case AppointmentsStatus.forgot_to_go:
    case AppointmentsStatus.unbooked:
      return true;
    default:
      return false;
  }
}

export function isPastAppointment(
  appointment: JsonApiMappedAppointment
): boolean {
  return moment(appointment?.from).diff(moment()) < 0;
}

export function findActiveAppointment(
  appointments: JsonApiAppointment[]
): JsonApiMappedAppointment | undefined {
  const activeAppointment = find(
    appointments,
    (a) =>
      !isCancelled(a.attributes.status) &&
      moment(a.attributes.from).isAfter(moment())
  );
  if (!activeAppointment) {
    return undefined;
  }
  return mapAppointment(activeAppointment);
}

/**
 * Filter appointments to find ONE past (not cancelled) appointment
 */
export function findActivePastAppointment(
  appointments: JsonApiAppointment[]
): JsonApiMappedAppointment | undefined {
  const lastActiveAppointment = appointments
    .sort(({ attributes: { from: a } }, { attributes: { from: b } }) =>
      moment(b).diff(moment(a))
    )
    .find(
      ({ attributes: { status, from } }) =>
        !isCancelled(status) && moment(from).isBefore(moment())
    );
  if (!lastActiveAppointment) {
    return undefined;
  }
  return mapAppointment(lastActiveAppointment);
}

/**
 * Filter function to search only approved claims
 * To be used with `.filter()` or `.find`
 */
export function appointmentClaimApprovedFilterFunction(
  claim: JsonApiMappedAppointmentClaim
): boolean {
  return claim.approved === true;
}

export function isReviewedByStudent(
  appointment: JsonApiMappedAppointment
): boolean {
  return typeof appointment?.reviewFromStudent?.liked === 'boolean';
}

export function isReviewedByProfessional(
  appointment?: JsonApiMappedAppointment
): boolean {
  // handle cancelled appointment (set review to nil)
  if (!appointment?.reviewFromProfessional) {
    return true;
  }
  return !!appointment?.reviewFromProfessional?.reviewedAt;
}

export function getStudentReviewText(
  appointment: JsonApiMappedAppointment
): string {
  return appointment?.reviewFromStudent?.messageForProfessional || '';
}

export function getStudentDesiredExperience(
  appointment: JsonApiMappedAppointment
) {
  return appointment?.reviewFromStudent?.aspiration;
}

/**
 * Be carreful !
 * Undefined can be a valid value
 * due to boolean anwser by student,
 * we use undefined to determine if student did not reviewed the appointment
 */
export function getStudentReviewValue(
  appointment: JsonApiMappedAppointment
): boolean | undefined | null {
  return appointment?.reviewFromStudent?.liked;
}

export function getProfessionalReviewText(
  appointment: JsonApiMappedAppointment
): string {
  return appointment?.reviewFromProfessional?.messageForStudent || '';
}

export function getProfessionalFeedback(
  appointment: JsonApiMappedAppointment
): string {
  return appointment?.reviewFromProfessional?.feedback || '';
}

export function canAppointmentBeReviewedByStudent(
  appointment?: JsonApiMappedAppointment
): boolean {
  return appointment?.reviewFromStudent?.canReview || false;
}

export function canAppointmentBeReviewedByProfessional(
  appointment: JsonApiMappedAppointment
): boolean {
  return appointment?.reviewFromProfessional?.canReview || false;
}
