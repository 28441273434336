import {
  getReadListRequestActionType,
  getReadListSuccessActionType,
} from '../utils/actions';
import ApiResource from '../api-resources';

const conversationsView = (
  state = { isLoading: false, isLoaded: false },
  { type },
) =>
  ({
    '@@router/LOCATION_CHANGE': {
      ...state,
      isLoaded: false,
    },
    [getReadListRequestActionType(ApiResource.MESSAGING_CONVERSATION)]: {
      ...state,
      isLoading: true,
    },
    [getReadListSuccessActionType(ApiResource.MESSAGING_CONVERSATION)]: {
      ...state,
      isLoading: false,
      isLoaded: true,
    },
  })[type] || state;

export default conversationsView;
