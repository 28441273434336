import ApiResource from '../api-resources';
import { updateResource, createResource } from './api';

export const HYDRATE_USER = '@@user/HYDRATE';
export const BOOTSTRAPPING_SUCCESS = '@@user/BOOTSTRAPPING_SUCCESS';

/**
 * Request resend confirmation email to a specific email
 * @param {String} email
 */
export const requestResendConfirmationEmail = (email) =>
  createResource({
    resourceUri: '/confirmation/resend_instructions',
    data: {
      type: ApiResource.USER_CONFIRMATION,
      attributes: {
        email,
      },
    },
  });

/**
 * Confirm an email with confirmation token
 * @param {String} confirmationToken
 */
export const confirmUser = (confirmationToken) =>
  updateResource({
    resourceUri: `/user/confirmations/${confirmationToken}`,
    data: {
      type: 'user',
      attributes: {},
    },
  });

export const updateUser = (userId, attributes, resolve, reject) =>
  updateResource(
    {
      resourceUri: `/users/${userId}`,
      data: {
        type: 'user',
        attributes,
      },
    },
    resolve,
    reject,
  );

export const updateEmail = (attributes, resolve, reject) =>
  updateResource(
    {
      resourceUri: '/user/email',
      data: {
        type: 'email',
        attributes,
      },
    },
    resolve,
    reject,
  );

export const createPassword = ({ new_password }, resolve, reject) =>
  createResource(
    {
      resourceUri: '/user/password',
      data: {
        type: 'password',
        attributes: {
          new_password,
        },
      },
    },
    resolve,
    reject,
  );

export const updatePassword = (attributes, resolve, reject) =>
  updateResource(
    {
      resourceUri: '/user/password',
      data: {
        type: 'password',
        attributes,
      },
    },
    resolve,
    reject,
  );

export const hydrateUser = (user) => ({
  type: HYDRATE_USER,
  user,
});

export const bootstrappingSuccess = (data) => ({
  type: BOOTSTRAPPING_SUCCESS,
  data,
});

/**
 * Request soft deletion of current user
 * @param {String} reason - soft deletion reason
 * @param {Function} resolve
 * @param {Function} reject
 */
export const softDeleteCurrentUser = (reason, resolve, reject) =>
  updateResource(
    {
      resourceUri: '/user/soft_deletion/delete',
      data: {
        type: ApiResource.USER_SOFT_DELETION,
        attributes: {
          reason,
        },
      },
    },
    resolve,
    reject,
  );
