/* prevent error on process things on urlParams ^ */
import { replace } from 'connected-react-router';
import { generatePath } from 'react-router';
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects';
import ApiResource from '../api-resources';
import Routes from '../routes';
import { conversationWithMessagesSelector } from '../selectors/conversations';
import { createSuccessActionType } from '../utils/actions';
import * as urlParams from '../utils/url-parser';
import { getPathnameId } from '../utils/routes';

const pipeline = [{ key: 'modal', saga: handleModalOpen }];

/**
 * Hub to display requested modals from above pipeline
 */
export function* initializeFromUrl() {
  yield all(
    pipeline.map((pipe) => {
      if (urlParams[pipe.key]) {
        return call(pipe.saga, urlParams[pipe.key]);
      }
    }),
  );
}

/**
 * Handle the modal opening
 * @param {String} modalName
 */
export function* handleModalOpen(modalName) {
  if (modalName === 'book-appointment') {
    const { locationAction } = yield race({
      locationAction: take('@@router/LOCATION_CHANGE'),
      // just in case this saga call after location already changed
      fallback: delay(2000),
    });
    const conversationId = getPathnameId(
      Routes.conversation,
      locationAction?.payload?.pathname || '',
    );
    const { search } = locationAction?.payload?.location || '';
    if (conversationId) {
      yield fork(function* () {
        let conversation = yield select(
          conversationWithMessagesSelector(conversationId),
        );
        if (!conversation) {
          yield take(
            createSuccessActionType('READ', ApiResource.MESSAGING_CONVERSATION),
          );
          conversation = yield select(
            conversationWithMessagesSelector(conversationId),
          );
        }
        if (!conversation?.appointment?.id) {
          yield put(
            replace(
              generatePath(Routes.bookAppointment, { id: conversationId }) +
                search,
            ),
          );
        }
      });
    }
  }
}
