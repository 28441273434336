import React from 'react';

// eslint-disable-next-line
const BreakpointDisplay = () =>
  window.__RUNTIME_CONFIG__.REACT_APP_DEBUG_RESPONSIVE_BREAKPOINT === 'true' ? (
    <span className="breakpoint">
      <span className="visible-xs-inline-block">XS</span>
      <span className="visible-sm-inline-block">SM</span>
      <span className="visible-md-inline-block">MD</span>
      <span className="visible-lg-inline-block">LG</span>
    </span>
  ) : null;

export default BreakpointDisplay;
