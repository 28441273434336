import {
  HYDRATE_STORAGE,
  REMOVE_IN_LOCAL_STORAGE,
  STORE_IN_LOCAL_STORAGE,
} from '../actions/storage';

const initialState = {
  message: {},
  credentials: {},
};

export const storage = (state = initialState, action) => {
  switch (action.type) {
    case STORE_IN_LOCAL_STORAGE:
      return {
        ...state,
        [action.key]: action.value,
      };

    case REMOVE_IN_LOCAL_STORAGE:
      return {
        ...state,
        [action.key]: undefined,
      };

    case HYDRATE_STORAGE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default storage;
