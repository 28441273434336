import ApiResource from '../api-resources';
import {
  readListResource,
  readResource,
  updateResource,
  uploadResource,
} from './api';

/**
 * Retrieve a list of pending mentor moderation
 */
export const getPendingMentorModerationList = (page = 1, resolve, reject) =>
  readListResource(
    {
      resourceUri: '/moderator/moderation/mentors',
      data: {
        type: ApiResource.MODERATION_MENTOR,
      },
      params: {
        'page[number]': page,
        'page[size]': 20,
        'stats[total]': 'count',
        'filter[moderation_status]': 'pending',
        include: [
          'mentor.user',
          'current_moderator',
          'mentor.moderation_draft.company_sector',
        ].join(','),
      },
    },
    resolve,
    reject,
  );

/**
 * Retrieve a list of standby mentor moderation
 */
export const getStandbyMentorModerationList = (page = 1, resolve, reject) =>
  readListResource(
    {
      resourceUri: '/moderator/moderation/mentors',
      data: {
        type: ApiResource.MODERATION_MENTOR_STANDBY,
      },
      params: {
        'page[number]': page,
        'page[size]': 20,
        'stats[total]': 'count',
        'filter[moderation_status]': 'standby',
        include: ['mentor.user', 'current_moderator'].join(','),
      },
    },
    resolve,
    reject,
  );

/**
 * Retrieve a single moderation item to process
 * @param {String} id - id of moderation object to retrieve
 */
export const getMentorModeration = (id) =>
  readResource({
    resourceUri: `/moderator/moderation/mentors/${id}`,
    data: {
      type: ApiResource.MODERATION_MENTOR,
    },
    params: {
      include: [
        'mentor.user',
        'mentor.company_sector',
        'mentor.educations',
        'mentor.moderation_draft.company_sector',
      ].join(','),
    },
  });

/**
 * Approves a moderation
 * @param {String} id - ID of the moderation
 */
export const approveModeration = (id, resolve, reject) =>
  updateResource(
    {
      resourceUri: `/moderator/moderation/mentors/${id}/approval`,
      data: {
        id,
        type: ApiResource.MODERATION_MENTOR_APPROVAL,
        attributes: {},
      },
      params: {
        include: ['mentor.user'].join(','),
      },
    },
    resolve,
    reject,
  );

/**
 * Reject a moderation with a reason
 * @param {Object} params
 * @param {String} params.moderationId
 * @param {String} params.reason
 * @param {function} resolve
 * @param {function} reject
 */
export const rejectModeration = ({ moderationId, reason }, resolve, reject) =>
  updateResource(
    {
      resourceUri: `/moderator/moderation/mentors/${moderationId}/rejection`,
      data: {
        id: moderationId,
        type: ApiResource.MODERATION_MENTOR_REJECTION,
        attributes: {
          rejection_reason: 'Not available',
          rejection_email_text: reason,
        },
      },
    },
    resolve,
    reject,
  );

/**
 * Standby a moderation with a reason
 * @param {Object} params
 * @param {String} params.moderationId
 * @param {String} params.reason
 * @param {function} resolve
 * @param {function} reject
 */
export const standbyModeration = ({ moderationId, reason }, resolve, reject) =>
  updateResource(
    {
      resourceUri: `/moderator/moderation/mentors/${moderationId}/standby`,
      data: {
        id: moderationId,
        type: ApiResource.MODERATION_MENTOR_STANDBY,
        attributes: {
          standby_reason: reason,
        },
      },
    },
    resolve,
    reject,
  );

/**
 * Update data for profile
 * @param {String} id - Moderation ID
 * @param {Object} attributes - Attributes to update
 * @param {Function} resolve - Issued from Promise
 * @param {Function} reject - Issued from Promise
 */
export const updateModerationProfile = (
  id,
  attributes,
  relationships,
  included,
  resolve,
  reject,
) =>
  updateResource(
    {
      resourceUri: `/moderator/moderation/mentors/${id}/profile`,
      data: {
        id,
        type: ApiResource.MODERATION_MENTOR,
        attributes,
        relationships,
      },
      params: {
        include: ['mentor.user', 'mentor.moderation_draft.company_sector'].join(
          ',',
        ),
      },
      included,
    },
    resolve,
    reject,
  );

/**
 * Update company of moderation profile
 * @param {String} id - Moderation ID
 * @param {Object} attributes - Attributes to update
 * @param {Function} resolve - Issued from Promise
 * @param {Function} reject - Issued from Promise
 */
export const updateModerationProfileCompany = (
  id,
  attributes,
  resolve,
  reject,
) =>
  updateResource(
    {
      resourceUri: `/moderator/moderation/mentors/${id}/company`,
      data: {
        id,
        type: ApiResource.MODERATION_MENTOR,
        attributes,
      },
      params: {
        include: ['mentor.user', 'mentor.moderation_draft.company_sector'].join(
          ',',
        ),
      },
    },
    resolve,
    reject,
  );

/**
 * Upload a company logo on moderation
 * @param {String} id - Moderation ID
 * @param {File} file - logo to upload
 * @param {Function} resolve
 * @param {Function} reject
 */
export const uploadCompanyLogo = (id, file, resolve, reject) => {
  const body = new FormData();
  body.append('data[type]', ApiResource.MODERATION_MENTOR);
  if (file) {
    body.append('moderation[company_logo]', file);
  }

  return uploadResource(
    {
      resourceUri: `/moderator/moderation/mentors/${id}/logo`,
      data: {
        type: ApiResource.MODERATION_MENTOR,
        id,
      },
      body,
    },
    resolve,
    reject,
  );
};

/**
 * Retrieve the next moderation to process
 */
export const getNextModeration = () =>
  readResource({
    resourceUri: '/moderator/moderation/mentors/next_moderation',
    data: {
      type: ApiResource.MODERATION_MENTOR,
    },
    params: {
      include: [
        'mentor.user',
        'mentor.company_sector',
        'mentor.moderation_draft.company_sector',
      ].join(','),
    },
  });

/**
 * Retrieve a list of pending school moderation
 */
export const getPendingSchoolModerations = (page = 1, resolve, reject) =>
  readListResource(
    {
      resourceUri: '/moderator/moderation/schools',
      data: {
        type: ApiResource.MODERATION_SCHOOL,
      },
      params: {
        'page[number]': page,
        'page[size]': 20,
        'stats[total]': 'count',
        'filter[moderation_status]': 'pending',
        include: ['profile.user', 'current_moderator'].join(','),
      },
    },
    resolve,
    reject,
  );

/**
 * Retrieve a school moderation
 */
export const getSchoolModeration = (id) =>
  readResource({
    resourceUri: `/moderator/moderation/schools/${id}`,
    data: {
      type: ApiResource.MODERATION_SCHOOL,
    },
    params: {
      include: ['profile.user', 'current_moderator'].join(','),
    },
  });

/**
 * Approve a school moderation
 * Version create + assing
 * @param {String} id
 * @param {Object} attributes
 * @param {Function} resolve
 * @param {Function} reject
 */
export const createAndAssignNewSchool = (id, attributes, resolve, reject) =>
  updateResource(
    {
      resourceUri: `/moderator/moderation/schools/${id}/create_school`,
      data: {
        type: ApiResource.MODERATION_SCHOOL_APPROVE,
        id,
        attributes,
      },
      params: {
        include: ['profile.user', 'current_moderator'].join(','),
      },
    },
    resolve,
    reject,
  );

/**
 * Approve a school moderation
 * Version only assign
 * @param {String} id
 * @param {Object} attributes
 * @param {Function} resolve
 * @param {Function} reject
 */
export const assignExistingSchool = (id, attributes, resolve, reject) =>
  updateResource(
    {
      resourceUri: `/moderator/moderation/schools/${id}/assign_school`,
      data: {
        type: ApiResource.MODERATION_SCHOOL_APPROVE,
        id,
        attributes,
      },
      params: {
        include: ['profile.user', 'current_moderator'].join(','),
      },
    },
    resolve,
    reject,
  );

/**
 * Retrieve the next school moderation to process
 */
export const getNextSchoolModeration = () =>
  readResource({
    resourceUri: '/moderator/moderation/schools/next_moderation',
    data: {
      type: ApiResource.MODERATION_SCHOOL,
    },
    params: {
      include: ['profile.user'].join(','),
    },
  });
