import { createSelector } from 'reselect';
import {
  JsonApiProfileDraft,
  JsonApiStudent,
  JsonApiMentor,
  JsonApiEmployee,
  JsonApiCompanyAdmin,
  JsonApiMjgAdmin,
  JsonApiModerator,
  JsonApiProfile,
  ProfileType,
} from '../../@types/jsonapi.d';

export function allMentorDraftCollectionJsonApi(store: ReduxStore) {
  return store?.api?.profileMentorDrafts || [];
}

export function allEmployeeDraftCollectionJsonApi(store: ReduxStore) {
  return store?.api?.profileEmployeeDrafts || [];
}

export const allProfileDraftCollectionJsonApi = createSelector(
  allMentorDraftCollectionJsonApi,
  allEmployeeDraftCollectionJsonApi,
  (profileMentorDrafts, profileEmployeeDrafts): JsonApiProfileDraft[] => [
    ...profileMentorDrafts,
    ...profileEmployeeDrafts,
  ],
);

export function allSchoolCoachCollectionJsonApi(
  store: ReduxStore,
): JsonApiCompanyAdmin[] {
  return (store?.api?.profileSchoolCoaches || []).map((profile) => ({
    ...profile,
    type: ProfileType.schoolCoach,
  }));
}

export function allStudentsCollectionJsonApi(
  store: ReduxStore,
): JsonApiStudent[] {
  return (store?.api?.profileStudents || []).map((profile) => ({
    ...profile,
    type: ProfileType.student,
  }));
}

export function allMentorsCollectionJsonApi(
  store: ReduxStore,
): JsonApiMentor[] {
  return (store?.api?.profileMentors || []).map((profile) => ({
    ...profile,
    type: ProfileType.mentor,
  }));
}

export function allEmployeesCollectionJsonApi(
  store: ReduxStore,
): JsonApiEmployee[] {
  return (store?.api?.profileEmployees || []).map((profile) => ({
    ...profile,
    type: ProfileType.employee,
  }));
}

export function allCompanyAdminsCollectionJsonApi(
  store: ReduxStore,
): JsonApiCompanyAdmin[] {
  return (store?.api?.profileCompanyAdmins || []).map((profile) => ({
    ...profile,
    type: ProfileType.companyAdmin,
  }));
}

export function allMjgAdminCollectionJsonApi(
  store: ReduxStore,
): JsonApiMjgAdmin[] {
  return (store?.api?.profileMjgAdmins || []).map((profile) => ({
    ...profile,
    type: ProfileType.MJGAdmin,
  }));
}

export function allModeratorCollectionJsonApi(
  store: ReduxStore,
): JsonApiModerator[] {
  return (store?.api?.profileModerators || []).map((profile) => ({
    ...profile,
    type: ProfileType.moderator,
  }));
}

export function allProfilesCollectionJsonApi(
  store: ReduxStore,
): JsonApiProfile[] {
  return [
    ...allStudentsCollectionJsonApi(store),
    ...allMentorsCollectionJsonApi(store),
    ...allEmployeesCollectionJsonApi(store),
    ...allCompanyAdminsCollectionJsonApi(store),
    ...allMjgAdminCollectionJsonApi(store),
    ...allModeratorCollectionJsonApi(store),
  ];
}
