import reduce from 'lodash.reduce';
import { toastr } from 'react-redux-toastr';
import { defineMessages } from 'react-intl';

import { extractErrorsFromPayload } from './json-api';
import { t } from './translate';

const translations = defineMessages({
  expMonth: {
    id: 'utils.error.expMonth',
    defaultMessage: 'Doit être une valeur entre 1 et 12',
    description: 'Validation carte crédit',
  },
  expYear: {
    id: 'utils.error.expYear',
    defaultMessage: 'Doit être une année valide',
    description: 'Validation carte crédit',
  },
  cvc: {
    id: 'utils.error.cvc',
    defaultMessage: 'Doit être une valeur à 3 chiffres',
    description: 'Validation carte crédit',
  },
  number: {
    id: 'utils.error.number',
    defaultMessage: 'Doit être un numéro de carte valide',
    description: 'Validation carte crédit',
  },
  other: {
    id: 'utils.error.other',
    defaultMessage: 'Valeur invalide',
    description: 'Validation carte crédit',
  },
  failedToFetchError: {
    id: 'utils.error.failedToFetchError',
    defaultMessage: "Une erreur est survenue lors de l'enregistrement",
    description: 'Champs auto-enregistré - erreur réseau/serveur',
  },
  unknownError: {
    id: 'utils.error.unknownError',
    defaultMessage: "Une erreur est survenue lors de l'enregistrement",
    description: 'Champs auto-enregistré - erreur réseau/serveur',
  },
});

const getMessage = (param) => {
  switch (param) {
    case 'exp_month':
      return t(translations.expMonth);
    case 'exp_year':
      return t(translations.expYear);
    case 'cvc':
      return t(translations.cvc);
    case 'number':
      return t(translations.number);
    default:
      return t(translations.other);
  }
};

export const serverToClient = (errors) => {
  if (Array.isArray(errors)) {
    return reduce(
      errors,
      (result, error) => {
        result[error.meta.attribute] = error.detail;
        return result;
      },
      {},
    );
  }

  if (errors?.param || false) {
    return {
      [errors.param]: getMessage(errors.param),
    };
  }

  return errors;
};

/*
 * Attempt to give feedback to the user/developer at any cost
 */
export const gimmeFeedback = (error) => {
  console.log('Feedback Error', error); // eslint-disable-line no-console
  let nestedError = getNestedError(error);
  let showableError;
  if (isJsonapiValidationError(nestedError)) {
    showableError = sampleJsonapiValidationMessage(nestedError);
  } else if (nestedError.message) {
    showableError = nestedError.message;
  } else if (typeof nestedError === 'string') {
    showableError = nestedError;
  }
  toastr.error(showableError);
};

const getNestedError = (error) =>
  (error && error.error && getNestedError(error.error)) || error || {}; // MindFuck You

/* JSONAPI Errors
 *
 * A json:api error payload *should* look like
 *   { errors: [{ jsonapi_error_hashes }] }
 * ...but due to some absent-mindedness we forgot to add an "s" so it actually is
 *   { error: [{ jsonapi_error_hashes }] }
 *
 * See definition of error hashes there :
 * http://jsonapi.org/format/#error-objects
 * In our implementation of jsonapi we decided to have an application friendly code
 * under jsonapi_error.meta.code
 * (since jsonapi_error.code is often used by middleware)
 * (see also https://github.com/jsonapi-suite/jsonapi_errorable/issues/11)
 *
 * The following utility functions allow to better manipulate our jsonapi errors
 */

/*
 * Returns the first jsonapi error if any
 * @param { Object } payload top level jsonapi payload
 */
export const firstJsonapiError = (payload) =>
  extractErrorsFromPayload(payload)[0];

/*
 * Pick a sample validation error from jsonapi response
 * Adapted for Toaster display
 */
export const sampleJsonapiValidationMessage = (response) => response[0].detail;

/* Should return true if the error response is a classic activemodel error
 * @param error { Object } - the error key of the top-level json payload
 */
export const isJsonapiValidationError = (response) =>
  response &&
  response.constructor === Array &&
  response[0] &&
  response[0].detail;

export const formSubmissionError = (error, defaultMessage) => {
  let message = error && error.errors && error.errors.message;
  if (!message) {
    message = defaultMessage || t(translations.unknownError);
  } else if (message === 'Failed to fetch') {
    message = t(translations.failedToFetchError);
  }
  return message;
};
