export const profileContentEducationsSelector = ({
  api: { profileContentEducations },
}) => profileContentEducations;
export const profileContentExperiencesSelector = ({
  api: { profileContentExperiences },
}) => profileContentExperiences;
export const apiSelector = ({ api }) => api;

export const hermesBranchSelector = ({ api: { monitoringHeartbeats } }) => {
  if (monitoringHeartbeats && monitoringHeartbeats.length) {
    return monitoringHeartbeats[monitoringHeartbeats.length - 1].attributes
      .branch;
  }
};

export default {
  profileContentEducationsSelector,
  profileContentExperiencesSelector,
};

/**
 * Retrieve all indexes
 */
export const apiIndexFromState = ({ apiIndexes }) => apiIndexes;
