import ApiResource from '../api-resources';
import { readResource, updateResource, readListResource } from './api';

export const INITIALIZE_SECTORS = '@@company/INITIALIZE_SECTORS';

const companyData = {
  type: 'company',
};

export const getCompanies = () =>
  readResource({
    resourceUri: '/companies',
    data: {
      ...companyData,
    },
  });

export const updateCompany = (
  { id, attributes, invitationToken },
  resolve,
  reject,
) =>
  updateResource(
    {
      resourceUri: `/companies/${id}/administration`,
      data: {
        ...companyData,
        attributes,
        meta: {
          invitation_token: invitationToken,
        },
      },
    },
    resolve,
    reject,
  );

/**
 * Retrieve a list of all existing company sectors
 */
export const getCompanySectors = () =>
  readListResource({
    resourceUri: '/company/sectors',
    data: {
      type: ApiResource.COMPANY_SECTOR,
    },
  });

/**
 * Request sector initializing
 */
export const intializeSectors = () => ({
  type: INITIALIZE_SECTORS,
});
