/**
 * X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X
 * |                           ,,'``````````````',,                            |
 * X                        ,'`                   `',                          X
 * |                      ,'                         ',                        |
 * X                    ,'          ;       ;          ',                      X
 * |       (           ;             ;     ;             ;     (               |
 * X        )         ;              ;     ;              ;     )              X
 * |       (         ;                ;   ;                ;   (               |
 * X        )    ;   ;    ,,'```',,,   ; ;   ,,,'```',,    ;   ;               X
 * |       (    ; ',;   '`          `',   ,'`          `'   ;,' ;              |
 * X        )  ; ;`,`',  _--~~~~--__   ' '   __--~~~~--_  ,'`,'; ;     )       X
 * |       (    ; `,' ; :  /       \~~-___-~~/       \  : ; ',' ;     (        |
 * X  )     )   )',  ;   -_\  o    /  '   '  \    o  /_-   ;  ,'       )   (   X
 * | (     (   (   `;      ~-____--~'       '~--____-~      ;'  )     (     )  |
 * X  )     )   )   ;            ,`;,,,   ,,,;',            ;  (       )   (   X
 * | (     (   (  .  ;        ,'`  (__ '_' __)  `',        ;  . )     (     )  |
 * X  )     \/ ,".). ';    ,'`        ~~ ~~        `',    ;  .(.", \/  )   (   X
 * | (   , ,'|// / (/ ,;  '        _--~~-~~--_        '  ;, \)    \|', ,    )  |
 * X ,)  , \/ \|  \\,/  ;;       ,; |_| | |_| ;,       ;;  \,//  |/ \/ ,   ,   X
 * |",   .| \_ |\/ |#\_/;       ;_| : `~'~' : |_;       ;\_/#| \/| _/ |.   ,"  |
 * X#(,'  )  \\\#\ \##/)#;     :  `\/       \/   :     ;#(\##/ /#///  (  ',)# ,X
 * || ) | \ |/ /#/ |#( \; ;     :               ;     ; ;/ )#| \#\ \| / | ( |) |
 * X\ |.\\ |\_/#| /#),,`   ;     ;./\_     _/\.;     ;   `,,(#\ |#\_/| //.| / ,X
 * | \\_/# |#\##/,,'`       ;     ~~--|~|~|--~~     ;       `',,\##/#| #\_// \/|
 * X  ##/#  #,,'`            ;        ~~~~~        ;            `',,#  #\##  //X
 * |####@,,'`                 `',               ,'`                 `',,@####| |
 * X#,,'`                        `',         ,'`                        `',,###X
 * |'  spb                          ~~-----~~                               `' |
 * X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X-X
 *
 *
 *                              WELCOME TO HELL
 *
 * TO NOT GO MAD, please listen to :
 * https://open.spotify.com/track/2zYzyRzz6pRmhPzyfMEC8s?si=tWAyb2EeTWuRE7YX2RZoSw
 * when you work on this file 🙏
 *
 * @todo https://github.com/MyJobGlasses/Hermes/issues/2908
 */
import { put, select, takeEvery } from 'redux-saga/effects';
import ApiResource from '../api-resources';
import {
  messagingAppointmentsSelector,
  profileHibernationsJsonApiFromState,
} from '../selectors';
import { createSuccessActionType } from '../utils/actions';

/**
 * Watch specifics delete actions to suppress object from json api redux state
 */
export function* dirtyHellWatchers() {
  yield takeEvery(
    createSuccessActionType('DELETE', ApiResource.MESSAGING_APPOINTMENT),
    dirtyPatchAppointmentDelete,
  );
  yield takeEvery(
    createSuccessActionType('DELETE', ApiResource.PROFESSIONAL_HIBERNATION),
    dirtyPatchHibernationDelete,
  );
}

/**
 * Track and kill appointment dissident
 * @param {action} payload - appointment deleted with success action
 */
export function* dirtyPatchAppointmentDelete(payload) {
  // payload is like { data: { meta: { '/messaging/appointments/[appointmentID]': {}}}}
  const key = Object.keys(payload?.data?.meta || {});
  if (key && key.length > 0) {
    const matches = key[0].match(/\/messaging\/appointments\/(.*)/);
    if (matches && matches[1]) {
      const ID = matches[1];
      // When the ID is identified, we need to delete the corresponding object on store
      // So, we retrieves all object for the appointment key, then we find index of searched ID
      // Next, we delete object, and dispatch new array without target appointment to suppress it on store
      const messagingAppointments = yield select(messagingAppointmentsSelector);
      const messagingAppointmentsIndex = messagingAppointments.findIndex(
        (appointment) => appointment.id === ID,
      );
      yield put({
        type: 'DIRTY_PATCH_API_DELETE',
        data: {
          messagingAppointments: [
            ...messagingAppointments.slice(0, messagingAppointmentsIndex),
            ...messagingAppointments.slice(
              messagingAppointmentsIndex + 1,
              messagingAppointments.length,
            ),
          ],
        },
      });
    }
  }
}

/**
 * Track and kill hibernations dissident
 * @param {action} payload - appointment deleted with success action
 */
export function* dirtyPatchHibernationDelete(payload) {
  // On delete hibernation ressource (with success)
  // We need to retrieve the hibernation ID to delete the good one on store
  // payload is like { data: { meta: { '/professional/hibernations/5455dsfhg34567dfg45': {}}}}
  const key = Object.keys(payload?.data?.meta || {});
  if (key && key.length > 0) {
    const matches = key[0].match(/\/professional\/hibernations\/(.*)/);
    if (matches && matches[1]) {
      const ID = matches[1];
      // When the ID is identified, we need to delete the corresponding object on store
      // So, we retrieves all object for the hibernation key, then we find index of searched ID
      // Next, we delete object, and dispatch new array without target hibernation to suppress it on store
      const hibernations = yield select(profileHibernationsJsonApiFromState);
      const hibernationIndex = hibernations.findIndex(
        (hibernation) => hibernation.id === ID,
      );
      yield put({
        type: 'DIRTY_PATCH_API_DELETE',
        data: {
          hibernations: [
            ...hibernations.slice(0, hibernationIndex),
            ...hibernations.slice(hibernationIndex + 1, hibernations.length),
          ],
        },
      });
    }
  }
}
