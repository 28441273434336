import { defineMessages } from 'react-intl';
import { languageTable } from '../data/languages';
import { t } from './translate';

const translations = defineMessages({
  oneDay: {
    id: 'utils.display.oneDay',
    defaultMessage: '<24h',
    description: 'Temps de réponses',
  },
  oneWeek: {
    id: 'utils.display.oneWeek',
    defaultMessage: '<7j',
    description: 'Temps de réponses',
  },
  aboveOneWeek: {
    id: 'utils.display.aboveOneWeek',
    defaultMessage: '>7j',
    description: 'Temps de réponses',
  },
});

/**
 * Estimate delay to display human readable information
 * @param Float delayInSecond The delay in second of response
 * @return String
 */
export const stringifyResponseTime = (delayInSecond) => {
  const delay = +delayInSecond / 60; // Mutate delay to minutes
  // less than one day
  if (delay / 60 < 24) {
    return t(translations.oneDay);
  }
  // less than 1 week
  if (delay / 60 / 24 <= 7) {
    return t(translations.oneWeek);
  }
  // more than 1 week
  return t(translations.aboveOneWeek);
};

/**
 * transform lang to flag url
 * @param {string} lang - (eg: french, english...)
 */
export const flagUrl = (lang) =>
  `https://assets.myjobglasses.com/images/flags/${lang}-flag.png`;

/**
 * transform lang to flag url
 * @param {string} lang - (eg: french, english...)
 */
export const languageToCode = (lang) => languageTable?.[lang]?.code;

/**
 * just to clearify code
 * @param {Any} value
 * @param {String} placeholder
 */
export const withPlaceholder = (value, placeholder, display = (v) => v) => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      return value.map((item) => display(item));
    }
  } else if (value) {
    return display(value);
  }
  return placeholder;
};
