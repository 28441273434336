import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose, withHandlers, withState } from 'recompose';
import Avatar from '../avatar/Avatar';
import { isProfessional as isProfessionalCheck } from '../../utils/user';
import EducationDisplay from '../education-display/EducationDisplay';
import ProfileSoftDeletionInformation from './profile-soft-deletion-information/ProfileSoftDeletionInformation';
import { ProfileStats } from './profile-stats/ProfileStats';

import './ProfileOverview.scss';

const ProfileOverview = ({
  withLink,
  user,
  user: {
    firstName,
    avatar,
    phone,
    profile: {
      id,
      activeSchoolName,
      organizationName,
      position,
      likeCount,
      rating,
      totalRatings,
      profileViews,
    },
  },
  isProfessional,
  isInterlocuteurAlive,
  toggleMobileDisplayInformations,
  isMobileDisplayInformations,
}) => (
  <div className="profile-overview">
    <div
      className="avatar-wrapper"
      onClick={toggleMobileDisplayInformations}
    >
      <Avatar
        avatar={avatar}
        className="avatar media-object"
        type="cropped"
      />
    </div>

    <div
      className={classNames({
        'profile-infos': true,
        'profile-infos-mobile-opened': isMobileDisplayInformations,
      })}
    >
      {withLink ? (
        <Link
          to={`/professionals/${id}`}
          className="profile-overview-link"
        >
          <h1 className="firstname">{firstName}</h1>
        </Link>
      ) : (
        <h1 className="firstname">{firstName}</h1>
      )}

      {!isInterlocuteurAlive && (
        <ProfileSoftDeletionInformation isProfessional={isProfessional} />
      )}

      <span className="position">{position}</span>

      {isProfessionalCheck(user) ? (
        <span className="organization-name">{organizationName}</span>
      ) : (
        <EducationDisplay
          className="education-name"
          educations={user?.profile?.educations || []}
          activeSchoolName={activeSchoolName}
        />
      )}

      {phone && (
        <FormattedMessage
          id="ProfileOverview.phone"
          defaultMessage="Tel: {phone}"
          description="Téléphone sur le profile dans la conversation"
          values={{
            phone: (
              <a
                href={`tel:${phone}`}
                className="phone"
              >
                {phone}
              </a>
            ),
          }}
        >
          {(...nodes) => <span className="profile-phone">{nodes}</span>}
        </FormattedMessage>
      )}

      <ProfileStats
        isProfessional={isProfessional}
        rating={rating}
        totalRatings={totalRatings}
        profileViews={profileViews}
        likeCount={likeCount}
      />
    </div>
  </div>
);

ProfileOverview.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    avatar: PropTypes.object,
    phone: PropTypes.string,
    profile: PropTypes.shape({
      id: PropTypes.string.isRequired,
      activeSchoolName: PropTypes.string,
      organizationName: PropTypes.string,
      position: PropTypes.string,
      likeCount: PropTypes.number,
      profileViews: PropTypes.number,
      rating: PropTypes.number,
      totalRatings: PropTypes.number,
      educations: PropTypes.shape({}),
    }),
  }),
  isProfessional: PropTypes.bool,
  withLink: PropTypes.bool,
  isInterlocuteurAlive: PropTypes.bool,
  isMobileDisplayInformations: PropTypes.bool,
  toggleMobileDisplayInformations: PropTypes.func.isRequired,
};

ProfileOverview.defaultProps = {
  isInterlocuteurAlive: true,
  withLink: false,
};

export default compose(
  connect(),
  withState(
    'isMobileDisplayInformations',
    'setIsMobileDisplayInformations',
    false,
  ),
  withHandlers({
    toggleMobileDisplayInformations:
      ({ isMobileDisplayInformations, setIsMobileDisplayInformations }) =>
      () => {
        setIsMobileDisplayInformations(!isMobileDisplayInformations);
      },
  }),
)(ProfileOverview);
