export const UPDATE_USER_ATTRIBUTES = '@@user/profile/UPDATE_USER_ATTRIBUTES';

export interface UpdateUserAttributesAction {
  type: string;
  userId: string;
  firstName: string;
  lastName: string;
  resolve: PromiseResolveFn;
  reject: PromiseRejectFn;
}
interface UpdateUserAttributesParams {
  userId: string;
  firstName: string;
  lastName: string;
}
export const updateUserAttributes = (
  { userId, firstName, lastName }: UpdateUserAttributesParams,
  resolve: PromiseResolveFn,
  reject: PromiseRejectFn,
): UpdateUserAttributesAction => ({
  type: UPDATE_USER_ATTRIBUTES,
  userId,
  firstName,
  lastName,
  resolve,
  reject,
});
