import moment from 'moment';
export const REMEMBER_AMBASSADOR_INVITATION_CODE =
  '@navigation/REMEMBER_AMBASSADOR_INVITATION_CODE';
export const REMEMBER_UTM = '@navigation/REMEMBER_UTM';

export const rememberAmbassadorInvitationCode = (code) => {
  return {
    type: REMEMBER_AMBASSADOR_INVITATION_CODE,
    code,
  };
};

export const rememberUtm = (utm) => {
  return {
    type: REMEMBER_UTM,
    utm,
    utmSavedAt: moment(),
  };
};
