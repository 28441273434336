import { useEffect, useState } from 'react';
import trimStart from 'lodash.trimstart';
import qs from 'querystring';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { SignupSearchParams } from '../../../../@types/routes-params.d';
import { StorageEntries } from '../../../../@types/storage-entries';
import { storeInLocalStorage } from '../../../../actions/storage';

export default function useSignUpIntent(): void {
  const dispatch = useDispatch();
  const [urlParams, setUrlParams] = useState<SignupSearchParams>();

  // handle url params
  const { search } = useLocation();
  useEffect(() => {
    const urlSearchParams: SignupSearchParams = qs.parse(
      trimStart(search, '?'),
    );
    if (urlSearchParams) {
      setUrlParams({
        ...urlParams,
        ...urlSearchParams,
      });
    }
  }, [search]);

  useEffect(() => {
    // handle store invitation_code
    if (urlParams?.invitation_code) {
      dispatch(
        storeInLocalStorage({
          key: StorageEntries.mentorInvitationCode,
          value: urlParams.invitation_code,
        }),
      );
    }
  }, [urlParams?.invitation_code]);
}
