import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Modal } from 'react-bootstrap';
import RequestLanguage from '../request-language/RequestLanguage';
import ProfileOverview from '../profile-overview/ProfileOverview';
import { userSelector } from '../../selectors';

import './RequestLanguageModal.scss';

const RequestLanguageModal = ({ show, onHide, user }) => (
  <Modal
    id="request-language"
    show={show}
    onHide={onHide}
    keyboard={false}
    backdrop={'static'}
  >
    <Modal.Header>{user && <ProfileOverview user={user} />}</Modal.Header>
    <Modal.Body>
      <RequestLanguage />
    </Modal.Body>
  </Modal>
);

RequestLanguageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    avatar: PropTypes.object,
    profile: PropTypes.shape({}).isRequired,
  }),
};

export default compose(
  connect((state) => ({
    user: userSelector(state),
  })),
)(RequestLanguageModal);
