export const RUN_DOWNLOAD = '@@download/RUN_DOWNLOAD';

interface RunDownloadActionProps {
  url: string;
  fileName: string;
  fileType: string;
}
export function runDownload({
  url,
  fileName,
  fileType,
}: RunDownloadActionProps) {
  return {
    type: RUN_DOWNLOAD,
    url,
    fileName,
    fileType,
  };
}
