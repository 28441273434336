import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, withHandlers, withProps } from 'recompose';
import { FormGroup, HelpBlock } from 'react-bootstrap';
import { availableLanguages } from '../../data/languages';
import { languageToCode } from '../../utils/display';

import 'flag-icon-css/css/flag-icon.css';
import './LanguageField.scss';

const LanguageField = ({
  input,
  submitting,
  meta: { touched, error, warning },
  handleLanguageChange,
}) => (
  <FormGroup
    className="language-field"
    validationState={
      classNames({
        error: touched && !!error,
      }) || null
    }
  >
    {availableLanguages.map((lang) => (
      <label
        key={lang}
        className="lang-option"
      >
        <span className={`flag-icon flag-icon-${languageToCode(lang)}`} />
        <input
          type="checkbox"
          value={lang}
          checked={input.value.indexOf(lang) > -1}
          onChange={handleLanguageChange}
          disabled={submitting}
        />
      </label>
    ))}
    <HelpBlock>
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </HelpBlock>
  </FormGroup>
);

LanguageField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
  handleLanguageChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default compose(
  withProps(({ validationState }) => ({
    submitting: validationState === 'submitting',
  })),
  withHandlers({
    handleLanguageChange:
      ({ input }) =>
      (ev) => {
        const inputValue = ev?.target?.value;
        let newValue = [...input.value];

        const index = input.value.indexOf(inputValue);
        if (index > -1) {
          // value exist so delete it
          newValue.splice(index, 1);
        } else {
          // else, just add it
          newValue = [...newValue, inputValue];
        }
        input.onBlur(newValue);
      },
  }),
)(LanguageField);
