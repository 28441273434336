import intersectionBy from 'lodash.intersectionby';
import map from 'lodash.map';
import moment from 'moment-timezone';
import { messageMapper, mapUserWithProfile } from '../selectors';
import {
  findActiveAppointment,
  findActivePastAppointment,
} from './appointment';
import {
  flattenAttributes,
  findRelationship,
  findAllRelationships,
} from './json-api';

/**
 * Merge all relations (+flatten attributes) of a conversation
 * @param {Object} conversation
 * @param {Object[]} messages
 * @param {Object[]} users
 * @param {Object[]} profiles
 * @param {Object[]} [appointments]
 * @param {Object[]} [appointmentClaims]
 * @param {Object[]} [profileContentEducations]
 * @param {Object[]} [profileInternships]
 * @returns {import('../@types/jsonapi.d').JsonApiMappedConversation|null}
 */
export const mapConversation = (
  conversation,
  messages,
  users,
  profiles,
  appointments,
  appointmentClaims,
  profileContentEducations,
  profileInternships,
) =>
  conversation
    ? {
        ...flattenAttributes(conversation),
        messages: map(
          intersectionBy(
            messages,
            conversation.relationships?.messages?.data || [],
            'id',
          ),
          (m) => messageMapper({ message: m, users, profiles }),
        ).reverse(),
        // @chore https://github.com/MyJobGlasses/Hermes/issues/4717
        initiator: mapUserWithProfile(
          findRelationship(conversation, users, 'initiator'),
          profiles,
          'student',
          profileContentEducations,
          undefined,
          undefined,
          undefined,
          undefined,
          profileInternships,
        ),
        recipient: mapUserWithProfile(
          findRelationship(conversation, users, 'recipient'),
          profiles,
          'mentor',
          profileContentEducations,
        ),
        appointment: (() => {
          if ((conversation?.relationships?.appointments?.data || []).length) {
            const appointment = findActiveAppointment(
              findAllRelationships(conversation, appointments, 'appointments'),
            );
            if (!appointment) {
              return null;
            }
            return {
              ...appointment,
              conversation: appointment?.conversation || conversation,
            };
          }
          return null;
        })(),
        pastAppointment: (conversation?.relationships?.appointments?.data || [])
          .length
          ? findActivePastAppointment(
              findAllRelationships(conversation, appointments, 'appointments'),
            )
          : null,
        appointmentClaims: (
          findAllRelationships(
            conversation,
            appointmentClaims,
            'appointmentClaims',
          ) || []
        )
          .filter((claim) => typeof claim?.attributes?.accepted !== 'boolean')
          .map((claim) => flattenAttributes(claim)),
      }
    : null;

/**
 * Check if difference between now and message is over hours
 * @param {Object} message
 * @param {Number} hours
 */
export const isMessageOlderThan = (message, hours) =>
  message && moment().diff(moment(message.createdAt), 'hours') > hours;

/**
 * Return if conversation is acknowledge
 * @param {Object} [conversation]
 * @returns {Boolean}
 */
export const isConversationAlreadyAcknowledged = (conversation) =>
  conversation?.acknowledged === false ?? true;

/**
 * Check if the last message is from user
 * @param {Object} conversation
 * @param {Object[]} conversation.messages
 * @param {Object} user
 * @returns {Boolean}
 */
export const isLastMessageFromUser = (conversation, user) => {
  const messages = conversation?.messages || [];
  const sortedMessage = messages.sort((a, b) =>
    moment(a.createdAt).diff(moment(b.createdAt)),
  );
  const lastMessage = sortedMessage[sortedMessage.length - 1];
  return user?.id === lastMessage?.senderId;
};

/**
 * @param {Object} [conversation]
 * @param {Boolean} [isProfessional]
 * @returns {import('../@types/jsonapi').JsonApiMappedUser|undefined}
 */
export const getInterlocutor = (conversation, isProfessional) => {
  if (!conversation) {
    return;
  }
  if (isProfessional) {
    return conversation.initiator;
  }
  return conversation.recipient;
};
