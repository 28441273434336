/*
 * Navigation reducer is responsible for keeping and aggregating navigation-wide information
 * Such as
 * - UTM information
 * - Any parameter obtained from special links (invitations, etc)
 */

import {
  REMEMBER_AMBASSADOR_INVITATION_CODE,
  REMEMBER_UTM,
} from '../actions/navigation';

const initialState = {
  invitationToken: null,
  invitationTokenType: null,
  // TODO: Handle UTM See https://github.com/Startouf/MyJobGlasses/issues/2184
  utm: {
    source: null,
    medium: null,
    campaign: null,
    term: null,
    content: null,
  },
};

export default function navigation(state = initialState, action) {
  switch (action.type) {
    case REMEMBER_UTM: {
      return {
        ...state,
        utm: action.utm,
      };
    }

    case REMEMBER_AMBASSADOR_INVITATION_CODE: {
      return {
        ...state,
        invitationToken: action.code,
        invitationTokenType: 'employee',
      };
    }

    default: {
      return state;
    }
  }
}
